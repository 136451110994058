<template>
  <div class="container">
      <section id="DescripConte" style="text-align:justify">
        <p>
          Costa Realty ofrece a nivel corporativo diversos servicios de
          consultoría, entre los cuales se encuentran:
        </p>
        <p></p>
        <p><strong class="markRed"> - Estudios de Mercado </strong></p>
        <p>
          Informe de la situación actual del mercado inmobiliario así como
          número de proyectos en el mercado, número de unidades, características
          del proyecto, de las unidades y su política de comercialización.
        </p>
        <p>
          <br /><strong class="markRed">
            - Consultoría en Desarrollo de Bienes Raíces
          </strong>
        </p>
        <p>
          Cual producto es el que se está demandando actualmente así como las
          características del mismo y sus amenidades.
        </p>
        <br />
        <p>
          <strong class="markRed">
            - Proyectos “Llave en mano” Construcción Comercial
          </strong>
        </p>
        <p>
          Asesoría en proyectos hoteleros, desarrollos integrales tanto
          comerciales como habitacionales coordinando a los mejores en el ramo.
        </p>
        <br />
        <p><strong class="markRed"> - Alianzas Estratégicas</strong></p>
        <br />
        <p><strong class="markRed"> - Cartera de Clientes</strong></p>
        <br />
        <p>
          <strong class="markRed">
            - Programas de Mercadotecnia y Publicidad
          </strong>
        </p>
        <p>
          Asesoría en los planes de medio para los proyectos tanto en impresos
          como en media y redes sociales tomando en cuenta los medios que
          realmente generan prospectos.
        </p>
      </section>
  </div>
</template>
