
<template>
    <div class="card-box-b card-shadow news-box">
        <div class="img-box-b">
        <img :src="item.image.smallphoto" :alt="item.image.alt" :title="item.image.title" class="img-b img-fluid">
        </div>
        <div class="card-overlay">
        <div class="card-header-b">
            <div class="card-category-b">
                  <a href="#" class="category-b"><i class="fas fa-map-marker-alt deve"></i> {{item.city}}</a>
                </div>
            <div class="card-title-b">
                <h3 class="title-2">
                    <a class="cc_pointer" @click="goToDevelopment()">{{item.developmentNameEs}}</a>
                </h3>
            </div>
            <div class="card-date">
                <span class="date-b">
                     <a class="cc_pointer" @click="goToDevelopment()">{{$t('components.best_development_card.link')}}</a>
                </span>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import services from '@/store/_services'
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    },
    methods:{
        goToDevelopment(){
            let nameDevFilt=services.normalize(this.item.developmentNameEs)
            let folio = this.item.folio;
            this.$router.push({
                name: 'Development',
                params: {
                    folio,
                    nameDevFilt
                }
            });
        }
    }
}
</script>
<style scoped>
.title-2{
    font-size: 1.2rem;
}
.date-b a,
.category-b,
.title-2{
    color: #ffffff;
    text-transform: uppercase;
}
.img-box-b{
    height: 250px;
}
.img-fluid{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
</style>