
<template>
    <div class="card-box-b">
       <iframe width="100%" height="200px" :src="item.url" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    }
}
</script>
