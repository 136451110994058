<template>
    <div class="card-box-a card-shadow">
        <div class="img-box-a">
            <img :src="item.image.smallphoto" :alt="item.image.alt" :title="item.image.title" class="img-a img-fluid">
        </div>
        <div class="card-overlay">
            <div class="card-overlay-a-content">
                <div class="card-header-a">
                    <h4 class="card-title-a">
                        <a class="cc_pointer" @click="goToDevelopment()" >{{item.developmentNameEs}}</a>
                    </h4>
                </div>
                <div class="card-body-a">
                    <div class="price-box d-flex">
                        <span class="price-a">{{item.priceFrom}} - {{item.priceTo}}</span>
                    </div>
                    <a @click="goToDevelopment()" class="cc_pointer link-a">{{$t('components.property_card.link')}}
                        <span class="ion-ios-arrow-forward"></span>
                    </a>
                </div>
                <div v-if="item.m2From || item.bedFrom || item.bathFrom"  class="card-footer-a">
                    <ul class="card-info d-flex justify-content-around">
                        <li v-if="item.m2From">
                            <h4 class="card-info-title">{{$t('components.property_card.area')}}</h4>
                            <span v-if="item.m2From">{{item.m2From}} m
                                <sup>2</sup>
                            </span>
                        </li>
                        <li v-if="item.bedFrom">
                            <h4 class="card-info-title">{{ $t("components.property_card.bedrooms", (item.bedFrom==1)?1:2)}}</h4>
                            <span v-if="item.bedFrom">{{$t('components.property_card.since')}} {{item.bedFrom}}</span>
                        </li>
                        <li v-if="item.bathFrom">
                            <h4 class="card-info-title">{{$t("components.property_card.bathrooms", (item.bathFrom==1)?1:2)}}</h4>
                            <span v-if="item.bathFrom">{{$t('components.property_card.since')}} {{item.bathFrom}}</span> 
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import services from '@/store/_services'
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    },
    methods:{
        goToDevelopment(){
            let nameDevFilt=services.normalize(this.item.developmentNameEs)
            let folio = this.item.folio;
            this.$router.push({
                name: 'Development',
                params: {
                    folio,
                    nameDevFilt
                }
            });
        }
    }
}
</script>
<style scoped>
.img-box-a{
    height: 300px;
}
.img-fluid {
    width: 100%;
    object-position: center;
    object-fit: cover;
}
</style>