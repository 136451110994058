<template>

    <GMapMap
    :center="center"
    :zoom="10"
    map-type-id="terrain"
    style="width: 100%; height: 60vh"
  >
    <GMapCluster :zoomOnClick="true">
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center = m.position"
      />
    </GMapCluster>
  
  </GMapMap>
   <!-- test: {{mapLat}} -->


</template>

<script>
export default {
  props:{    
        mapLat:{
            type:String,
            required:true
        },
        mapLng:{
            type:String,
            required:true
        }
  },
  data() {
    return {
      // center: { lat: 51.165218, lng: 7.067116},
        center: { lat: 20.209431 , lng:-87.467896},
      markers: [
        {
          position: {
            lat: parseFloat(this.mapLat),
            lng:parseFloat(this.mapLng),
          },
          // position: {
          //   lat: 51.093048,
          //   lng: 6.84212,
          // },
        // },
        // {
        //   position: {
        //     lat: 51.198429,
        //     lng: 6.69529,
        //   },
        // },
        // {
        //   position: {
        //     lat: 51.165218,
        //     lng: 7.067116,
        //   },
        },
      ],
    };
  },
      created(){
        this.center.lat=parseFloat(this.mapLat);
        this.center.lng=parseFloat(this.mapLng);
    }
};
</script>

<style>
body {
  margin: 0;
}
</style>
