<template>
  <section class="height-70 bg-gradient pt-2 pb-2 BannerHome " style=" display: grid;">
    <img
      src="/img/zh-cancun.jpg"
      alt="Image"
      class="bg-image"
    />
    <div class="hero-wrap">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center  pt-5">
          <div class="col-lg-12 col-md-12 col-sm-12 ftco-animate text-center">
            <div class="text-left quote-banner mb-4"> <h2> Encuentra el hogar de tus <b>sueños</b></h2></div>
            <section class="ftco-search mt-2 mb-2 pt-1">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-12 search-wrap">
                    <SearchFormHome />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SearchFormHome from '@/components/layout/SearchFormHome.vue'
export default {
  components: {
    SearchFormHome
  },
};
</script>

<style>

@media (max-width: 576px) {
  .BannerHome h1{
    font-size: 1.6rem;
  }
  .img-lone {
    width: 100% !important;
  }
}
@media (max-width: 992px) { 
.img-lone {
  width: 65%;
  }
}

img {
  width: 100%;
}
/* .bg-gradient {
    background: linear-gradient(90deg, #0099FF 0%, #f2ba10 100%);
} */
.height-70 {
  min-height: 70vh;
}
img.bg-image {
  object-fit: cover;
}
.opacity-60 {
  opacity: 0.7;
}
img {
  max-width: 100%;
}
.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.bg-image:not([class*="absolute"]) {
  position: absolute;
}
::before,
::after {
  box-sizing: border-box;
}
[class*="height-"]:not(.align-items-initial) {
  align-items: center;
}
/* [class*="height-"] {
  display: flex;
} */
section {
  position: relative;
}
section {
  padding: 4.5rem 0;
}
</style>