<template>
    <!--/ footer Star /-->
  <section v-if="info" class="section-footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <div class="widget-a">
            <div class="w-header-a text-center">
              <img class="cr-logo" :src="info.logo" :alt="info.alt" srcset="">
            </div>
            <div class="w-footer-a">
              <ul class="list-unstyled">
                <li v-if="info.reserveemail!=0" class="color-a">
                  <span class="color-text-a">{{$t('layout.footer.company_email')}} .</span> <router-link to="/contacto"> {{info.reserveemail}}</router-link>
                </li>
                <li class="color-a">
                  <span class="color-text-a">{{$t('layout.footer.company_phone')}} .</span> (998) 321 0234</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 section-md-t3">
          <div class="widget-a">
            <div class="w-header-a">
              <h3 class="w-title-a text-brand">{{$t('layout.footer.contact_title')}}</h3>
            </div>
            <div class="w-body-a">
              <div class="w-body-a">
                <ul class="list-unstyled">
                  <li class="item-list-a">
                    <i class="fa fa-angle-right"></i> <a>{{$t('layout.footer.contact_phone')}}</a> <br>
                    (998) 885 0965
                  </li>
                  <li class="item-list-a">
                    <i class="fa fa-angle-right"></i> <a>Toll Free USA</a> <br>
                    1 866 406 3124
                  </li>
                  <li class="item-list-a">
                    <i class="fa fa-angle-right"></i> <a>Toll Free Canada</a> <br>
                    1 866 406 3124
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 section-md-t3">
          <div class="widget-a">
            <div class="w-header-a">
              <h3 class="w-title-a text-brand">{{$t('layout.footer.menu_title')}}</h3>
            </div>
            <div class="w-body-a">
              <ul class="list-unstyled">
                <li class="item-list-a">
                  <i class="fa fa-angle-right"></i> <router-link to="/">{{$t('layout.nav.home')}}</router-link>
                </li>
                <li class="item-list-a">
                  <i class="fa fa-angle-right"></i> <router-link to="/propiedades">{{$t('layout.nav.properties')}}</router-link>
                </li>
                <li class="item-list-a">
                  <i class="fa fa-angle-right"></i> <router-link to="/desarrollos">{{$t('layout.nav.developments')}}</router-link>
                </li>
                <!-- <li class="item-list-a">
                  <i class="fa fa-angle-right"></i> <router-link to="/agentes">{{$t('layout.nav.agents')}}</router-link>
                </li> -->
                <li class="item-list-a">
                  <i class="fa fa-angle-right"></i> <router-link to="/contacto">{{$t('layout.nav.contact')}}</router-link>
                </li>
                <li class="item-list-a">
                  <i class="fa fa-angle-right"></i> <a href="https://mi.immocrm360.com.mx/" target="_blank">{{$t('layout.nav.go_to_immo')}}<sup>©</sup></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="socials-a">
            <ul class="list-inline">
              <li v-if="info.fb"  class="list-inline-item">
                <a :href="info.fb" target="_blank">
                  <i class="fab fa-facebook-f" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="info.twitter" class="list-inline-item">
                <a :href="info.twitter" target="_blank">
                  <i class="fab fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="info.instagram"  class="list-inline-item">
                <a :href="info.instagram" target="_blank">
                  <i class="fab fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="info.youtube"  class="list-inline-item">
                <a :href="info.youtube" target="_blank">
                  <i class="fab fa-youtube" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="info.linkedin"  class="list-inline-item">
                <a :href="info.linkedin" target="_blank">
                  <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="info.pinterest"  class="list-inline-item">
                <a :href="info.pinterest" target="_blank">
                  <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="info.gmas" class="list-inline-item">
                <a :href="info.gmas"  target="_blank">
                  <i class="fab fa-google-plus-g" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer v-if="info">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="copyright-footer">
            <p class="copyright color-text-a">
              <span class="color-a">{{info.companyName}}<sup>&copy;</sup></span> | {{$t('layout.footer.copyright')}} {{year}}
            </p>
          </div>
          <div class="credits">
            Powered with <i class="far fa-heart" aria-hidden="true"></i> & <i class="fas fa-pizza-slice" aria-hidden="true"></i>
            <a href="https://immocrm360.com.mx/" target="_blank"> IMMO CRM 360<sup>&copy;</sup></a>
          </div>
          

        </div>
      </div>
    </div>
  </footer>
  <!--/ Footer End /-->
</template>

<script>
import { mapGetters } from 'vuex'

export default {
 
    data(){
        return{
            year:""
        }
    },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
    created(){
        let d= new Date();
        this.year=d.getFullYear();
    }
}
</script>

<style>

</style>