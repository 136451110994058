<template>
  <section class="SellProperty mr-3 ml-2">
    <div class="container sprop">
      <div class="row">
        <div class="col-sm-12 col-lg-3 left-flyer">
          <div class="row pt-4">
            <div class="col-sm-12">
              <h5>EXPERIENCIA EN EL RAMO</h5>
              <p class="text-justify mb-3">
                Establecidos desde 1996, representamos inventarios en exclusiva en
                México por más de 4 mil millones de dólares.
              </p>
            </div>
            <div class="col-sm-12">
              <h5>CARTERA DE CLIENTES</h5>
              <p class="text-justify mb-3">
                Contamos con una base de datos propia de aproximadamente 25,000 
                clientes y prospectos calificados.
              </p>
            </div>
            <div class="col-sm-12 mb-3">
              <h5>HEMOS COMERCIALIZADO</h5>
              <p class="text-justify mb-3">
                Follow the next link to our Curriculum:
              </p>
              <a
                class="text-justify"
                href="http://www.costarealty.com.mx/nosotros"
                >http://www.costarealty.com.mx/nosotros</a
              >
            </div>
            <div class="col-sm-12">
              <h5>OPERACIÓN ÉTICA</h5>
              <p class="text-justify mb-3">
                100% conforme a las leyes y normas mexicanas para proteger
                al vendedor y al comprador.
              </p>
            </div>
            <div class="col-sm-12 mb-3 mail-flyer">
              <h5>ESCRÍBANOS A:</h5>
              <a class="text-justify" href="mailto:coordinadora@costarealty.com.mx"
                >coordinadora@costarealty.com.mx</a
              >
            </div>
            <div class="col-sm-12 mb-3 phones-flyer">
              <h5>MÁRQUENOS AL NÚMERO:</h5>
              <a class="text-justify mb-1" href="tel:9983210234"
                >9983210234</a
              >
              <br />
              <a class="text-justify mb-3" href="tel:9988851551"
                >9988851551</a
              >
            </div>
            <div class="col-sm-12 mb-4">
              <h5>VISÍTENOS:</h5>
              <a
                class="text-justify mb-1"
                href="https://www.costarealty.com.mx"
              >
                www.costarealty.com.mx</a
              >
              <br />
            </div>
            <div class="col-sm-1 pt-2 pb-2 light-flyer"></div>
            <div class="col-sm-11 pt-3 pb-1 socialicons-flyer">
              <ul class="list-inline socials">
                <li class="list-inline-item">
                  <a href="https://www.facebook.com/CRCancunRM">
                    <i class="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="https://instagram.com/crrivieramaya?utm_medium=copy_link">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-9 right-flyer">
            <div class="row text-center">
              <!-- <div class="col-sm-12 logos-flyer text-left ">
                <img class="img-fluid mr-5" src="/img/flyer/LogoCostaRealtyFlyer.png" alt="">
                <img class="img-fluid" src="/img/flyer/DivisionListados-Logoflyer.PNG" alt="">
              </div> -->
              <div class="col-sm-12 title-flyer pl-3 pt-3 pb-3">
                <h2>¿Por qué enlistar <br> en exclusiva con nosotros?</h2>
              </div>
              <div class="col-sm-12 property-flyer text-left pr-0 pl-0">
                <img class="img-fluid" src="/img/flyer/vendermiinmueble-fleyer.PNG" alt="Vender-Mi-Inmueble-CostaRealty">
              </div>
              <div class="col-sm-6 text-justify benefits">
                <h2 class="display-4 font-weight-bold">Beneficios</h2>
                <ul>
                  <li> <b>Red de vendedores: </b>
                    Experimentados vendedores que colaboran directamente en nuestra firma.
                  </li>
                  <li> <b>Red Nacional e internacional de brokers: </b>
                    Su propiedad estará conectada a una red de bróker en México, Estados Unidos y Canadá.
                  </li>
                  <li> <b>Programas de promoción y Publicidad: </b>
                      <ul class="programs-list">
                        <li>•	División especializada en listados.</li>
                        <li>•	Departamento de Atención al Cliente.</li>
                        <li>•	E-Commerce y Diseño Publicitario.</li>
                        <li>•	Publicación en páginas web especializadas.</li>
                        <li>•	Folletos y Brochures digitales para redes de Brókers.</li>
                        <li>•	Redes sociales.</li>
                        <li>•	E-Mail blast que se envía a la base de datos en EE. UU. y Canadá.</li>
                        <li>•	Anuncios en revistas y periódicos.</li>
                        <li>•	Campaña AddWords the Google.</li>
                      </ul>
                  </li>
                  <li> <b>Avisos frecuentes: </b>
                  Le mantendremos al tanto constantemente de los avances en la comercialización de su propiedad y tendencias del mercado.
                  </li>
                  <li> <b>Tecnología de punta: </b>
                    Contamos con nuestro propio software de administración de propiedades, conectado a las principales páginas web de bienes raíces.
                  </li>
                  <li> <b>El precio justo es la clave: </b>
                    Costa Realty, al enlistar su propiedad EN EXCLUSIVA, le asesorará sobre precios y tendencias de la zona.
                  </li>
                  
                </ul>
              </div>
              <div class="col-sm-6 text-justify prosyc">
                <h4 class="mt-3">Pros y contras</h4>
                <p> Mientras algunos opinan que lo mejor es no otorgar la exclusiva
                  para tener más opciones de promover la propiedad, los especialistas
                  aseguran que no tenerla limita la promoción en medios especializados
                  y minimiza el entusiasmo de los vendedores..</p>
                <h4>¿Cómo funciona la exclusiva inmobiliaria?</h4>
                <p> Consiste en poner a la venta o renta un inmueble a través de sólo una
                  agencia inmobiliaria, quien será la única autorizada para promover la 
                  operación a través de todos los medios pertinentes. <br>
                  Una de las grandes ventajas es que únicamente una agencia defiende 
                  el interés del cliente y el precio de la propiedad, y así también, 
                  sólo hay un responsable de dar información. 
                  Los clientes se precalifican, por lo que hay control en quién
                  se lleva a la visita a la propiedad y se protege la identidad 
                  del cliente.
                </p>
                <h4>Recomendaciones</h4>
                <ul>
                <li>La realización y análisis de un precio correcto es la base para 
                  la efectiva comercialización de su propiedad.</li> <br>
                
                  <li>La postura de las agencias inmobiliarias más importantes del 
                    país respecto de la no exclusiva es clara, la mayoría están contra 
                    esta manera de operar, pues coinciden en que da pie a que agentes 
                    inmobiliarios poco serios se beneficien.
                  </li> <br>
                    <li>Poner a la venta una propiedad es sencillo,
                      pero venderla requiere estrategias claras y trabajo 
                      de promoción constante, por lo cual la recomendación es 
                      optar por la exclusiva inmobiliaria.
                  </li>
                </ul>

                  
              </div>
            </div>
        </div>
      </div>
      <div class="row contact-flyer mt-4">
        <div class="col-sm-12">
            <FlyerForm/>
        </div>
      </div>
      <div class="row map mt-4">
        <div class="col-sm-12">
          <div class="contact-map box">
            <div id="map" class="contact-map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3128.4907615255024!2d-86.81995493317314!3d21.175330090905764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x929d73e1e95834e2!2sKeller%20Williams%20Breeze!5e0!3m2!1ses!2smx!4v1603520825941!5m2!1ses!2smx" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FlyerForm from '@/components/layout/FlyerForm.vue'

export default {
  components:{
    FlyerForm,
  }

}
</script>


<style scoped>
/* .sprop{
  background-image: url('/img/flyer/bg-blue.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
background-position: left;
} */
.mail-flyer{
  font-size: 15px;
}
.SellProperty{
  margin-top: 9rem;
}
.phones-flyer{
  font-size: 17px;
}
.logos-flyer .img-fluid{
  width: 40%;
}
  .logos-flyer{
    padding-top: inherit ;
    margin-top: inherit ;
  }
  .title-flyer, .title-flyer h2{
    background-color:#bd1734;
    color: #fff !important;
    text-align: left;
    clip-path: polygon(0 0, 83% 0, 100% 100%, 0% 100%);
    font-size: 2rem;
    font-weight: bold;
  }
  .property-flyer .img-fluid{
    width: 100%;
  }
  .benefits, .prosyc{
    color: #000;
  }

.programs-list li:before{
  content: inherit !important; 
  font-family: inherit !important;
  display: inline-block;
  margin-left: inherit !important;
  width: inherit !important;
  color:inherit !important;
  font-size: inherit !important;
  margin-top: 1px !important;
}
.benefits ul{
  list-style: none;
  padding: 0;
}          
.benefits li {
  padding-left: 1.3em;
  margin-top: 0.6em;
}
.benefits li:before {
  content: "\f14a"; 
  font-family: ionicons;
  display: inline-block;
  margin-left: -1.3em; 
  width: 1.3em; 
  color:#bd1734;
  font-size: 1.3em;
  
}
.left-flyer{
  margin-top: 6rem;
  margin-bottom: 30rem;
}
.right-flyer, .right-flyer {
  font-size: 14px;
}
@media only screen  and (max-width: 991px){
  .logos-flyer{
    padding-top: 1.5rem;
    margin-top: 3rem;
  }
  .property-flyer .img-fluid{
    width: 100%;
  }
  .left-flyer{
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}
@media (max-width: 768px) {
  .title-flyer, .title-flyer h2{
    font-size: 1.5rem;
  }
}
.left-flyer {
  background-color: #313d55;
  color: #fff;
  font-size: 13px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
    rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
    rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}
.left-flyer h5 {
  color: #dd2d2d;
}
.left-flyer h6,
a {
  color: #fff;
}
.light-flyer {
  background-color: #92d5e5;
  padding: 10px;
}
.socialicons-flyer i {
  color: #fff !important;
  font-size: 25px;
  padding-inline-start: 14px;
}
.socialicons-flyer {
  background: linear-gradient(
    94deg,
    rgba(89, 142, 149, 1) 0%,
    rgba(113, 183, 195, 1) 27%
  );
}
</style>
