<template>
  <!-- <Carousel/> -->
  <Bannerhome/>
  <!--ServicesSection/-->
  <!-- <MasterBrokerSection/> -->
  <!-- <BestDevelopmentsSection/> -->
  <BestDevelopmentsSection2/>
  <PropertiesSectionHome/>
  <!-- <BestPropertiesSection/> -->
  <!--BestAgentsSection/-->
  <PlacesSection/>
  <HomeVideosSection/>
  <!-- <LatestNewsSection/> -->
  <!--TestimonialsSection/-->
</template>

<script>
import { mapGetters } from 'vuex'
import Carousel from '@/components/properties/Carousel.vue'
import Bannerhome from '@/components/layout/Bannerhome.vue'
//import ServicesSection from '@/components/ServicesSection.vue'
import MasterBrokerSection from '@/components/MasterBrokerSection.vue'
import BestDevelopmentsSection from '@/components/BestDevelopmentsSection.vue'
import BestDevelopmentsSection2 from '@/components/BestDevelopmentsSection2.vue'
import BestPropertiesSection from '@/components/BestPropertiesSection.vue'
import PropertiesSectionHome from '@/components/PropertiesSectionHome.vue'
import BestAgentsSection from '@/components/BestAgentsSection.vue'
import LatestNewsSection from '@/components/LatestNewsSection.vue'
//import TestimonialsSection from '@/components/TestimonialsSection.vue'
import HomeVideosSection from '@/components/HomeVideosSection.vue'
import PlacesSection from '@/components/PlacesSection.vue'

export default {
  name: 'Home',
  components: {
    Carousel,
    //ServicesSection,
    Bannerhome,
    MasterBrokerSection,
    BestDevelopmentsSection,
    BestDevelopmentsSection2,
    BestPropertiesSection,
    PropertiesSectionHome,
    BestAgentsSection,
    LatestNewsSection,
    //TestimonialsSection,
    HomeVideosSection,
    PlacesSection
  },
  computed:{
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
      error:'_getError',
    }),
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
  }
}
</script>
