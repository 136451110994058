<template>
  <div class="container">
    <div class="card-body">
      <section id="DescripConte" style="text-align:justify">
        <p>
          <strong class="markRed">COSTA REALTY S.A. DE C.V.</strong> Inició
          operaciones en diciembre de 1996. Fundada por sus socios y directores,
          cada uno con una amplia trayectoria en dirección y administración de
          desarrollos inmobiliarios, marketing, promociones y ventas. Su
          objetivo fue y sigue siendo, proporcionar servicios de alta calidad a
          los mercados de Cancún, la Riviera Maya y el Caribe mexicano y
          recientemente en Puerto Vallarta y Cabo San Lucas. Por esta razón
          Costa Realty ha invertido cantidades sustanciales y esfuerzo para
          posicionarse rápidamente y definitivamente como el Líder de los
          mercados inmobiliarios en estas regiones.
        </p>
        <p>
          Debido a su capacidad de producción de venta, Costa Realty ha sido
          seleccionado como Master Broker&nbsp; por&nbsp; los principales&nbsp;
          desarrolladores de la zona, cumpliendo y en la mayoría de los casos,
          superando los objetivos de venta.
        </p>
        <p>
          Costa Realty ha centrado sus esfuerzos en la comercialización de
          proyectos exclusivos `llave en mano´´ como Master Broker,
          involucrándose en la búsqueda y compra de terrenos y diseño del
          proyecto, colaborando estrechamente con el promotor y su equipo.
          (Véase listado de abajo).
        </p>
        <p>
          La división comercial de ventas, manejada personalmente por los socios
          directores, reporta informes de venta de alta producción desde el
          inicio de operaciones, aprovechando un portafolio bien establecido de
          los Directores, incluyendo corporaciones hoteleras, de golf , marinas
          y desarrollos inmobiliarios de México, Estados Unidos, Canadá y Europa
          <br />
          A nivel dirección&nbsp; ofrecemos servicios integrales como:
        </p>
        <p>
          - Consultoría a desarrolladores<br />
          - Construcción de proyectos&nbsp; Llave en Mano <br />
          - Alianzas estratégicas<br />
          - Programas de promoción y publicidad<br />
          - Estudios de mercado:<br />
          - Preferencias de mercado y tendencias<br />
          - Origen y rango de la inversión <br />
          - Ubicación geográfica de clientes para diferentes productos
        </p>
        <p>
          Estos elementos son constantemente supervisados y se reflejan a diario
          en nuestra base de datos que es consultada con frecuencia por
          organismos tales como Bancomext, fuente internacional de financiación
          de proyectos por excelencia en México y Fonatur Agencia Federal a
          cargo de Cancún desde su inicio.
        </p>
        <p>
          Nuestra base de datos, ventas, inventarios y estudios de mercado, etc.
          son controlados por nuestro propio programa de software, desarrollado
          durante los años.
        </p>
        <p>
          Costa Realty logró sus dos objetivos originales rápidamente:
          Convertirse en el líder indiscutible del mercado inmobiliario de
          Cancún y la Riviera Maya y mucho más importante,
          <strong>proporcionar</strong
          ><strong>
            los mejores servicios inmobiliarios y asistencia para el público e
            inversionistas en el caribe.
          </strong>
        </p>
        <p>
          Costa Realty ha lanzado recientemente su división de lujo ‘’ COSTA
          LUXURY PORTFOLIO’’ para cubrir la creciente demanda de Propiedades de
          Lujo en destinos de Mexico.
        </p>
        <p>
          Este año (2012) Costa Realty está en proceso de abrir oficinas en la
          ciudad de México, Monterrey, Mérida, Puerto Vallarta, Acapulco y
          Dallas, Texas.
        </p>
        <p v-if="info.about_us == 0 & info.about_us_eng == 0 || info.about_us == 0"
          style="text-align:center; font-size:18px; text-transform: uppercase">
          <!-- {{$t("pages.about.company.description")}} -->
        </p>
        <p class="jump-line" v-else
          style="text-align:center; font-size:18px; text-transform: uppercase">
          <strong class="markRed">COSTA REALTY S.A. DE C.V.</strong> <About 
            :aboutx="activeLocale == 'es' || !info.about_us_eng
            ? info.about_us	 : info.about_us_eng"
            />
        </p>
        <span v-if="info.our_slogan == 0 & info.our_slogan_eng == 0" 
          > </span
        >
        <span v-else class="jump-line">
          <Slogan 
            :sloganx="activeLocale == 'es' || !info.our_slogan_eng
            ? info.our_slogan	 : info.our_slogan_eng"
            />
        </span>
          <p v-if="info.our_mission == 0 & info.our_mission_eng == 0" 
            style="text-align: justify; font-size: 16px; text-transform: uppercase;">
          </p>
          <p v-else
            style="text-align: justify; font-size: 16px; text-transform: uppercase;" class="jump-line">
            <Mision 
              :misionx="activeLocale == 'es' || !info.our_mission_eng
              ? info.our_mission	 : info.our_mission_eng"
              />
          </p>
          <p v-if="info.our_vision == 0 & info.our_vision_eng == 0"
            style="text-align: justify; font-size: 16px; text-transform: uppercase;" >
          </p>
          <p v-else
            style="text-align: justify; font-size: 16px; text-transform: uppercase;" class="jump-line">
            <Vision 
              :visionx="activeLocale == 'es' || !info.our_vision_eng
              ? info.our_vision	 : info.our_vision_eng"
              />
          </p>
          <p v-if="info.our_values == 0 & info.our_values_eng == 0"
            style="text-align: justify; font-size: 16px; text-transform: uppercase;" >
          </p>
          <p v-else
            style="text-align: justify; font-size: 16px; text-transform: uppercase;" class="jump-line">
            <Values 
              :valuesx="activeLocale == 'es' || !info.our_values_eng
              ? info.our_values	 : info.our_values_eng"
              />
          </p>
        <div id="tablas" align="left">
          
          <h3>Historico de Ventas</h3>
          <br />
          <h4>PROYECTOS •<em>CANCUN ZONA HOTELERA</em></h4>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">PROYECTO</th>
                <th scope="col">DESARROLLADOR</th>
                <th scope="col">#UNIDADES</th>
                <th scope="col">AÑO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MARALAGO</td>
                <td>GICSA</td>
                <td bgcolor="#cfd8dd">76</td>
                <td bgcolor="#cfd8dd">1998</td>
              </tr>
              <tr>
                <td>ISLA REAL</td>
                <td>
                  CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO, S.A. DE C.V.
                </td>
                <td>28</td>
                <td>2001</td>
              </tr>
              <tr>
                <td>ISLA ROMANTICA</td>
                <td>CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO</td>
                <td>41</td>
                <td>2003</td>
              </tr>
              <tr>
                <td>ISLA PARAISO</td>
                <td>DISTRIBUIDORA DISUR,&nbsp; S.A. DE C.V.</td>
                <td>90</td>
                <td>2003</td>
              </tr>
              <tr>
                <td>LAS OLAS</td>
                <td>GICSA</td>
                <td>84</td>
                <td>2004</td>
              </tr>
              <tr>
                <td>EMERALD</td>
                <td>GRAN CORAL, S.A. DE C.V.</td>
                <td>104</td>
                <td>2006</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>PROYECTOS - <em>CANCUN ZONA CENTRO</em></h4>
          <tdpalma del="" sol="" td=""> </tdpalma><td2005 td=""> </td2005>
          <table class="table table-hover cc_cursor">
            <thead>
              <tr>
                <th scope="col">PROYECTO</th>
                <th scope="col">DESARROLLADOR</th>
                <th scope="col">#UNIDADES</th>
                <th scope="col">AÑO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>LA HACIENDA</td>
                <td>GRUPO GADOL, S.A DE C.V.</td>
                <td>36</td>
                <td>2000</td>
              </tr>
              <tr>
                <td>GRUPO GADOL, S.A. DE C.V.</td>
                <td>64</td>
                <td>2002</td>
              </tr>
              <tr>
                <td>PALMA DEL MAR</td>
                <td>GRUPO GADOL, S.A. DE C.V.</td>
                <td>62</td>
                <td>2003</td>
              </tr>
              <tr>
                <td>LA CUSPIDE</td>
                <td>A+B INMOBILIARIO, S.A. DE C.V.</td>
                <td>64</td>
                <td>2004</td>
              </tr>
              <tr>
                <td>CORAL RIVIERA</td>
                <td>VILLSA</td>
                <td>16</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>TORRE BONAMPAK</td>
                <td>VILLSA</td>
                <td>28</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>SKY CANCUN RESIDENCES</td>
                <td>ICON GROUP,&nbsp; S.A. DE C.V.</td>
                <td>86</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>MALECON AMERICAS</td>
                <td>ICON GROUP, S.A. DE C.V.</td>
                <td>500</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>ISLA DEL SOL</td>
                <td>GRUPO ISLA, S.A. DE C.V.</td>
                <td>15</td>
                <td>2002</td>
              </tr>
              <tr>
                <td>ISLA BONITA</td>
                <td>GRUPO ISLA, S.A. DE C.V.</td>
                <td>25</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>COMERCIAL -<em>CANCUN ZONA CENTRO</em></h4>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">PROYECTO</th>
                <th scope="col">DESARROLLADOR</th>
                <th scope="col">#UNIDADES</th>
                <th scope="col">AÑO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PLAZA PLAYACAR</td>
                <td>INMOBILIARIA CONSTRUCTORA Y DESARROLLADORA SA. DE CV.</td>
                <td>47</td>
                <td>2000</td>
              </tr>
              <tr>
                <td>PLAZA MONARCA</td>
                <td>WASH AUTOLAVADO SA. DE CV.</td>
                <td>27</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>PLAZA VIVENDI</td>
                <td>BANCA MIFEL, S.A. FIDEICOMISO 338/2002</td>
                <td>27</td>
                <td>2005</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>PROYECTOS <em>PLAYA DEL CARMEN</em></h4>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">
                  PROYECTO
                </th>
                <th scope="col">DESARROLLADOR</th>
                <th scope="col">#UNIDADES</th>
                <th scope="col">AÑO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>FISHERMEN’S VILLAGE</td>
                <td>GRUPO KAHUA, S.A. DE C.V</td>
                <td>74</td>
                <td>1996</td>
              </tr>
              <tr>
                <td>VILLAS PAKAL</td>
                <td>GRUPO TORRE, S.A DE C.V.</td>
                <td>52</td>
                <td>1998</td>
              </tr>
              <tr>
                <td>AZUL</td>
                <td>DESARROLLOS DPC, S.A. DE C.V.</td>
                <td>32</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>OKOL PARAISO</td>
                <td>UCALLI, S.A. DE C.V.</td>
                <td>21</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>ZAPHIRA</td>
                <td>ICON GROUP, S.A DE C.V.</td>
                <td>32</td>
                <td>2005</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>PROYECTOS <em>PUERTO AVENTURAS</em></h4>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">
                  PROYECTO
                </th>
                <th scope="col">DESARROLLADOR</th>
                <th scope="col">#UNIDADES</th>
                <th scope="col">AÑO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>VILLA DEL MAR 1</td>
                <td>
                  CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO, S.A. DE C.V.
                </td>
                <td>92</td>
                <td>1997</td>
              </tr>
              <tr>
                <td>VILLA DEL MAR 2</td>
                <td>
                  CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO, S.A. DE C.V.
                </td>
                <td>38</td>
                <td>2003</td>
              </tr>
              <tr>
                <td>GRAN PENINSULA</td>
                <td>
                  CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO, S.A. DE C.V.
                </td>
                <td>72</td>
                <td>2004</td>
              </tr>
              <tr>
                <td>PUEBLO ESCONDIDO</td>
                <td>DARKITECTOS 24&nbsp; 2 FASES</td>
                <td>31</td>
                <td>2004</td>
              </tr>
              <tr>
                <td>REAL DEL MAR</td>
                <td>GRUPO CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO</td>
                <td>8</td>
                <td>2004</td>
              </tr>
              <tr>
                <td>GARZA BLANCA</td>
                <td>ROBERTO Y MARIANO REBOLLEDO</td>
                <td>12</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>NAUTILIUS</td>
                <td>ROQUE CAMPOS Y JOSE CARLOS GONZALEZ IRIARTE</td>
                <td>8</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>VILLAS TALHIA</td>
                <td>SF MEXICO, LLC DEVELOPMENT</td>
                <td>16</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>KAN TE NAH</td>
                <td>JORGE CASAÑAS</td>
                <td>12</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>MARINA DOLCE</td>
                <td>CAD DESARROLLADORA DE INMUEBLES, S.A. DE C.V.</td>
                <td>8</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>CHAC HAL AL</td>
                <td>CAD DESARROLLADORA DE INMUEBLES, S.A. DE C.V.</td>
                <td>117</td>
                <td>1987</td>
              </tr>
              <tr>
                <td>PORTO BELLO</td>
                <td>CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO</td>
                <td>21</td>
                <td>2002</td>
              </tr>
              <tr>
                <td>GRAN MARINA 1</td>
                <td>CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO</td>
                <td>22</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>GRAN MARINA 2</td>
                <td>CAD CONSTRUCCIONES ARQUITECTURA Y DISEÑO</td>
                <td>22</td>
                <td>2008</td>
              </tr>
              <tr>
                <td>MARINA MIA</td>
                <td>DESARROLLOS KUVERT, S.A. DE C.V.</td>
                <td>56</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>LUNA TUUNICH</td>
                <td>EZKERRE SA DE CV</td>
                <td>5</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>FASE NAUTICA</td>
                <td>FIDEICOMISO PTO. AVENTURAS</td>
                <td>200 LOTS</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>FASE GOLF</td>
                <td>FIDEICOMISO PTO. AVENTURAS</td>
                <td>88&nbsp; LOTS</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>NAUTICO PLANO 4</td>
                <td>FIDEICOMISO PTO. AVENTURAS</td>
                <td>130&nbsp; LOTS</td>
                <td>2011</td>
              </tr>
              <tr>
                <td>SECC. LAGOS</td>
                <td>FIDEICOMISO PTO. AVENTURAS</td>
                <td>55&nbsp; LOTS</td>
                <td>2001</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>PROYECTOS <em>PUERTO VALLARTA</em></h4>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">PROYECTO</th>
                <th scope="col">DESARROLLADOR</th>
                <th scope="col">#UNIDADES</th>
                <th scope="col">AÑO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td width="141" valign="top">OLAS VALLARTA</td>
                <td>GICSA</td>
                <td>500</td>
                <td>2006</td>
              </tr>
              <tr>
                <td width="141" valign="top">
                  IKARIA
                  <p></p>
                </td>
                <td>MILENIUM DESARROLLOS INMOBILIARIOS EN 2 TORRES</td>
                <td>96</td>
                <td>2008</td>
              </tr>
              <tr>
                <td width="141" valign="top">SAN SEBASTIAN</td>
                <td>GONZALO GONZALEZ EN DOS TORRES</td>
                <td>48</td>
                <td>2008</td>
              </tr>
              <tr>
                <td width="141" valign="top">RESERVA SAN SEBASTIAN</td>
                <td>HECTOR VILLANUEVA</td>
                <td>400 lotes</td>
                <td>2009</td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>PROYECTOS <em>LOS CABOS</em></h4>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">PROYECTO</th>
                <th scope="col"><p>DESARROLLADOR</p></th>
                <th scope="col">#UNIDADES</th>
                <th scope="col">AÑO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>PUNTA ARENA</td>
                <td>GRUPO POZO VARGAS</td>
                <td>74</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>MARVISTA</td>
                <td>GORHTEIN ARQUITECTOS</td>
                <td>9</td>
                <td>2005</td>
              </tr>
              <tr>
                <td>MIRADOR</td>
                <td>HIPOTECARIA VERTICE</td>
                <td>56</td>
                <td>2006</td>
              </tr>
              <tr>
                <td>VILLAS EMANUELLE</td>
                <td>INMOBILIARIA ROCA</td>
                <td>15</td>
                <td>2006</td>
              </tr>
            </tbody>
          </table>
          <h4>HOTELES Y HOTELS SITES</h4>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">PROYECTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>HOTEL RIU PLAYACAR</td>
              </tr>
              <tr>
                <td>HOTEL RIU TEQUILA PLAYACAR</td>
              </tr>
              <tr>
                <td>HOTEL RIU PALACE PLAYACAR</td>
              </tr>
              <tr>
                <td>HOTEL IBEROSTAR PLAYACAR</td>
              </tr>
              <tr>
                <td>HOTEL IBEROSTAR PARAÍSO</td>
              </tr>
              <tr>
                <td>HOTEL RIU CANCUN</td>
              </tr>
              <tr>
                <td>HOTEL RIU PALACE VALLARTA</td>
              </tr>
              <tr>
                <td>HOTEL RIU VALLARTA</td>
              </tr>
              <tr>
                <td>HOTEL RIU CABO SAN LUCAS MEDANOS BEACH</td>
              </tr>
              <tr>
                <td>HOTEL MANDARIN RIVIERA MAYA</td>
              </tr>
              <tr>
                <td>LOS BARRILES&nbsp; CABOS</td>
              </tr>
            </tbody>
          </table>
          
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import About from "@/components/about/About.vue";
import Slogan from "@/components/about/Slogan.vue";
import Mision from "@/components/about/Mision.vue";
import Vision from "@/components/about/Vision.vue";
import Values from "@/components/about/Values.vue";
export default {
    components: {
      About,
      Slogan,
      Mision,
      Vision,
      Values,

  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created() {
     window.scrollTo(0, 0);
    console.log("create footer");
  },
};
</script>
<style scoped>
.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>