<template>
  <div class="col-md-4 text-center">
    <div class="card-box-d">
      <div class="card-img-d">
        <img :src="item.image" :alt="item.name" class="img-d img-fluid" />
      </div>
    </div>

    <a type="button" class="btn btn-a mt-4" target="_blank" :href="item.url">{{$t('components.master_broker.button')}}</a>
  </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    }
}
</script>
<style scoped>
.img-fluid {
    height: auto !important;
}
.btn-a{
    color: #ffffff !important;
    text-decoration: none;
}
</style>