<template>
    <form class="form-a form-home-search" v-on:submit.prevent="searchProps">

        <!-- <label for="Type"></label>
                    <select class="form-control form-propordevs" @change="changeType">
                        <option value="properties">{{$t('layout.nav.properties')}}</option>
                        <option value="developments">{{$t('layout.nav.developments')}}</option>
                    </select>
                -->

        <div class="col-sm-12 btns-search-props-or-devs">
            <div class="button-group ">
                <button class="form-control btn-prop active-button" @click="selectProperties">
                    {{ $t('layout.nav.properties') }}
                </button>
                <button class="form-control btn-dev" @click="selectDevelopments">
                    {{ $t('layout.nav.developments') }}
                </button>
            </div>
        </div>

        <div class="row justify-content-center">
            <div v-if="!search.searchDevelopments" class="col-sm-12 col-md-4 col-lg-3">
                <div class="form-group">
                    <label for="Type"></label>
                    <select class="form-control form-control-lg form-control-a" v-model="search.operation" id="Operation">
                        <option selected value="">{{ $t('layout.header.search_form.operation_input') }}</option>
                        <option value="1">{{ $t('layout.header.search_form.option_sold') }}</option>
                        <option value="2">{{ $t('layout.header.search_form.option_rent') }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3  " v-if="!search.searchDevelopments">
                <div class="form-group">
                    <label for="Type"></label>
                    <select class="form-control form-control-lg form-control-a " v-model="search.type" id="Type">
                        <option selected value="">{{ $t('layout.header.search_form.property_type_input') }}</option>
                        <option v-for="(type, i) in input.types" :key="i" :index="i" :value="type.typepropertyid">
                            {{ type.typepropertyspa }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 col-lg-3 ">
                <div class="form-group">
                    <label for="city"></label>
                    <select v-if="!search.searchDevelopments" class="form-control form-control-lg form-control-a "
                        required="required" v-model="search.city" id="city">
                        <option selected value="">{{ $t('layout.header.search_form.city_input') }}</option>
                        <option v-for="(city, i) in input.cities" :key="i" :index="i" :value="city.city">{{ city.city }}
                        </option>
                    </select>
                    <select v-else class="form-control form-control-lg form-control-a" v-model="search.city" id="city">
                        <option selected value="">{{ $t('layout.header.search_form.city_input') }}</option>
                        <option v-for="(city, i) in input.citiesDevs" :key="i" :index="i" :value="city.city">{{ city.city }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-12 col-lg-3 pt-4">
                <button type="submit" class="btn btn-b"><span class="fa fa-search pr-1" aria-hidden="true"
                        style="display: inline!important;"></span>{{ $t('layout.header.search_form.button') }}</button>
            </div>
        </div>

    </form>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            search: {
                operation: "",
                type: "",
                typeDevs: "",
                city: "",
                keyword: "",
                minPrice: "",
                maxPrice: "",
                urbanization: "",
                beds: "",
                baths: "",
                parking: "",
                searchDevelopments: false
            },
            searching: false,
            moneyMin: {
                thousand: ',',
                prefix: '',
                precision: 0,
                masked: false
            },
            moneyMax: {
                thousand: ',',
                prefix: '',
                precision: 0,
                masked: false
            },
            advanced: false
        }
    },
    computed: {
        ...mapGetters({
            input: "_getInputs"
        }),

    },
    methods: {
        searchProps() {
            if (this.hasValidSearchCriteria()) {
                this.searching = true;
                let searchForm = this.search;
                this.$store.dispatch("getResults", searchForm);

                if (!this.search.searchDevelopments) {
                    if (this.$route.name != 'Results') this.$router.push({ name: 'Results' });
                } else {
                    if (this.$route.name != 'ResultsDevs') this.$router.push({ name: 'ResultsDevs' });
                }

                this.searching = false;
                this.$store.dispatch("set_search", searchForm);
            } else {
                console.log("Por favor, ingresa criterios de búsqueda válidos antes de buscar.");
            }
        },
        /*Valida si ha llenado alguno de los campos antes de hacer la busqueda */
        hasValidSearchCriteria() {
            const searchCriteria = [this.search.operation, this.search.type, this.search.city, this.search.keyword];
            return searchCriteria.some(criteria => criteria !== "");
        },


        advancedChange() {
            let temp = this.advanced;
            this.advanced = !temp;
        },
        selectProperties() {
            this.search.searchDevelopments = false;
            document.querySelector('.btn-prop').classList.add('active-button');
            document.querySelector('.btn-dev').classList.remove('active-button');
        },
        selectDevelopments() {
            this.search.searchDevelopments = true;
            document.querySelector('.btn-dev').classList.add('active-button');
            document.querySelector('.btn-prop').classList.remove('active-button');
        }
        /* Por si quiero tener las opciones en un select */
        /* changeType(event) {
        //     const opcionSeleccionada = event.target.value;

        //     if (opcionSeleccionada === 'properties') {
        //         this.funcionParaproperties();
        //     } else if (opcionSeleccionada === 'developments') {
        //         this.funcionParadevelopments();
        //     }
        //     },
        //     funcionParaproperties() {
        //         this.search.searchDevelopments = false
        //         this.search.operation="";
        //         this.search.type="";
        //         this.search.typeDevs="";
        //         this.search.city="";
        //         this.search.keyword="";
        //         this.search.minPrice="";
        //         this.search.maxPrice="";
        //         this.search.urbanization="";
        //         this.search.beds="";
        //         this.search.baths="";
        //         this.search.parking="";
        //     },
        //     funcionParadevelopments() {
        //         this.search.searchDevelopments = true
        //         this.search.operation="";
        //         this.search.type="";
        //         this.search.typeDevs="";
        //         this.search.city="";
        //         this.search.keyword="";
        //         this.search.minPrice="";
        //         this.search.maxPrice="";
        //         this.search.urbanization="";
        //         this.search.beds="";
        //         this.search.baths="";
        //         this.search.parking="";
        },*/
    },
    mounted() {
        /*--/ Navbar Collapse /--*/
        $('.navbar-toggle-box-collapse').on('click', function () {
            $('body').removeClass('box-collapse-closed').addClass('box-collapse-open');
        });
        $('.close-box-collapse, .click-closed').on('click', function () {
            $('body').removeClass('box-collapse-open').addClass('box-collapse-closed');
            $('.menu-list ul').slideUp(700);
        });

        this.$store.dispatch("getInputs");
    }
}
</script>
<style scoped>
#switchPropHome h5 {
    margin-right: calc(100% - 280px);
}

input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

#switchPropHome label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

#switchPropHome label:after {
    content: '';
    position: absolute;
    top: 2.8px;
    left: 5px;
    width: 19px;
    height: 19px;
    background: #fff;
    border-radius: 20px;
    transition: 0.70s;
}

#switchPropHome input:checked+label {
    background: #bada55;
}

#switchPropHome input:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

#switchPropHome label:active:after {
    width: 40px;
}

.form-group {
    margin-bottom: 0rem;
}

/* @media (min-width: 992px) {
    .form-home-search .col-lg-3{
        max-width: 21%;
    }
}
.form-propordevs{
    background: #c78787;
    border-radius: 50px !important;
    color: #000 !important;
    margin: 0;
    height: 53px;
    border: none;
} */
.btns-search-props-or-devs {
    margin-top: -20px;
}

.btns-search-props-or-devs .button-group {
    display: inline-flex;
    box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}

.btns-search-props-or-devs .btn-prop {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.btns-search-props-or-devs .btn-dev {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.active-button {
    background-color: #bd1734;
    color: #ffffff;
}
</style>