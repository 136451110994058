<template>
 <Preloader v-if="!dev"/>
  <template v-else>
      <!--/ Intro Single star /-->
    <Banner :pageTitle="(activeLocale=='es' || !dev.developmentNameEn)?dev.developmentNameEs:dev.developmentNameEn" :address="`${(dev.city!=0)?dev.city:''} ${(dev.state!=0&& dev.city!=0)?',':''} ${dev.state}`" :page="$route.meta.breadcrumb" />
    <!--/ Intro Single End /-->

    <!--/ Property Single Star /-->
    <section class="property-single nav-arrow-b pt-0">
        <div class="container">
        <div class="row">
            <div class="col-sm-12">

            <!-- Carousel Start -->
            <div
                v-if="dev.images"
                id="development-single-carousel"
                class="owl-carousel owl-arrow gallery-property"
            >
                <div v-for="(image,i) in dev.images" :key="i" class="carousel-item-b">
                    <a class="example-image-link animated-link-dark" :href="image.largefile" data-lightbox="example-set" :data-title="image.alt">
                        <img :src="image.largefile" :alt="image.alt" />
                    </a>
                </div>
            </div>
            <!-- Carousel End -->

            <div class="row justify-content-between section-top10">
                <div class="col-md-5 col-lg-4">
                    
                <!-- Price Start -->
                <div v-if="!badPrice && dev.priceFrom!=0" class="property-price d-flex justify-content-center foo">
                    <div class="card-header-c d-flex">
                        <div class="card-box-ico">
                            <span class="ion-money">$</span>
                        </div>
                        <div class="card-title-c align-self-center">
                            <h5 class="title-c">
                                <small>{{$t('components.property_card.since')}}:</small> <br>
                                {{format}}
                            </h5>
                        </div>
                    </div>
                </div>
                <div v-else class="property-price d-flex justify-content-center foo">
                    <div class="card-header-c d-flex">
                       <button type="submit" @click="goTo('contact-information')" class="btn btn-a">{{$t('pages.development_detail.more_information_button')}}</button>
                    </div>
                </div>
                <!-- Price End -->

                <!-- Details Start -->
                <div class="property-summary">
                    <div class="row">
                    <div class="col-sm-12">
                        <div class="title-box-d section-t4">
                        <h3 class="title-d">{{$t('pages.development_detail.features')}}</h3>
                        </div>
                    </div>
                    </div>
                    <div class="summary-list">
                    <ul class="list">
                        <li class="d-flex justify-content-between">
                        <strong>ID:</strong>
                        <span>#{{folio}}</span>
                        </li>
                        <li v-if="dev.presales" class="d-flex justify-content-between">
                            <strong>{{$t('pages.development_detail.feature_presale')}}</strong>
                        </li>
                        <li v-if="dev.towers" class="d-flex justify-content-between">
                        <strong>{{$t("pages.development_detail.towers")}}:</strong>
                        <span>{{dev.towers }}</span>
                        </li>
                        <li class="d-flex justify-content-between">
                            <strong>{{$t('pages.development_detail.feature_address')}}:</strong>
                            <span>{{dev.address}}</span>
                        </li>
                        <li v-if="dev.m2cFrom " class="d-flex justify-content-between">
                            <strong>{{$t('pages.development_detail.feature_area_cons')}}:</strong>
                            <span>{{dev.m2cFrom}} {{(dev.m2cFrom==dev.m2cTo)?'':`a ${dev.m2cTo}`}} m<sup>2</sup> </span>
                        </li>
                        <li v-if="dev.bedFrom" class="d-flex justify-content-between">
                        <strong>{{$t('pages.development_detail.feature_bedrooms')}}:</strong>
                        <span>{{dev.bedFrom}} {{(dev.bedFrom==dev.bedTo)?'':`a ${dev.bedTo}`}}</span>
                        </li>
                        <li v-if="dev.bathFrom" class="d-flex justify-content-between">
                        <strong>{{$t('pages.development_detail.feature_bathrooms')}}:</strong>
                        <span>{{dev.bathFrom}} {{(dev.bathFrom==dev.bathTo)?'':`a ${dev.bathTo}`}}</span>
                        </li>
                        <li v-if="dev.pkFrom||dev.pkTo" class="d-flex justify-content-between">
                            <strong>{{$t('pages.development_detail.feature_parking')}}:</strong>
                            <span>{{dev.pkFrom}} {{(dev.pkTo)?`- ${dev.pkTo}`:''}}</span>
                        </li>
                        <li v-if="dev.monthsMarket" class="d-flex justify-content-between">
                            <strong>{{$t('pages.development_detail.feature_months_market')}}:</strong>
                            <span>{{dev.monthsMarket}}</span>
                        </li>
                        <li v-if="dev.unitsAviable" class="d-flex justify-content-between">
                            <strong>{{$t('pages.development_detail.feature_units_aviable')}}:</strong>
                            <span>{{dev.unitsAviable}}</span>
                        </li>
                    </ul>
                    </div>
                </div>
                <!-- Details End -->

                <!-- Details Start -->
                <div class="property-summary">
                    <div class="row">
                    <div class="col-sm-12">
                        <div class="title-box-d section-t3">
                        <h3 class="title-d">{{$t('pages.development_detail.documents')}}</h3>
                        </div>
                    </div>
                    </div>
                    <div class="summary-list">
                        <ul class="list">
                            <li v-if="dev.flyer.flyerEs4" class="d-flex justify-content-between">
                                <strong>Flyer Español - 4 fotos</strong>
                                <a :href="dev.flyer.flyerEs4" target="_blank">                                
                                    <span>Ver Documento ></span>
                                </a>
                            </li>
                            <li v-if="dev.flyer.flyerEs10" class="d-flex justify-content-between">
                                <strong>Flyer Español - 10 fotos</strong>
                                <a :href="dev.flyer.flyerEs10" target="_blank">                                
                                    <span>Ver Documento ></span>
                                </a>
                            </li>
                            <li v-if="dev.flyer.flyerEn4" class="d-flex justify-content-between">
                                <strong>Flyer English - 4 fotos</strong>
                                <a :href="dev.flyer.flyerEs4" target="_blank">                                
                                    <span>See Document ></span>
                                </a>
                            </li>
                            <li v-if="dev.flyer.flyerEn10" class="d-flex justify-content-between">
                                <strong>Flyer English - 10 fotos</strong>
                                <a :href="dev.flyer.flyerEs10" target="_blank">                                
                                    <span>See Document ></span>
                                </a>
                            </li>                 
                        </ul>
                    </div>
                </div>
                <!-- Share Buttons Start -->
                <div class="post-share">
                    <span>{{$t('pages.development_detail.share_prop')}}: </span>
                    <ul class="list-inline socials">
                        <li class="list-inline-item">
                            <a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank">
                                <i class="fab fa-facebook-f" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="`https://twitter.com/home?status=${url}`" target="_blank">
                                <i class="fab fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="`https://pinterest.com/pin/create/button/?url=${url}&amp;media=${dev.images[0].smallfile}&amp;description=${dev.developmentNameEs}`" target="_blank">
                                <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="`https://api.whatsapp.com/send?text=${$t('pages.development_detail.share_whats_message')}:${url}&phone=${dev.agent.cellphone}`"
                                target="_blank"
                            >
                                <i class="fab fa-whatsapp" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- Share Buttons End -->
                    
                <!-- Details End -->
                </div>
                <div class="col-md-7 col-lg-7 section-md-t3">

                <!-- Description Start -->
                <section>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="title-box-d">
                                <h3 class="title-d">{{$t('pages.development_detail.description_prop')}}</h3>
                            </div>
                        </div>
                    </div>
                    <ul
                        class="nav nav-pills-a nav-pills mb-3 "
                        id="pills-tab"
                        role="tablist"
                    >
                        <li class="nav-item">
                        <a
                            :class="['nav-link',(activeLocale=='es')?'active':'']"
                            id="spanish-tab"
                            data-toggle="pill"
                            href="#spanish"
                            role="tab"
                            aria-controls="spanish"
                            aria-selected="true"
                            >Español</a>
                        </li>
                        <li class="nav-item">
                        <a
                            :class="['nav-link',(activeLocale=='en')?'active':'']"
                            id="english-tab"
                            data-toggle="pill"
                            href="#english"
                            role="tab"
                            aria-controls="english"
                            aria-selected="false"
                            v-if="dev.descriptionEn"
                            >English</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="languajes-tabContent">
                        <div
                            :class="['tab-pane fade',(activeLocale=='es')?'show active':'']"
                            id="spanish"
                            role="tabpanel"
                            aria-labelledby="spanish-tab"
                            v-if="dev.descriptionEs"
                        >
                            <div class="property-description">
                                <p class="description color-text-a">
                                    <pre>{{dev.descriptionEs}}</pre>
                                </p>
                            </div>

                        </div>
                        <div
                            :class="['tab-pane fade',(activeLocale=='en')?'show active':'']"
                            id="english"
                            role="tabpanel"
                            aria-labelledby="english-tab"
                            v-if="dev.descriptionEn"
                        >
                            <div class="property-description">
                                <p class="description color-text-a">
                                    <pre>{{dev.descriptionEn}}</pre>
                                </p>
                            </div>

                        </div>
                    </div>
                </section>
                <!-- Description End -->

                <!-- Amenities Start -->
                <div v-if=" dev.amenities||dev.other_amenities" class="row section-t3">
                    <div class="col-sm-12">
                    <div class="title-box-d">
                        <h3 class="title-d">{{$t('pages.development_detail.amenities_prop')}}</h3>
                    </div>
                    </div>
                </div>
                <div class="amenities-list color-text-a">
                
                    <ul class="list-a no-margin">
                        <li v-for="(item,i) in dev.amenities" :key="i" :index="i" >{{item}}</li>
                        <li v-for="(item,i) in dev.other_amenities" :key="i" :index="i" >{{item}}</li>
                    </ul>
                </div>
                <!-- Amenities End -->

                <!-- Services Start -->
                <div v-if="dev.services || dev.other_services" class="row section-t3">
                    <div class="col-sm-12">
                    <div class="title-box-d">
                        <h3 class="title-d">{{$t('pages.development_detail.services_prop')}}</h3>
                    </div>
                    </div>
                </div>
                <div class="amenities-list color-text-a">
                    <ul class="list-a no-margin">
                        <li v-for="(item,i) in dev.services" :key="i" :index="i" >{{item}}</li>
                        <li v-for="(item,i) in dev.other_services" :key="i" :index="i" >{{item}}</li>
                    </ul>
                </div>
                <!-- Services End -->
                </div>
            </div>
            </div>
            <div class="col-md-10 offset-md-1">
            <ul
                class="nav nav-pills-a nav-pills mb-3 section-t3"
                id="pills-tab"
                role="tablist"
            >
                <li v-if="dev.video" class="nav-item">
                <a
                    class="nav-link active"
                    id="pills-video-tab"
                    data-toggle="pill"
                    href="#pills-video"
                    role="tab"
                    aria-controls="pills-video"
                    aria-selected="true"
                    >Video</a
                >
                </li>
                <!--i  v-if="prop.latitude|| prop.longitude" class="nav-item">
                <a
                    :class="['nav-link',(!prop.video)?'active':'']"
                    id="pills-map-tab"
                    data-toggle="pill"
                    href="#pills-map"
                    role="tab"
                    aria-controls="pills-map"
                    aria-selected="false"
                >Ubicación</a>
                </li-->
            </ul>
            <div class="tab-content" id="pills-tabContent">
                
                <!-- Video Start -->
                <div
                v-if="dev.video"
                class="tab-pane fade show active"
                id="pills-video"
                role="tabpanel"
                aria-labelledby="pills-video-tab"
                >
                <iframe
                    :src="dev.video"
                    width="100%"
                    height="460"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                ></iframe>
                </div>
                <!-- Video End -->
                
                <!-- Location Start
                <div
                v-if="prop.latitude|| prop.longitude"
                :class="['tab-pane fade', (!prop.video)?'show active':'']"
                id="pills-map"
                role="tabpanel"
                aria-labelledby="pills-map-tab"
                >
                    <div id="map"></div>
                </div>
                Location End -->
            </div>
            </div>
            <div id="contact-information" class="col-md-12">
            <div class="row section-t3">
                <div class="col-sm-12">
                <div class="title-box-d">
                    <h3 class="title-d">{{$t('pages.development_detail.agent_information')}}</h3>
                </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div v-if="dev.agent.image" class="col-md-6 col-lg-4 text-center">
                    <img :src="dev.agent.image" alt="" class="img-fluid" />
                </div>
                <div class="col-md-6 col-lg-4">
                <div class="property-agent">
                    <h4 class="title-agent">{{dev.agent.fullname}}</h4>
                    <ul class="list-unstyled">
                    <li v-if="dev.agent.phone!=0" class="d-flex justify-content-between styled-item">
                        <strong>{{$t('pages.development_detail.agent_phone')}}:</strong>
                        <span class="color-text-a">{{dev.agent.phone}}</span>
                    </li>
                    <li v-if="dev.agent.cellphone" class="d-flex justify-content-between styled-item">
                        <strong>{{$t('pages.development_detail.agent_cellphone')}}:</strong>
                        <span class="color-text-a">{{dev.agent.cellphone}}</span>
                    </li>
                    <li v-if="dev.agent.email" class="d-flex justify-content-between styled-item">
                        <strong>{{$t('pages.development_detail.agent_email')}}:</strong>
                        <span class="color-text-a">{{dev.agent.email}}</span>
                    </li>
                    <li v-if="dev.agent.skypeid!=0" class="d-flex justify-content-between styled-item">
                        <strong>Skype:</strong>
                        <span class="color-text-a">{{dev.agent.skypeid}}</span>
                    </li>
                    </ul>
                    <div class="socials-a">
                    <ul class="list-inline">
                        <li v-if="dev.agent.fb && dev.agent.fb!=0" class="list-inline-item">
                        <a target="_blank" :href="dev.agent.fb">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                        </li>
                        <li v-if="dev.agent.tweet && dev.agent.tweet!=0" class="list-inline-item">
                        <a target="_blank" :href="dev.agent.tweet">
                            <i class="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                        </li>
                        <li v-if="dev.agent.pinterest && dev.agent.pinterest!=0" class="list-inline-item">
                            <a target="_blank" :href="dev.agent.pinterest">
                                <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="dev.agent.gmas && dev.agent.gmas!=0" class="list-inline-item">
                        <a target="_blank" :href="dev.agent.gmas">
                            <i class="fab fa-google-plus-g" aria-hidden="true"></i>
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <div class="col-md-12 col-lg-4">
                    <div class="property-contact">
                        <ContactForm from="development"/>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
    <!--/ Property Single End /-->
  </template>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
      badPrice:false
    };
  },
  computed: {
    ...mapGetters({
      dev: "_getDevelopment",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let price = val.dev.priceFrom;
      //Formateo de decimales
      let decimalPrice = price.lastIndexOf(".");     
      //Extaracción de moneda
      let currentPriceM = price.indexOf("MXN");
      let currentPriceU = price.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = price.substr(0, decimalPrice);
      
      if(formatPrice.slice(1)=='0') this.badPrice=true;
      
      formatPrice+= " ";


      if (currentPriceM != "-1") {
        formatPrice += price.substr(currentPriceM);
      } else {
        formatPrice += price.substr(currentPriceU);
      }
      return formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;
    let data = {
      folio: this.folio,
    };
    //call metadatas for property
    this.$store.dispatch("getDevMetadata", this.folio);
    // end call metadatas for property
    this.$store.dispatch("getDevelopment", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;

    /*Send Data to Access
    if (process.env.NODE_ENV === "production") {
      this.$getLocation().then((coordinates) => {
        let accessData = {
          lat: coordinates.lat,
          lng: coordinates.lng,
          url: this.url,
          propertyId: this.folio,
        };

        this.$store.dispatch("setPropertyClick", accessData);
      });
    }*/
  },

  updated() {
    /*--/ Property owl owl /--*/
    $("#development-single-carousel").owlCarousel({
      loop: true,
      margin: 0,
      nav: true,
      navText: [
        '<i class="ion-ios-arrow-back" aria-hidden="true"></i>',
        '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
      },
    });
  },
  methods:{
    goTo(ref){ 
        document.getElementById(ref).scrollIntoView({block: "center", behavior: "smooth"});
    },
  }
};
</script>
<style >
.img-fluid{
    width: 80%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    margin: auto;
  }
.summary-list {
  padding-right: 0rem;
}
.summary-list .list span {
  text-align: end;
}
.summary-list .list span:hover {
  color: #bd1734;
}
pre {
  white-space: pre-line;

  height: auto;

  overflow: hidden;

  background: transparent;

  border-color: transparent;

  color: inherit;

  font-family: inherit;

  font-size: 0.9rem;

  text-align: justify;
}
.styled-item{
    flex-flow: wrap !important;
  }
</style>
