<template>
  <!--/ Agents Grid Star /-->
  <section class="agents-grid grid">
    <div v-for="(office,i) in agentByOffice" :key="office.office" :index="i" class="container mb-4">
        <template v-if="office.agents">
            <div v-if="office.office!=60" class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                <h2 class="title-a">{{office.nameOffice}}</h2>
                </div>
            </div>
            <SectionLoader v-if="!office.agents"/>
            <div v-else class="row justify-content-md-center">
                <AgentCard v-for="(item,i) in office.agents" :key="i" :index="i" :agent="item"/>
            </div>
        </template>
    </div>
  </section>
  <!--/ Agents Grid End /-->
</template>

<script>
import { mapGetters } from 'vuex'
import AgentCard from '@/components/agents/AgentCard.vue'
import SectionLoader from '@/components/layout/SectionLoader.vue';
export default {
    components:{
        AgentCard,
        SectionLoader
    },
    computed:{
        ...mapGetters({
            offices:'_getOffices',
            //team:'_getTeam',
            error:'_getError',
            agentByOffice:'_getTeamByOffice'
        }),
    },
    created(){
      //this.$store.dispatch('getOffices');
      //this.$store.dispatch('getTeam');
      this.$store.dispatch('getTeamByOffice');
    },
};
</script>

<style>
</style>