<template>
    <!--/ Contact Star /-->
  <section class="contact">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="contact-map box">
            <div id="map" class="contact-map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3128.4907615255024!2d-86.81995493317314!3d21.175330090905764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x929d73e1e95834e2!2sKeller%20Williams%20Breeze!5e0!3m2!1ses!2smx!4v1603520825941!5m2!1ses!2smx" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
            </div>
          </div>
        </div>
        <div class="col-sm-12 section-t8">
          <div class="row">
            <div class="col-md-7">
              <ContactForm from="contact"/>
            </div>
            <div class="col-md-5 section-md-t3">
              <div class="icon-box section-b2">
                <div class="icon-box-icon">
                  <span class="ion-ios-paper-plane"></span>
                </div>
                <div class="icon-box-content table-cell">
                  <div class="icon-box-title">
                    <h4 class="icon-title">{{$t('pages.contact.contact_info')}} </h4>
                  </div>
                  <div class="icon-box-content">
                    <p v-if="info.reserveemail!=0" class="mb-1" >{{$t('layout.footer.company_email')}}.
                      <span class="color-a">{{info.reserveemail}}</span>
                    </p>
                    <p class="mb-1">{{$t('layout.footer.company_phone')}}.
                      <span class="color-a">+52 998 321 0234</span>
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="info.address" class="icon-box section-b2">
                <div class="icon-box-icon">
                  <span class="ion-ios-pin"></span>
                </div>
                <div class="icon-box-content table-cell">
                  <div class="icon-box-title">
                    <h4 class="icon-title">{{$t('pages.contact.company_location')}}</h4>
                  </div>
                  <div class="icon-box-content">
                    <p class="mb-1">
                      {{info.address}}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="info.fb!=0 ||info.tw!=0 ||info.instagram!=0 ||info.youtube!=0 ||info.linkedin!=0 ||info.pinterest!=0 ||info.gmas!=0"  class="icon-box">
                <div class="icon-box-icon">
                  <span class="ion-ios-redo"></span>
                </div>
                <div class="icon-box-content table-cell">
                  <div class="icon-box-title">
                    <h4 class="icon-title">{{$t('pages.contact.social_title')}}</h4>
                  </div>
                  <div class="icon-box-content">
                    <div class="socials-footer">
                      <ul class="list-inline">
                        <li v-if="info.fb"  class="list-inline-item">
                            <a :href="info.fb" target="_blank">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="info.tw" class="list-inline-item">
                            <a :href="info.tw" target="_blank">
                            <i class="fab fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="info.instagram"  class="list-inline-item">
                            <a :href="info.instagram" target="_blank">
                            <i class="fab fa-instagram" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="info.youtube"  class="list-inline-item">
                            <a :href="info.youtube" target="_blank">
                            <i class="fab fa-youtube" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="info.linkedin"  class="list-inline-item">
                            <a :href="info.linkedin" target="_blank">
                            <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="info.pinterest"  class="list-inline-item">
                            <a :href="info.pinterest" target="_blank">
                            <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="info.gmas" class="list-inline-item">
                            <a :href="info.gmas"  target="_blank">
                            <i class="fab fa-google-plus-g" aria-hidden="true"></i>
                            </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--/ Contact End /-->
</template>

<script>
import { mapGetters } from 'vuex'
import ContactForm from '@/components/layout/ContactForm.vue';
export default {
    components:{
        ContactForm
    },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
}
</script>

<style>

</style>