<template>
    <!--/ News Star /-->
    <div class="container property-grid grid pt-4">

        <div class="row">
            <div class="col-md-12">
                <div class="title-wrap d-flex justify-content-between">
                    <div class="title-box">
                    <h2 class="title-a">{{$t('components.best_developments.title')}}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" >
          
            <div v-for="(item,i) in developments"  :key="item.folio" :name="item.folio" :index="i" class="col-md-6 col-lg-4">
                <BestDevelopmentCard2  :item="item"/>
            </div> 
            <div class="col-sm-12 text-center">
                <router-link class="btn btn-b-n" to="/desarrollos">Ver todos los desarrollos
                </router-link>
            </div>
        </div>
   
    </div>
    <!--/ News End /-->
</template>
<script>
import { mapGetters } from "vuex";
import BestDevelopmentCard2 from '@/components/developments/BestDevelopmentCard2.vue'
import BestDevelopmentCard from '@/components/developments/BestDevelopmentCard.vue'

export default {
    components:{
        BestDevelopmentCard2,
        BestDevelopmentCard
    },
     computed: {
        ...mapGetters({
            developments: "_getDevelopments2",
            bestDevelopments: "_getBestDevelopments"
        }),
    },
    created() {
        this.$store.dispatch("getDevelopments2");
    },
    mounted(){

    }
}
</script>