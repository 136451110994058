<template>
   <!--/ Intro Single star /-->
    <section class="intro-single">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-lg-8">
                    <div class="title-single-box">
                        <h3 class="title-single">{{$t(`${pageTitle}`)}}</h3>
                        <span v-if="nameRoute=='Property' || nameRoute=='Development'|| nameRoute=='Agent'" class="color-text-a">{{address}}</span>
                    </div>
                </div>
                <div class="col-md-12 col-lg-4">
                    <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link to="/">{{$t('layout.banner.home_link')}}</router-link>
                            </li>
                            <li v-if="nameRoute=='Property'" class="breadcrumb-item">
                                <router-link to="/propiedades">{{$t('layout.banner.properties_link')}}</router-link>
                            </li>
                            <li v-if="nameRoute=='Agent'" class="breadcrumb-item">
                                <router-link to="/agentes">{{$t('layout.banner.agents_link')}}</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">
                                {{$t(`${page}`)}}
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <!--/ Intro Single End /-->
</template>

<script>
export default {
    props: {
        page:{
            type:String,
            required:true
        },
        pageTitle:{
            type:String,
            required:true
        },
        address:{
            type:String,
            required:false
        }
    }, 
    computed:{
        nameRoute(){
            return this.$route.name
        },
        activeLocale(){
            return this.$i18n.locale
        }
    }
}
</script>