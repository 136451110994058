<template>
  <select name="" id="locale" v-model="$i18n.locale">
      <option v-for="locale in locales" :key="locale.key" :value="locale.key" :style="`background-image:url(${locale.icon});`">
          {{locale.label}}
      </option>
  </select>
</template>

<script>
export default {
    data(){
        return{
            locales:[
                {key:"es",label:"Español", icon:"/img/mx-icon.png"},
                {key:"en",label:"English", icon:"/img/eu-icon.png"}
            ]
        }
    },
    watch:{
        "$i18n.locale":{
            handler(locale){
                localStorage.setItem("locale",locale);
            }
        }
    }
}
</script>

<style>
#locale{
    padding: 2px 4px;
    font-size: 0.9rem!important;
}
</style>