<template>
    <!--/ Development Grid Star /-->
    <section class="property-grid grid">
        <div class="container">
            <SectionLoader v-if="!developments"/>
            <div v-else class="row">
                <div class="col-md-4" v-for="(item,i) in developments" :key="item.folio" :name="item.folio" :index="i">
                    <DevelopmentCard :item="item"/>
                </div>
            </div>
        </div>
    </section>
    <!--/ Development Grid End /-->
</template>

<script>
import { mapGetters } from "vuex";
import SectionLoader from '@/components/layout/SectionLoader.vue';
import DevelopmentCard from '@/components/developments/DevelopmentCard.vue';
export default {
    components:{
        DevelopmentCard,
        SectionLoader
    },
    computed: {
        ...mapGetters({
            developments: "_getDevelopments"
        }),
    },
    created() {
        this.$store.dispatch("getDevelopments");
    },
}
</script>