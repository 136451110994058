<template>
  <p >{{$t(`${aboutx}`)}}</p>
</template>

<script>
export default {
  props: {
    aboutx: { type: String, required: true },
  },
};
</script>

<style scoped>

</style>