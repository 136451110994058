<template>
    <!--/ News Star /-->
    <section class="section-news section-t8">
        <div class="container">
        <div class="row">
            <div class="col-md-12">
            <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                <h2 class="title-a">Videos</h2>
                </div>
            </div>
            </div>
        </div>
        <div id="videos-carousel" class="owl-carousel owl-theme">
            <div v-for="(item,i) in videos" :key="item.id" :index="i" class="carousel-item-c">
                <VideoCard  :item="item"/>
            </div>
        </div>
        </div>
    </section>
    <!--/ News End /-->
</template>
<script>
import { mapGetters } from "vuex";
import VideoCard from '@/components/generalCards/VideoCard.vue'

export default {
    components:{
        VideoCard
    },
    computed: {
        ...mapGetters({
            videos: "_getHomeVideos"
        }),
    },
    mounted(){
    	/*--/ News owl /--*/
        $('#videos-carousel').owlCarousel({
            loop: true,
            margin: 30,
            responsive: {
                0: {  
                    items: 1,
                },
                769: {
                    items: 2,
                },
                992: {
                    items: 3,
                }
            }
        });
    }
}
</script>