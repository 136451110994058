
<template>
<div class="col-sm-12 col-md-6 col-lg-4 mb-2">
    <div class="card-box-b card-shadow news-box mt-2 mb-2">
        <div class="img-box-b">
            <img src="../../../public/img/propertieszonehome/CANCUN_ZONA_HOTELERA.jpg"  class="img-b img-fluid">
        </div>
        <div class="card-overlay">
        <div class="card-header-b">
            <div class="card-title-b">
                <h4 class="title-2"><a @click="searchProps()" class="cc_pointer" >Propiedades Cancún Zona Hotelera</a></h4>
            </div>
            <div class="card-date">
                <span class="date-b">
                    <a @click="searchProps()" class="cc_pointer" >{{$t('components.property_card.link')}} <span class="ion-ios-arrow-forward"></span></a></span>
            </div>
        </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    },
    methods:{
        searchProps(){
            this.searching=true;
            this.$store.dispatch("getPropsZH");
            
            if(this.$route.name!='ResultsSingle')
                this.$router.push({name: 'ResultsSingle'});
            
            this.searching=false;
        },        
    },
}
</script>
<style scoped>

.date-b a,
.title-2{
    color: #ffffff;
}
.img-box-b{
    height: 300px;
}
.img-fluid {
    width: 100%!important;
    max-width: auto;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.news-box .title-2:hover{
    color:#fff;
    text-shadow: 0 0 10px #BD1734;
}
.card-date a:hover{
    color:#BD1734;
}
</style>