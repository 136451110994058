<template>
<div class="row m-0 mb-2">
    <button class="col-6 btn btn-b2 hidden d-lg-none d-xl-none" type="button" data-toggle="collapse" data-target="#collapsesearch" aria-expanded="false" aria-controls="collapseExample">
        Buscador <span class="fa fa-search" aria-hidden="true"></span>
    </button>
    <div class="col-6 search-r  d-lg-none d-xl-none">
        <div class="p-2 flex-fill ">
            <nav aria-label="breadcrumb" class=" d-flex justify-content-lg-end">
                <ul class="breadcrumb nav nav-tabs p-0 mb-0"  id="myTab" role="tablist"> 
                    <li class="breadcrumb-item">
                        <a class="nav-link active" id="map-tab" data-toggle="tab" href="#map" role="tab" aria-controls="map" aria-selected="false"><i class="fas fa-map-marker-alt"></i></a>
                    </li>
                    <li class="breadcrumb-item">
                        <a class="nav-link" id="grid-tab" data-toggle="tab" href="#grid" role="tab" aria-controls="grid" aria-selected="true"><i class="fas fa-th-large"></i></a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div class="col-12 collapse dont-collapse-sm p-0" id="collapsesearch">
        <div class="well">
            <form class="form-a search-r" v-on:submit.prevent="searchProps">
                <div class="d-flex flex-column flex-lg-row">
                    <div v-if="!search.searchDevelopments" class="p-2 flex-fill ">
                        <div class="form-group">
                        <select class="form-control form-control-lg form-control-a" v-model="search.operation" id="Operation">
                            <option selected value="">{{$t('layout.header.search_form.operation_input')}}</option>
                            <option value="1">{{$t('layout.header.search_form.option_sold')}}</option>
                            <option value="2">{{$t('layout.header.search_form.option_rent')}}</option>
                        </select>
                        </div>
                    </div>
                    <div class="p-2 flex-fill ">
                        <div class="form-group" v-if="!search.searchDevelopments">
                            <select class="form-control form-control-lg form-control-a" v-model="search.type" id="Type">
                                <option selected value="">{{$t('layout.header.search_form.property_type_input')}}</option>
                                <option v-for="(type,i) in input.types" :key="i" :index="i" :value="type.typepropertyid">{{type.typepropertyspa}}</option>
                            </select>
                        </div>
                        <div class="form-group" v-else>
                            <select class="form-control form-control-lg form-control-a" v-model="search.type" id="Type">
                                <option selected value="">{{$t('layout.header.search_form.development_type_input')}}</option>
                                <option v-for="(type,i) in input.typesDevs" :key="i" :index="i" :value="type.type_development_id">{{type.name_type_esp}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="p-2 flex-fill ">
                        <div class="form-group">
                            <select v-if="!search.searchDevelopments" class="form-control form-control-lg form-control-a" v-model="search.city" id="city">
                                <option selected value="">{{$t('layout.header.search_form.city_input')}}</option>
                                <option v-for="(city,i) in input.cities" :key="i" :index="i" :value="city.city">{{city.city}}</option>
                            </select>
                            <select v-else class="form-control form-control-lg form-control-a" v-model="search.city" id="city">
                                <option selected value="">{{$t('layout.header.search_form.city_input')}}</option>
                                <option v-for="(city,i) in input.citiesDevs" :key="i" :index="i" :value="city.city">{{city.city}}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="!search.searchDevelopments" class=" p-2 flex-fill ">
                        <div class="form-group">
                        <select class="form-control form-control-lg form-control-a" v-model="search.beds" id="bedrooms">
                            <option selected value="">{{$t('layout.header.search_form.bedrooms_input')}}</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                        </div>
                    </div>
                    <div v-if="!search.searchDevelopments" class=" p-2 flex-fill ">
                        <div class="form-group">
                        <select class="form-control form-control-lg form-control-a" v-model="search.baths" id="bathrooms">
                            <option selected value="">{{$t('layout.header.search_form.bathrooms_input')}}</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                        </div>
                    </div>
                    <div class="p-2 flex-fill ">
                        <div class="form-group">
                        <select class="form-control form-control-lg form-control-a" v-model="search.minPrice" id="priceMin">
                            <option selected value="">$ Min</option>
                            <option>$50,000</option>
                            <option>$100,000</option>
                            <option>$150,000</option>
                            <option>$200,000</option>
                        </select>
                        </div>
                    </div>
                    <div class="p-2 flex-fill ">
                            <div class="form-group">
                            <select class="form-control form-control-lg form-control-a" v-model="search.maxPrice" id="priceMax">
                                <option selected value="">$ Max</option>
                                <option>$50,000</option>
                                <option>$100,000</option>
                                <option>$150,000</option>
                                <option>$200,000</option>
                            </select>
                        </div>
                    </div>
                    <div class="p-2 mt-2 flex-fill ">
                        <button type="submit" data-toggle="collapse" data-target="#collapsesearch" class="btn btn-b2">{{$t('layout.header.search_form.button')}}</button>
                    </div>
                    <div class="p-2 flex-fill d-none d-lg-block">
                        <nav aria-label="breadcrumb" class=" d-flex justify-content-lg-end">
                            <ul class="breadcrumb nav nav-tabs p-0"  id="myTab" role="tablist">
                                <li class="breadcrumb-item">
                                    <a class="nav-link active" id="map-tab" data-toggle="tab" href="#map" role="tab" aria-controls="map" aria-selected="false"><i class="fas fa-map-marker-alt"></i></a>
                                </li>
                                <li class="breadcrumb-item">
                                    <a class="nav-link" id="grid-tab" data-toggle="tab" href="#grid" role="tab" aria-controls="grid" aria-selected="true"><i class="fas fa-th-large"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
</div>
    
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return{
            search:{
                operation:"",
                type:"",
                typeDevs:"",
                city:"",
                keyword:"",
                minPrice:"",
                maxPrice:"",
                urbanization:"",
                beds:"",
                baths:"",
                parking:"",
                searchDevelopments:false
            },
            searching:false,
            moneyMin:{
                thousand:',',
                prefix:'',
                precision:0,
                masked:false
            },
            moneyMax:{
                thousand:',',
                prefix:'',
                precision:0,
                masked:false
            },
            advanced:false
        }
    },
    computed: {
        ...mapGetters({
            input: "_getInputs"
        }),
        
    },
    methods:{
        searchProps(){
            this.searching=true;
            let searchForm = this.search;
            this.$store.dispatch("getResults",searchForm);
            
            if(!this.search.searchDevelopments){
                if(this.$route.name!='Results')
                    this.$router.push({name: 'Results'});
            }else{
                if(this.$route.name!='ResultsDevs')
                    this.$router.push({name: 'ResultsDevs'});
            }
            
            this.searching=false;

        },
        advancedChange(){
            let temp = this.advanced;
            this.advanced=!temp;
        },
        changeForm(){
            this.search.operation="";
            this.search.type="";
            this.search.typeDevs="";
            this.search.city="";
            this.search.keyword="";
            this.search.minPrice="";
            this.search.maxPrice="";
            this.search.urbanization="";
            this.search.beds="";
            this.search.baths="";
            this.search.parking="";
        },
        test(){
        $(function () {
        $('#test1').click(function() {
            $(this).next('#test2').slideToggle();
        });
        });
        }
    },
    mounted(){
        /*--/ Navbar Collapse /--*/
        $('.navbar-toggle-box-collapse').on('click', function () {
            $('body').removeClass('box-collapse-closed').addClass('box-collapse-open');
        });
        $('.close-box-collapse, .click-closed').on('click', function () {
            $('body').removeClass('box-collapse-open').addClass('box-collapse-closed');
            $('.menu-list ul').slideUp(700);
        });
        
        this.$store.dispatch("getInputs");
    }
}
</script>
<style scoped>
/* for collapse search */
    @media (min-width: 992px) {
        .collapse.dont-collapse-sm {
            display: block;
            height: auto !important;
            visibility: visible;
        }
    }
/* for collapse search */
#switchProp  h5{
    margin-right: calc(100% - 280px);
}
.rpu{
    display:none;
}
input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

#switchProp label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 25px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

#switchProp label:after {
	content: '';
	position: absolute;
	top: 2.8px;
	left: 5px;
	width: 19px;
	height: 19px;
	background: #fff;
	border-radius: 20px;
	transition: 0.70s;
}

#switchProp input:checked + label {
	background: #bada55;
}

#switchProp input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

#switchProp label:active:after {
	width: 40px;
}
.card{
    position:absolute !important;
}
.search-r .nav-link.active{
    color: #bd1734;
    background-color: #dadee1;
    border-color: none;
    border-radius: 1.25rem!important;
}
.search-r .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0rem;
  color: #6c757d;
  content: "";
}
.search-r .breadcrumb{
    border-radius: 1.25rem!important;
    background-color: #f5f7fb;
    border: none !important;
}
.search-r .form-group{
    margin-bottom: 0px;
}
.search-r .form-a .form-control{
    border-bottom: 1px solid #fff;
}
.search-r .nav-link {
    color:#a3a3a3;
}
</style>