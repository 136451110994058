<template>
 <Preloader v-if="!prop"/>
  <template v-else>
      <!--/ Intro Single star /-->
    <Banner :pageTitle="(activeLocale=='es'|| !prop.propertyNameEn)?prop.propertyNameEs:prop.propertyNameEn" :address="`${(prop.state!=0)?prop.city+',':prop.city} ${prop.state}`" :page="$route.meta.breadcrumb" />
    <!--/ Intro Single End /-->
    
    <!--/ Property Single Star /-->
    <section class="property-single nav-arrow-b pt-0">
        <div class="container">
        <div class="row">
            <div class="col-sm-12">

            <!-- Carousel Start -->
            <div
                v-if="prop.images"
                id="property-single-carousel"
                class="owl-carousel owl-arrow gallery-property"
            >
                <div v-for="(image,i) in prop.images" :key="i" class="carousel-item-b">
                    <a class="example-image-link animated-link-dark" :href="image.largefile" data-lightbox="example-set" :data-title="image.alt">
                        <img :src="image.largefile" :alt="image.alt" class="carousel-image" />
                    </a>
                </div>
            </div>
            <!-- Carousel End -->

            <div class="row justify-content-between section-top10">
                <div class="col-md-5 col-lg-4">
                   
                <!-- Price Start -->
                <div class="property-price d-flex justify-content-center foo">
                    
                    <div class="card-header-c d-flex">
                    <div class="card-box-ico">
                        <span class="ion-money">$</span>
                    </div>
                    <div v-if="prop.operation.opportunity_sold !=0" class="card-title-c align-self-center">
                            <h5 class="title-c">{{format}}</h5>
                        </div>
                            <!-- <div v-if="prop.reduction==0 " class="card-title-c align-self-center">
                                <h5 class="title-c mb-less">{{format}}</h5>
                                <small class="subt">{{$t('pages.property_detail.reduction')}}</small>
                                <h5 v-if="prop.pricepermonth" class="title-c mb-less">{{formatr}}</h5>
                                <small  v-if="prop.pricepermonth" class="subt">PRECIO RENTA</small>
                                <h5 v-if="prop.pricepermonth" class="title-c mb-less">{{formatr}}</h5>
                                <small  v-if="prop.pricepermonth" class="subt">PRECIO VENTA</small>
                            </div> -->
                            <div v-if="prop.price>0 && prop.operation.opportunity_sold==0" class="card-title-c align-self-center">
                                <h5 class="title-c mb-less">{{format}}</h5>
                                <small  v-if="prop.pricepermonth" class="subt">PRECIO VENTA</small>
                                <h5 v-if="prop.pricepermonth" class="title-c mb-less">{{formatr}}</h5>
                                <small  v-if="prop.pricepermonth" class="subt">PRECIO RENTA</small>
                            </div>
                            <div v-else class="card-title-c align-self-center">
                                <h5 class="title-c">{{format}}</h5>
                            </div>
                        
                    </div>
                </div>
                 <h4 class="ml-4" v-if="prop.operation.opportunity_sold !=0"> <em>{{$t('pages.property_detail.feature_opportunity')}} </em></h4>
                 <h4 class="ml-4" v-if="prop.reduction !=0"> <em>{{$t('pages.property_detail.reduction')}} </em></h4>
                <!-- Price End -->

                <!-- Details Start -->
                <div class="property-summary">
                    <div class="row">
                    <div class="col-sm-12">
                        <div class="title-box-d section-t3 section-md-t3">
                        <h3 class="title-d">{{$t('pages.property_detail.features')}}</h3>
                        </div>
                    </div>
                    </div>
                    <div class="summary-list">
                    <ul class="list">
                        <li class="d-flex justify-content-between">
                        <strong>ID:</strong>
                        <span>#{{folio}}</span>
                        </li>
                        <li class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_operation')}}:</strong>
                        <span>{{prop.toperation[0]}}</span>
                        </li>
                        <li class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_type')}}:</strong>
                        <span>{{prop.typepropertyEs}}</span>
                        </li>
                        <li v-if="prop.nameTower" class="d-flex justify-content-between">
                        <strong>{{$t("pages.property_detail.nameTower")}}:</strong>
                        <span>{{prop.nameTower }}</span>
                        </li>
                        <li class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_address')}}:</strong>
                        <span>{{prop.address}}</span>
                        </li>
                        <li v-if="prop.m2c" class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_area_cons')}}n:</strong>
                        <span>{{prop.m2c}} m<sup>2</sup>
                        </span>
                        </li>
                        <li v-if="prop.mlot" class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_area_lot')}}:</strong>
                        <span>{{prop.mlot}} m<sup>2</sup></span>
                        </li>
                        <li v-if="prop.bedrooms" class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_bedrooms')}}:</strong>
                        <span>{{prop.bedrooms}}</span>
                        </li>
                        <li v-if="prop.bathrooms" class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_bathrooms')}}:</strong>
                        <span>{{prop.bathrooms}}</span>
                        </li>
                        <li v-if="prop.num_parking" class="d-flex justify-content-between">
                        <strong>{{$t('pages.property_detail.feature_parking')}}:</strong>
                        <span>{{prop.num_parking}}</span>
                        </li>
                    </ul>
                    </div>
                </div>
                <!-- Details End -->

                <!-- Details Start -->
                <div class="property-summary">
                    <div class="row">
                    <div class="col-sm-12">
                        <div class="title-box-d section-t3">
                        <h3 class="title-d">{{$t('pages.property_detail.documents')}}</h3>
                        </div>
                    </div>
                    </div>
                    <div class="summary-list">
                    <ul class="list">
                        <li v-if="prop.flyerEs" class="d-flex justify-content-between">
                            <strong>Flyer Español</strong>
                            <a :href="prop.flyerEs" target="_blank">                                
                                <span>Ver Documento ></span>
                            </a>
                        </li>
                        <li v-if="prop.flyerEn" class="d-flex justify-content-between">
                            <strong>Flyer English</strong>
                            <a :href="prop.flyerEn" target="_blank">                                
                                <span>See Document ></span>
                            </a>
                        </li>
                        <hr>
                        <li v-if="prop.banner1" class="d-flex justify-content-between">
                            <strong>Banner 1 foto</strong>
                            <a :href="prop.banner1" target="_blank">                                
                                <span>Ver Documento ></span>
                            </a>
                        </li>
                        <li v-if="prop.banner3" class="d-flex justify-content-between">
                            <strong>Banner 3 fotos</strong>
                            <a :href="prop.banner3" target="_blank">                                
                                <span>Ver Documento ></span>
                            </a>
                        </li>
                        <li v-if="prop.banner4" class="d-flex justify-content-between">
                            <strong>Banner 4 fotos</strong>
                            <a :href="prop.banner4" target="_blank">                                
                                <span>Ver Documento ></span>
                            </a>
                        </li>
                        <li v-if="prop.banner6" class="d-flex justify-content-between">
                            <strong>Banner 6 fotos</strong>
                            <a :href="prop.banner6" target="_blank">                                
                                <span>Ver Documento ></span>
                            </a>
                        </li>                        
                    </ul>
                    </div>
                </div>
                <!-- Share Buttons Start -->
                <div class="post-share">
                    <span>{{$t('pages.property_detail.share_prop')}}: </span>
                    <ul class="list-inline socials">
                        <li class="list-inline-item">
                            <a :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`" target="_blank">
                                <i class="fab fa-facebook-f" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="`https://twitter.com/home?status=${url}`" target="_blank">
                                <i class="fab fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="`https://pinterest.com/pin/create/button/?url=${url}&amp;media=${prop.images[0].smallfile}&amp;description=${prop.propertyNameEs}`" target="_blank">
                                <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <a :href="`https://api.whatsapp.com/send?text=${$t('pages.property_detail.share_whats_message')}:${url}&phone=${prop.agent.cellphone}`"
                                target="_blank"
                            >
                                <i class="fab fa-whatsapp" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- Share Buttons End -->
                    
                <!-- Details End -->
                </div>
                <div class="col-md-7 col-lg-7 section-md-t3">

                <!-- Description Start -->
                <section>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="title-box-d">
                                <h3 class="title-d">{{$t('pages.property_detail.description_prop')}}</h3>
                            </div>
                        </div>
                    </div>
                    <ul
                        class="nav nav-pills-a nav-pills mb-3 "
                        id="pills-tab"
                        role="tablist"
                    >
                        <li class="nav-item">
                        <a
                            class="nav-link active"
                            id="spanish-tab"
                            data-toggle="pill"
                            href="#spanish"
                            role="tab"
                            aria-controls="spanish"
                            aria-selected="true"
                            >Español</a>
                        </li>
                        <li class="nav-item">
                        <a
                            class="nav-link"
                            id="english-tab"
                            data-toggle="pill"
                            href="#english"
                            role="tab"
                            aria-controls="english"
                            aria-selected="false"
                            v-if="prop.descriptionEn"
                            >English</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="languajes-tabContent">
                        <div
                            class="tab-pane fade show active"
                            id="spanish"
                            role="tabpanel"
                            aria-labelledby="spanish-tab"
                            v-if="prop.descriptionEs"
                        >
                            <div class="property-description">
                                <p class="description color-text-a">
                                    <pre>{{prop.descriptionEs}}</pre>
                                </p>
                            </div>

                        </div>
                        <div
                            class="tab-pane fade "
                            id="english"
                            role="tabpanel"
                            aria-labelledby="english-tab"
                            v-if="prop.descriptionEn"
                        >
                            <div class="property-description">
                                <p class="description color-text-a">
                                    <pre>{{prop.descriptionEn}}</pre>
                                </p>
                            </div>

                        </div>
                    </div>
                </section>
                <!-- Description End -->

                <!-- Amenities Start -->
                <div v-if=" prop.interiorAmenities||prop.exteriorAmenities||prop.developmentAmenities||prop.otherAmenities" class="row section-t3">
                    <div class="col-sm-12">
                    <div class="title-box-d">
                        <h3 class="title-d">{{$t('pages.property_detail.amenities_prop')}}</h3>
                    </div>
                    </div>
                </div>
                <div v-if="prop.interiorAmenities || prop.otherAmenities" class="amenities-list color-text-a">
                    <h5>{{$t('pages.property_detail.amenities_interior')}}</h5>
                    <ul class="list-a no-margin">
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.hall || prop.interiorAmenities.sala">

                            {{ $t("pages.amenities.int_sala") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.kitchen  || prop.interiorAmenities.cocina">

                            {{ $t("pages.amenities.int_cocina") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.dinning_room || prop.interiorAmenities.comedor">

                            {{ $t("pages.amenities.int_comedor") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.service_area || prop.interiorAmenities.area_servicio">

                            {{ $t("pages.amenities.int_areadeservicio") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.amueblado">

                            {{ $t("pages.amenities.int_amueblado") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.cuartoTv">

                            {{ $t("pages.amenities.int_cuartoTV") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.estudio">

                            {{ $t("pages.amenities.int_estudio") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.cocinaIntegral">

                            {{ $t("pages.amenities.int_cocinaIntegral") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.ctoLavado">

                            {{ $t("pages.amenities.int_ctoLavado") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.ctoServBaño">

                            {{ $t("pages.amenities.int_ctoServBano") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.area_servicio">

                            {{ $t("pages.amenities.int_ctoServBano") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.aa">

                            {{ $t("pages.amenities.int_aa") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.minisplit">

                            Mini split
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.ventiladores">

                            {{ $t("pages.amenities.int_ventiladores") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.calentadorAgua">

                            {{ $t("pages.amenities.int_calentadorAgua") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.casaInteligente">

                            {{ $t("pages.amenities.int_casaInteligente") }}
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.microondas">

                            {{ $t("pages.amenities.int_microondas") }} 
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.lavaPlatos">

                            {{ $t("pages.amenities.int_lavaPlatos") }} 
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.camaraFria">

                            {{ $t("pages.amenities.int_camaraFria") }} 
                        </li>
                        <li class="amenidades col-md-12" v-if="prop.interiorAmenities.central">

                            Central
                        </li>
                    </ul>
                </div>
                <div v-if="prop.exteriorAmenities" class="amenities-list color-text-a mt-3">
                    <h5>{{$t('pages.property_detail.amenities_exterior')}}</h5>
                    <ul class="list-a no-margin">
                               <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.balcon">

                        {{ $t("pages.amenities.ext_balcon") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.terraza">

                        {{ $t("pages.amenities.ext_terraza") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.jardin">

                        {{ $t("pages.amenities.ext_jardin") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.alberca">

                        {{ $t("pages.amenities.ext_alberca") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.elevador">

                        {{ $t("pages.amenities.ext_elevador") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.estacionamiento">

                        {{ $t("pages.amenities.ext_estacionamiento") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.bbq">

                        {{ $t("pages.amenities.ext_bbq") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.bar">

                        Bar
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.gimnasio">

                        {{ $t("pages.amenities.ext_gimnasio") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.muelle">

                        {{ $t("pages.amenities.ext_muelle") }} 
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.cisterna">

                        {{ $t("pages.amenities.ext_cisterna") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.jacuzzi">

                        Jacuzzi
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.plantaElectrica">

                        {{ $t("pages.amenities.ext_plantaElectrica") }} 
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.cercoElectrico">

                        {{ $t("pages.amenities.ext_cercoElectrico") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.puertaMuLock">

                        {{ $t("pages.amenities.ext_puertaMuLock") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.almacen">

                        {{ $t("pages.amenities.ext_almacen") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.panelesSolares">

                        {{ $t("pages.amenities.ext_panelesSolares") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.areaManiobras">

                        {{ $t("pages.amenities.ext_areaManiobras") }} 
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.casetaSeguridad">

                        {{ $t("pages.amenities.ext_casetaSeguridad") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.cortinasMetalicas">

                        {{ $t("pages.amenities.ext_cortinasMetalicas") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.areaDescarga">

                        {{ $t("pages.amenities.ext_areaDescarga") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.cortinasAnticiclonicas">

                        {{ $t("pages.amenities.ext_cortinasAnticiclonicas") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.techado">

                        {{ $t("pages.amenities.ext_techado") }}
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.solarium">

                        Solarium
                    </li>
                    <li class="amenidades col-md-12" v-if="prop.exteriorAmenities.patio_servicio">

                        {{ $t("pages.amenities.ext_patioservicio") }}
                    </li>
                    </ul>
                </div>
                <div v-if="prop.developmentAmenities" class="amenities-list color-text-a mt-3">
                    <h5>{{$t('pages.property_detail.amenities_development')}}</h5>
                    <ul class="list-a no-margin">
                    <li class="amenidades col-md-12" v-if="prop.developmentAmenities.condominio">

                      {{ $t("pages.amenities.man_condominio") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.cafetera">

                      {{ $t("pages.amenities.man_cafetera") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.clubGolf">

                      {{ $t("pages.amenities.man_clubGolf") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.casaClub">

                      {{ $t("pages.amenities.man_casaClub") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.alberca_d">

                      {{ $t("pages.amenities.man_alberca_d") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.muelle_d">

                      {{ $t("pages.amenities.man_muelle_d") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.clubPlaya">

                      {{ $t("pages.amenities.man_clubPlaya") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.spa">

                      SPA
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.gimnasio_d">

                      {{ $t("pages.amenities.man_gimnasio_d") }} 
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.canchaTenis">

                      {{ $t("pages.amenities.man_canchaTenis") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.juegosInf">

                      {{ $t("pages.amenities.man_juegosInf") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.lobby">

                      {{ $t("pages.amenities.man_lobby") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.elevador_d">

                      {{ $t("pages.amenities.man_elevador_d") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.salonEventos">

                      {{ $t("pages.amenities.man_salonEventos") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.restaurante">

                    {{ $t("pages.amenities.man_restaurante") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.comercios">

                      {{ $t("pages.amenities.man_comercios") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.bodega">

                      {{ $t("pages.amenities.man_bodega") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.seguridad">

                      {{ $t("pages.amenities.man_seguridad") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.accesoControlado">

                      {{ $t("pages.amenities.man_accesoControlado") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.servicioAdmin">

                      {{ $t("pages.amenities.man_servicioAdmin") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.centroNegocios">

                      {{ $t("pages.amenities.man_centroNegocios") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.albercaAlquiler">

                      {{ $t("pages.amenities.man_albercaAlquiler") }} 
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.servConcierge">

                      {{ $t("pages.amenities.man_servConcierge") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.otherServices">

                      {{ $t("pages.amenities.man_otherServices") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.m2constructionDev">

                      {{ $t("pages.amenities.man_m2constructionDev") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.m2greenArea">

                      {{ $t("pages.amenities.man_m2greenArea") }}
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.palapa">

                      Palapa
                  </li>
                  <li class="amenidades col-md-12" v-if="prop.developmentAmenities.marina">

                      {{ $t("pages.amenities.man_marina") }} 
                  </li>
                    </ul>
                </div>
                <!-- Amenities End -->

                <!-- Services Start -->
                <div v-if="prop.developmentServices || prop.services" class="row section-t3">
                    <div class="col-sm-12">
                    <div class="title-box-d">
                        <h3 class="title-d">{{$t('pages.property_detail.services_prop')}}</h3>
                    </div>
                    </div>
                </div>
                <div v-if="prop.developmentServices || prop.services" class="amenities-list color-text-a">
                    <ul class="list-a no-margin">
                       <li class="amenidades col-md-12" v-if="prop.developmentServices.mantenimiento">

                          {{ $t("pages.amenities.ser_mantenimiento") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.servTransp">

                          {{ $t("pages.amenities.ser_servTransp") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.tour">

                          {{ $t("pages.amenities.ser_tour") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.tiendaComestibles">

                          {{ $t("pages.amenities.ser_tiendaComestibles") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.servLimpieza">

                          {{ $t("pages.amenities.ser_servLimpieza") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.servAgua">

                          {{ $t("pages.amenities.ser_servAgua") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.servLocalCel">

                          {{ $t("pages.amenities.ser_servLocalCel") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.rampasSillasRuedas">

                          {{ $t("pages.amenities.ser_rampasSillasRuedas") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.luz">

                          {{ $t("pages.amenities.ser_luz") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.agua">

                          {{ $t("pages.amenities.ser_agua") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.gas">

                          {{ $t("pages.amenities.ser_gas") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.circuitoCerrado">

                          {{ $t("pages.amenities.ser_circuitoCerrado") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.energiaSustentable">

                          {{ $t("pages.amenities.ser_energiaSustentable") }}
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.alumbrado">

                          {{ $t("pages.amenities.ser_alumbrado") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.cableTV">

                          {{ $t("pages.amenities.ser_cableTV") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.wifi">

                          Wi-Fi
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.concierge">

                          {{ $t("pages.amenities.ser_concierge") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.mascotas">

                          {{ $t("pages.amenities.ser_mascotas") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.fumar">

                          {{ $t("pages.amenities.ser_fumar") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.sistemaDeAlarma">

                          {{ $t("pages.amenities.ser_sistemaDeAlarma") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.servTelefono">

                          {{ $t("pages.amenities.ser_servTelefono") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.camarasDeSeguridad">

                          {{ $t("pages.amenities.ser_camarasDeSeguridad") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.drenaje">

                          {{ $t("pages.amenities.ser_drenaje") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.pavimento">

                          {{ $t("pages.amenities.ser_pavimento") }} 
                      </li>
                      <li class="amenidades col-md-12" v-if="prop.developmentServices.poolRentas">

                          {{ $t("pages.amenities.ser_poolRentas") }} 
                      </li>
                    </ul>
                </div>
                <!-- Services End -->
                </div>
            </div>
            </div>
            <div class="col-md-10 offset-md-1">
            <ul
                class="nav nav-pills-a nav-pills mb-3 section-t3"
                id="pills-tab"
                role="tablist"
            >
                <li v-if="prop.video" class="nav-item">
                <a
                    class="nav-link active"
                    id="pills-video-tab"
                    data-toggle="pill"
                    href="#pills-video"
                    role="tab"
                    aria-controls="pills-video"
                    aria-selected="true"
                    >Video</a
                >
                </li>
                <!--i  v-if="prop.latitude|| prop.longitude" class="nav-item">
                <a
                    :class="['nav-link',(!prop.video)?'active':'']"
                    id="pills-map-tab"
                    data-toggle="pill"
                    href="#pills-map"
                    role="tab"
                    aria-controls="pills-map"
                    aria-selected="false"
                >Ubicación</a>
                </li-->
                
            <!-- Map -->
            <!-- <section class="row mb-5 border-top" >
              <div class="col-md-12 no-gutters mt-3">
                <div class="col-12">
                  <h4 class="h4 text-black mb-3">
                   MAPA
                  </h4>
                </div>
                <div class="col-12">

                  <GoogleMap :mapLat="prop.latitude" :mapLng="prop.longitude" />
                </div>
              </div>
            </section> -->
            </ul>
            <div class="tab-content" id="pills-tabContent">
                
                <!-- Video Start -->
                <div
                v-if="prop.video"
                class="tab-pane fade show active"
                id="pills-video"
                role="tabpanel"
                aria-labelledby="pills-video-tab"
                >
                <iframe
                    :src="prop.video"
                    width="100%"
                    height="460"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                ></iframe>
                </div>
                <!-- Video End -->
                
                <!-- Location Start
                <div
                v-if="prop.latitude|| prop.longitude"
                :class="['tab-pane fade', (!prop.video)?'show active':'']"
                id="pills-map"
                role="tabpanel"
                aria-labelledby="pills-map-tab"
                >
                    <div id="map"></div>
                </div>
                Location End -->
            </div>
            </div>
            <div class="col-md-12">
            <div class="row section-t3">
                <div class="col-sm-12">
                <div class="title-box-d">
                    <h3 class="title-d">{{$t('pages.property_detail.agent_information')}}</h3>
                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 text-center">
                    <img :src="prop.agent.image" alt="" class="img-fluid" />
                  
                </div>
                <div class="col-md-6 col-lg-4">
                <div class="property-agent">
                    <h4 class="title-agent">{{prop.agent.name}}</h4>
                    <ul class="list-unstyled">
                    <li v-if="prop.agent.phone!=0" class="d-flex justify-content-between styled-item">
                        <strong>{{$t('pages.property_detail.agent_phone')}}:</strong>
                        <span class="color-text-a">{{prop.agent.phone}}</span>
                    </li>
                    <li v-if="prop.agent.cellphone" class="d-flex justify-content-between styled-item">
                        <strong>{{$t('pages.property_detail.agent_cellphone')}}:</strong>
                        <span class="color-text-a">{{prop.agent.cellphone}}</span>
                    </li>
                    <li v-if="prop.agent.email" class="d-flex justify-content-between styled-item">
                        <strong>{{$t('pages.property_detail.agent_email')}}:</strong>
                        <span class="color-text-a">{{prop.agent.email}}</span>
                    </li>
                    <li v-if="prop.agent.skype!=0" class="d-flex justify-content-between styled-item">
                        <strong>Skype:</strong>
                        <span class="color-text-a">{{prop.agent.skype}}</span>
                    </li>
                    </ul>
                    <div class="socials-a">
                    <ul class="list-inline">
                        <li v-if="prop.agent.facebook && prop.agent.facebook!=0" class="list-inline-item">
                        <a target="_blank" :href="prop.agent.facebook">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>
                        </a>
                        </li>
                        <li v-if="prop.agent.twitter && prop.agent.twitter!=0" class="list-inline-item">
                        <a target="_blank" :href="prop.agent.twitter">
                            <i class="fab fa-twitter" aria-hidden="true"></i>
                        </a>
                        </li>
                        <li v-if="prop.agent.instagram && prop.agent.instagram!=0" class="list-inline-item">
                        <a target="_blank" :href="prop.agent.instagram">
                            <i class="fab fa-instagram" aria-hidden="true"></i>
                        </a>
                        </li>
                        <li v-if="prop.agent.pinterest && prop.agent.pinterest!=0" class="list-inline-item">
                        <a target="_blank" :href="prop.agent.pinterest">
                            <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                        </a>
                        </li>
                        <li v-if="prop.agent.googlePlus && prop.agent.googlePlus!=0" class="list-inline-item">
                        <a target="_blank" :href="prop.agent.googlePlus">
                            <i class="fab fa-google-plus-g" aria-hidden="true"></i>
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <div class="col-md-12 col-lg-4">
                <div class="property-contact">
                    <ContactForm from="property"/>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
    <!--/ Property Single End /-->
  </template>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from '@/components/layout/Preloader.vue';
import ContactForm from '@/components/layout/ContactForm.vue';
import GoogleMap from "@/components/layout/GoogleMap.vue";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
     GoogleMap,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      prop: "_getProperty",
    }),
    activeLocale(){
        return this.$i18n.locale
    },
    format(val) {
        let value=val.prop.bestprice[0]
        //Formateo de decimales
        let decimalPrice = value.lastIndexOf(".");
        //Extaracción de moneda
        let currentPriceM = value.indexOf("MXN");
        let currentPriceU = value.indexOf("USD");

        // Precio formateado value.substr(currentPrice)
        let formatPrice=value.substr(0,decimalPrice)+" ";
        if(currentPriceM!='-1'){
            formatPrice+= value.substr(currentPriceM)
        }else{
            formatPrice+= value.substr(currentPriceU)
        }
        return formatPrice.slice(1);
    },
        formatr(val) {
        let value=val.prop.prices.rent
        //Formateo de decimales
        let decimalPrice = value.lastIndexOf(".");
        //Extaracción de moneda
        let currentPriceM = value.indexOf("MXN");
        let currentPriceU = value.indexOf("USD");

        // Precio formateado value.substr(currentPrice)
        let formatPrice=value.substr(0,decimalPrice)+" ";
        if(currentPriceM!='-1'){
            formatPrice+= value.substr(currentPriceM)
        }else{
            formatPrice+= value.substr(currentPriceU)
        }
        return formatPrice.slice(1);
    }
  },
  created() {
    this.folio = this.$route.params.folio;
    if (this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    //call metadatas for property
       this.$store.dispatch("getPropertyMetadata", this.folio);
    // end call metadatas for property
    this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;

    //Send Data to Access
    if (process.env.NODE_ENV === "production") {
      this.$getLocation().then((coordinates) => {
        let accessData = {
          lat: coordinates.lat,
          lng: coordinates.lng,
          url: this.url,
          propertyId: this.folio,
        };

        this.$store.dispatch("setPropertyClick", accessData);
      });
    }
  },

  updated() {
    /*--/ Property owl owl /--*/
    $("#property-single-carousel").owlCarousel({
      loop: true,
      margin: 0,
      nav: true,
      navText: [
        '<i class="ion-ios-arrow-back" aria-hidden="true"></i>',
        '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
      },
    });
  },
};
</script>
<style >
.img-fluid{
    width: 80%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    margin: auto;
  }
.summary-list{
    padding-right: 0rem;
}
.summary-list .list span {
    text-align: end;
}
.summary-list .list span:hover {
    color:#bd1734;
}
.subt{
    margin-left: -17rem;
    font-weight: bold;
    color:#bd1734; 
}
.mb-less{
    margin-bottom:-10px;
}
pre {

    white-space:pre-line;

    height: auto;

    overflow: hidden;

    background: transparent;

    border-color: transparent;

    color: inherit;

    font-family: inherit;

    font-size: 0.9rem;

    text-align: justify;

}
#property-single-carousel .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    height: inherit;
    max-height: 80vh;
    object-fit: contain;
    object-position: center;
}
.example-image-link{
    height: 80vh;
}
.styled-item{
    flex-flow: wrap !important;
  }
</style>
