
<template>
    <div class="card-box-b card-shadow news-box">
        <div class="img-box-b">
        <img :src="item.image" :alt="item.title" class="img-b img-fluid">
        </div>
        <div class="card-overlay">
        <div class="card-header-b">
            <div class="card-title-b">
                <h3 class="title-2">
                    <a target="_blank" :href="item.url">{{item.title}}</a>
                </h3>
            </div>
            <div class="card-date">
                <span class="date-b">
                     <a target="_blank" :href="item.url">{{$t('components.news_card.link')}}</a></span>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    }
}
</script>
<style scoped>
.title-2{
    font-size: 1.2rem;
}
.date-b a{
    color: #ffffff;
}
.img-box-b{
    height: 250px;
}
.img-fluid{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
</style>