<template>
    <!--/ Property Star /-->
    <section class="section-property section-t3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="title-wrap d-flex justify-content-between">
                        <div class="title-box">
                            <h2 class="title-a">{{$t('components.best_properties.properties')}}</h2>
                        </div>
                        <!-- <div class="title-link">
                            <router-link to="/propiedades">{{$t('components.best_properties.link')}}
                                <span class="ion-ios-arrow-forward"></span>
                            </router-link>
                        </div> -->
                    </div>
                </div>
            </div>
            <div id="property-carousel" class="owl-carousel owl-theme">
                <div class="carousel-item-b" v-for="(item,i) in properties" :key="item.folio" :index="i">
                    <PropertyCard :item="item"/>
                </div>
            </div>
        </div>
    </section>
    <!--/ Property End /-->
</template>
<script>
import { mapGetters } from 'vuex'
import PropertyCard from '@/components/properties/PropertyCard.vue'
export default {
    components:{
        PropertyCard
    },
    computed:{
        ...mapGetters({
            properties:'_getHomeProperties',
        }),
    },
    mounted(){   
        setTimeout(function(){
            /*--/ Property owl /--*/
                $('#property-carousel').owlCarousel({
                    loop: true,
                    margin: 30,
                    responsive: {
                        0: {
                            items: 1,
                        },
                        769: {
                            items: 2,
                        },
                        992: {
                            items: 3,
                        }
                    }
                });
        },2000)
    }
}
</script>