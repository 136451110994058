<template>
  <!--/ Nav Star /-->
  <nav class="navbar navbar-default navbar-trans navbar-expand-lg fixed-top">
    <div class="container">
      <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarDefault"
        aria-controls="navbarDefault" aria-expanded="false" aria-label="Toggle navigation">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <img  @click="goToHome()" v-if="info" class="cr-logo" :src="info.logo" :alt="info.alt" />
      <button type="button" class="btn btn-link nav-search navbar-toggle-box-collapse d-md-none" data-toggle="collapse"
        data-target="#navbarTogglerDemo01" aria-expanded="false">
        <span class="fa fa-search" aria-hidden="true"></span>
      </button>
      <div class="navbar-collapse collapse justify-content-end" id="navbarDefault">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link :class="['nav-link',(nameRoute=='Home')?'active':'']" to="/">{{$t('layout.nav.home')}}</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{$t('layout.nav.properties')}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" to="/propiedades">{{$t('layout.nav.properties')}}</router-link>
              <router-link class="dropdown-item" to="/desarrollos-miami">{{$t('layout.nav.PropertiesMiami')}}</router-link>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{$t('layout.nav.developments')}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" to="/desarrollos">{{$t('layout.nav.developments')}}</router-link>
              <router-link class="dropdown-item" to="/desarrollos-miami">{{$t('layout.nav.DevelopmentsMiami')}}</router-link>
            </div>
          </li>
          <li class="nav-item ">
            <router-link :class="['nav-link redlink',(nameRoute=='Opportunities')?'active':'']" to="/oportunidades">{{$t('layout.nav.opportunities')}}</router-link>
          </li>
          <!-- <li class="nav-item">
            <router-link :class="['nav-link',(nameRoute=='Agents')?'active':'']" to="/agentes">{{$t('layout.nav.agents')}}</router-link>
          </li> -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              {{$t('layout.nav.about.title')}}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" to="/nosotros">{{$t('layout.nav.about.title')}}</router-link>
              <router-link class="dropdown-item" to="/aviso-de-privacidad">{{$t('layout.nav.about.privacy')}}</router-link>
              <router-link class="dropdown-item" to="/consultoria">{{$t('layout.nav.about.consultacy')}}</router-link>
              <router-link class="dropdown-item" to="/oficinas-de-costa-realty">{{$t('layout.nav.about.offices')}}</router-link>
              <a class="dropdown-item" href="https://crrivieramaya.com/blog/">Blog</a>
            </div>
          </li>
          <li class="nav-item">
            <router-link :class="['nav-link',(nameRoute=='Contact')?'active':'']" to="/contacto">{{$t('layout.nav.contact')}}</router-link>
          </li>
          <li class="nav-item">
            <router-link :class="['nav-link',(nameRoute=='SellProperty')?'active':'']" to="/VenderMiInmueble">{{$t('layout.nav.sell_property')}}</router-link>
          </li>
          <!-- <li class="nav-item ml-2">
            <a class="nav-link" href="http://crrivieramaya.com/registro-master-broker/">{{$t('layout.nav.broker_cr')}}</a>
          </li> -->
        </ul>
      </div>
      
      <div class="mx-3">
            <LocaleSwitcher/>
        </div>
      <button type="button" class="btn btn-b-n navbar-toggle-box-collapse d-none d-md-block" data-toggle="collapse"
        data-target="#navbarTogglerDemo01" aria-expanded="false">
        <span class="fa fa-search" aria-hidden="true"></span>
      </button>

    </div>
  </nav>
  <!--/ Nav End /-->
</template>
<script>
import { mapGetters } from 'vuex'
import LocaleSwitcher from '@/components/layout/LocaleSwitcher.vue';
export default {
   components:{
    LocaleSwitcher
  },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
        nameRoute:function(){
          return this.$route.name
        }
    },
    methods:{
        goToHome(){
            this.$router.push({
                name: 'Home',
            });
        }
    },
}
</script>
<style scoped>
.cr-logo{
    width: 5vw;
}
.redlink{
  color: #bd1734 !important;
}
@media only screen  and (max-width: 991px){
    .cr-logo{
        width: 20%;
    }  
}
</style>