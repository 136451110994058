<template>
    <div class="card-box-a card-shadow">
        <div class="img-box-a">
            <img :src="item.smallphoto" :alt="item.alt" :title="item.namePropertyEs+'-IMAGE'" class="img-a img-fluid">
        </div>
        <div class="card-overlay">
            <div class="card-overlay-a-content">
                <div class="card-header-a">
                    <h4 class="card-title-a">
                        <a class="cc_pointer" @click="goToProperty(item.folio,item.namePropertyEs)" >{{item.namePropertyEs}}</a>
                    </h4>
                </div>
                <div class="card-body-a">
                    <div class="price-box d-flex">
                        <span class="price-a">{{mainOperation}} | {{priceInfo}}</span>
                    </div>
                    <a @click="goToProperty(item.folio,item.namePropertyEs)" class="cc_pointer link-a">{{$t('components.property_card.link')}}
                        <span class="ion-ios-arrow-forward"></span>
                    </a>
                </div>
                <div v-if="item.m2c || item.bedrooms || item.bathrooms" class="card-footer-a">
                    <ul class="card-info d-flex justify-content-around">
                        <li v-if="item.m2c">
                            <h4 class="card-info-title">{{$t('components.property_card.area')}}</h4>
                            <span>{{item.m2c}}m
                                <sup>2</sup>
                            </span>
                        </li>
                        <li v-if="item.bedrooms">
                            <h4 class="card-info-title">{{ $t("components.property_card.bedrooms", (item.bedrooms==1)?1:2)}}</h4>
                            <span>{{item.bedrooms}}</span>
                        </li>
                        <li v-if="item.bathrooms">
                            <h4 class="card-info-title">{{$t("components.property_card.bathrooms", (item.bathrooms==1)?1:2)}}</h4>
                            <span>{{item.bathrooms}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import services from '@/store/_services'
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    },
    computed:{
        priceInfo(){
            //Formateo de decimales
            let decimalPrice = this.mainPrice.lastIndexOf(".");
            //Extaracción de moneda
            let currentPriceM = this.mainPrice.indexOf("MXN");
            let currentPriceU = this.mainPrice.indexOf("USD");
            
            // Precio formateado value.substr(currentPrice)
            let formatPrice=this.mainPrice.substr(0,decimalPrice)+" ";
            if(currentPriceM!='-1'){
                formatPrice+= this.mainPrice.substr(currentPriceM)
            }else{
                formatPrice+= this.mainPrice.substr(currentPriceU)
            }
            return formatPrice;
        }
    },
    data(){
        return{
            mainPrice:0,
            secondPrice:0,
            mainOperation:0,
            secondOperation:0,
        }
    },
    methods:{
        goToProperty(){
            let namePropFilt=services.normalize(this.item.namePropertyEs)
            let folio = this.item.folio;
            this.$router.push({
                name: 'Property',
                params: {
                    folio,
                    namePropFilt
                }
            });
        }
    },
    created(){
        /* For Base Template*/
        let prices=this.item.prices;
        let operations=this.item.operations;
        if(prices.opportunity_sold){
            this.mainPrice=prices.opportunity_sold;
            this.mainOperation=operations.opportunity_sold
            this.secondPrice=prices.sold
            this.secondOperation=operations.sold
        }else if(prices.opportunity_rent){
            this.mainPrice=prices.opportunity_rent;
            this.mainOperation=operations.opportunity_rent
            this.secondPrice=prices.rent
            this.secondOperation=operations.rent
        }else if(prices.sold){
            this.mainPrice=prices.sold;
            this.mainOperation=operations.sold
        }else if(prices.rent){
            this.mainPrice=prices.rent;
            this.mainOperation=operations.rent
        }
    }
}
</script>
<style scoped>
.img-box-a{
    height: 300px;
}
.img-fluid {    
    width: 100%;
    object-position: center;
    object-fit: cover;
}
</style>