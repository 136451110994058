<template>
  <form class="form-a contactForm" v-on:submit.prevent="sendMail" role="form">
    <!--div id="sendmessage">Your message has been sent. Thank you!</div-->
    <div id="errormessage"></div>
    <div class="row">
      <div :class="[(nameRoute=='Property'|| nameRoute=='Development')?'col-md-12':'col-md-6' ,'mb-1']">
        <div class="form-group">
          <input
            type="text"
            name="name"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="$t('components.contact_form.name_input_placeholder')"
            data-rule="minlen:4"
            :data-msg="$t('components.contact_form.name_input_message')"
            v-model="contactForm.name.value"
            :disabled="sent||sending"
          />
          <div class="validation"></div>
        </div>
      </div>
      <div :class="[(nameRoute=='Property'|| nameRoute=='Development')?'col-md-12':'col-md-6' ,'mb-1']">
        <div class="form-group">
          <input
            name="email"
            type="email"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="$t('components.contact_form.email_input_placeholder')"
            data-rule="email"
            :data-msg="$t('components.contact_form.email_input_message')"
            v-model="contactForm.email.value"
            :disabled="sent||sending"
          />
          <div class="validation"></div>
        </div>
      </div>
      <div class="col-md-12 mb-1">
        <div class="form-group">
          <input
            type="phone"
            name="phone"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="$t('components.contact_form.phone_input_placeholder')"
            data-rule="minlen:4"
            :data-msg="$t('components.contact_form.phone_input_message')"
            v-model="contactForm.phone.value"
            :disabled="sent||sending"
          />
          <div class="validation"></div>
        </div>
      </div>
      <div class="col-md-12 mb-1">
        <div class="form-group">
          <textarea
            name="message"
            class="form-control"
            cols="45"
            rows="3"
            required="required"
            data-rule="required"
            :data-msg="$t('components.contact_form.message_input_message')"
            :placeholder="$t('components.contact_form.message_input_placeholder')"
            v-model="contactForm.message.value"
            :disabled="sent||sending"
          ></textarea>
          <div class="validation"></div>
        </div>
      </div>
      
      <div class="col-md-12">
        <button v-if="!sending" :disabled="sent" type="submit" class="btn btn-a">{{$t('components.contact_form.button')}}</button>
        <div v-else>
            <!-- Loader -->
            <SectionLoader/>
        </div>
      </div>
    </div>
  </form>
</template>


<script>
import { mapState,mapGetters } from 'vuex';
import services from '@/store/_services';
import VueRecaptcha from 'vue-recaptcha';
import SectionLoader from '@/components/layout/SectionLoader.vue';

export default {
    components:{
        VueRecaptcha,
        SectionLoader
    },
    props:{
        from:{
            type:String,
            required:true
        }
    },
    computed:{
        ...mapState({
            URL: state => state.CONTACT_URL,
            PARAMS: state => state.API_PARAMS,
            SITE: state=>state.URL_SITE
        }),
        ...mapGetters({
            companyInfo:'_getInfoCompany',
            propertyInfo:"_getProperty",
            agent:'_getDataAgent',
            dev: "_getDevelopment",
        }),
        nameRoute(){
            return this.$route.name
        }
    },
    data(){
        return{
            url:'',
            contactForm:{
                name:{
                    value:'',
                    status:Boolean
                },
                phone:{
                    value:'',
                    status:Boolean
                },
                email:{
                    value:'',
                    status:Boolean
                },
                message:{
                    value:'',
                    status:Boolean
                },
            },
            sending:false,
            sent:false,
            bindTelProps:{
                id:"phoneContact",
                mode: "international",
                defaultCountry: "MX",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: true,
                placeholder: "Teléfono",
                required: false,
                enabledCountryCode: true,
                enabledFlags: true,
                preferredCountries: ["MX", "BR","VE"],
                onlyCountries: [],
                ignoredCountries: [],
                autocomplete: "off",
                name: "telephone",
                maxLen: 18,
                inputClasses: "form-control py-0",
                dropdownOptions: {
                    disabledDialCode: false
                },
                inputOptions: {
                    showDialCode: false
                }
            }
        }
    },
    mounted(){
        this.url=this.SITE+this.$route.path
    },
    methods:{
        sendMail(){
            //Activate Loader
            this.sending=true;

            //Validate empty form
            for(let x in this.contactForm){
                if(this.contactForm[x].value==''){
                    this.contactForm[x].status=false;
                    this.sending=false;
                    return false;
                }
            }

            //Validate email
            if(!this.validateEmail(this.contactForm.email.value)){
                this.$swal.fire({
                    icon: 'error',                    
                    text: 'El formato del correo electrónico ingresado no es válido.',
                    showConfirmButton: false,
                    showCloseButton: true,
                });
                this.contactForm.email.status=false;
                this.sending=false;
                return false;
            }else{
                this.contactForm.email.status=true;
            }

            //Validate with captcha
            this.mailerExecute();
            //this.$invisibleRecaptcha.execute();
        },
        validateEmail(email){
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
        },
        mailerExecute(){
            let self=this;
            let config=self.PARAMS;
            let data = `m=mailerContact&name=${ self.contactForm.name.value}&email=${ self.contactForm.email.value}&message=${ self.contactForm.message.value}&phone=${ self.contactForm.phone.value}&companyName=${ self.companyInfo.companyName}&companyEmail=ecommerce@costarealty.com.mx&companyAddress=${ self.companyInfo.address}&companyPhone=(998) 147 9292&companyLogo=${ self.companyInfo.logoClean}&special=${self.$store.state.ID_COMPANY}`;
            switch(this.from){
                case 'contact':
                    data+=`&origin=contact&urlSite=${ self.SITE}`
                    break;
                case 'property':
                    data+=`&folio=${self.propertyInfo.folio}&urlProp=${self.url}&agentEmail=${self.propertyInfo.agent.email}&bcc=ecommerce@costarealty.com.mx&origin=property`
                    break;
                case 'development':
                    data+=`&folio=${self.dev.folio}&urlProp=${self.url}&agentEmail=${self.dev.agent.email}&bcc=ecommerce@costarealty.com.mx&origin=property`
                    break;
                case 'agent':
                    data+=`&origin=contact&bcc=${self.agent.email}&urlSite=${ self.SITE}`
                    break;
            }
            
            config.body=data            
            //Send Mail
            services.sendMail(self.URL,config)
            .then((res)=>{
                if(res==200){
                    self.$swal.fire({
                        icon: 'success',                    
                        title: '¡Envio completo!',
                        text: `Gracias por contactarnos :)`,
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                    self.sending=false;
                    self.sent=true;

                    self.$watchLocation()
                    .then(coordinates=>{
                        let contactInfo={
                            fullname:self.contactForm.name.value,
                            email:self.contactForm.email.value,
                            phone:self.contactForm.phone.value,
                            message:self.contactForm.message.value,
                            latitude:coordinates.lat,
                            longitude:coordinates.lng,
                            contacttype:1,
                            soldagentid:2520
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                    .catch(()=>{
                        let contactInfo={
                            fullname:self.contactForm.name.value,
                            email:self.contactForm.email.value,
                            phone:self.contactForm.phone.value,
                            message:self.contactForm.message.value,
                            latitude:0,
                            longitude:0,
                            contacttype:1,
                            soldagentid:2520                            
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                }else{
                    self.$swal.fire({
                        icon: "error",
                        text: "No se pudo completar el envio del formulario.",
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            });
        },
        onCaptchaVerified(){
            console.log('en verificado;')
            let self=this;
            let config=self.PARAMS;
            let data = `name=${ self.contactForm.name.value}&email=${ self.contactForm.email.value}&message=${ self.contactForm.message.value}&phone=${ self.contactForm.phone.value}&companyName=${ self.companyInfo.companyName}&companyEmail=${self.companyInfo.reserveemail}&companyAddress=${ self.companyInfo.address}&companyPhone=${ self.companyInfo.phone}&companyLogo=${ self.companyInfo.logoClean}&urlSite=${ self.SITE}&origin=contact&special=${self.$store.state.ID_COMPANY}`;
            console.log("cool")
            config.body=data;
            //console.log(self.URL);
            //Send Mail
            /*services.sendMail(self.URL,config)
            .then((res)=>{
                if(res==200){
                    self.$swal.fire({
                        icon: 'success',                    
                        title: '¡Envio completo!',
                        text: `Gracias por contactarnos :)`,
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                    self.sending=false;
                    self.sent=true;

                    self.$watchLocation()
                    .then(coordinates=>{
                        let contactInfo={
                            fullname:self.contactForm.name.value,
                            email:self.contactForm.email.value,
                            phone:self.contactForm.phone.value,
                            message:self.contactForm.message.value,
                            latitude:coordinates.lat,
                            longitude:coordinates.lng,
                            contacttype:1,
                            soldagentid:2520
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                    .catch(()=>{
                        let contactInfo={
                            fullname:self.contactForm.name.value,
                            email:self.contactForm.email.value,
                            phone:self.contactForm.phone.value,
                            message:self.contactForm.message.value,
                            latitude:0,
                            longitude:0,
                            contacttype:1,
                            soldagentid:2520                            
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                }else{
                    self.$swal.fire({
                        icon: "error",
                        text: "No se pudo completar el envio del formulario.",
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            });*/
        },
        onCaptchaExpired() {
            this.$swal.fire({
                icon: "error",
                text: "No se pudo completar el envio del formulario.",
                showConfirmButton: false,
                showCloseButton: true
            });
            this.sending=false;
            this.$refs.invisibleRecaptcha.reset();
        }
    }
}
</script>