<template>
    <div class="click-closed"></div>
    <!--/ Form Search Star /-->
    <div class="box-collapse">
        <div class="title-box-d">
            <h3 class="title-d">{{$t("layout.header.search_form.title",(search.searchDevelopments)?2:1)}}</h3>
        </div>
        <span class="close-box-collapse right-boxed ion-ios-close"></span>
        <div class="box-collapse-wrap form">
            <form class="form-a" v-on:submit.prevent="searchProps">
                <div class="row">
                    <div class="col-md-12 my-2">
                        <div id="switchProp" class="form-group container">
                            <div class="row">
                                <h5>{{$t('layout.header.search_form.search_devs')}}</h5>
                                <input v-model="search.searchDevelopments" @click="changeForm" type="checkbox" id="switch" /><label for="switch">Toggle</label>
                            </div>
                        </div>
                    </div>
                    <div v-if="!search.searchDevelopments" class="col-md-6 mb-2">
                        <div class="form-group">
                        <label for="Type">{{$t('layout.header.search_form.keyword_input')}}</label>
                        <input v-model="search.keyword" type="text" class="form-control form-control-lg form-control-a">
                        </div>
                    </div>
                    <div v-if="!search.searchDevelopments" class="col-md-6 mb-2">
                        <div class="form-group">
                        <label for="Type">{{$t('layout.header.search_form.operation_input')}}</label>
                        <select class="form-control form-control-lg form-control-a" v-model="search.operation" id="Operation">
                            <option selected value="">{{$t('layout.header.search_form.option_select')}}</option>
                            <option value="1">{{$t('layout.header.search_form.option_sold')}}</option>
                            <option value="2">{{$t('layout.header.search_form.option_rent')}}</option>
                        </select>
                        </div>
                    </div>
                    <div v-if="!search.searchDevelopments" class="col-md-6 mb-2 ">
                        <div class="form-group" >
                            <label for="Type">{{$t('layout.header.search_form.property_type_input')}}</label>
                            <select class="form-control form-control-lg form-control-a" v-model="search.type" id="Type">
                                <option selected value="">{{$t('layout.header.search_form.option_select')}}</option>
                                <option v-for="(type,i) in input.types" :key="i" :index="i" :value="type.typepropertyid">{{type.typepropertyspa}}</option>
                            </select>
                        </div>
                        <!-- <div class="form-group" v-else>
                            <label for="Type">{{$t('layout.header.search_form.development_type_input')}}</label>
                            <select class="form-control form-control-lg form-control-a" v-model="search.type" id="Type">
                                <option selected value="">{{$t('layout.header.search_form.option_select')}}</option>
                                <option v-for="(type,i) in input.typesDevs" :key="i" :index="i" :value="type.type_development_id">{{type.name_type_esp}}</option>
                            </select>
                        </div> -->
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="form-group">
                            <label for="city">{{$t('layout.header.search_form.city_input')}}</label>
                            <select v-if="!search.searchDevelopments" class="form-control form-control-lg form-control-a" v-model="search.city" id="city">
                                <option selected value="">{{$t('layout.header.search_form.all_cities_option')}}</option>
                                <option v-for="(city,i) in input.cities" :key="i" :index="i" :value="city.city">{{city.city}}</option>
                            </select>
                            <select v-else class="form-control form-control-lg form-control-a" v-model="search.city" id="city">
                                <option selected value="">{{$t('layout.header.search_form.all_cities_option')}}</option>
                                <option v-for="(city,i) in input.citiesDevs" :key="i" :index="i" :value="city.city">{{city.city}}</option>
                            </select>
                        </div>
                    </div>
                    <div v-if="!search.searchDevelopments" class="col-md-6 mb-2">
                        <div class="form-group">
                        <label for="bedrooms">{{$t('layout.header.search_form.bedrooms_input')}}</label>
                        <select class="form-control form-control-lg form-control-a" v-model="search.beds" id="bedrooms">
                            <option selected value="">{{$t('layout.header.search_form.option_select')}}</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                        </select>
                        </div>
                    </div>
                    <div v-if="!search.searchDevelopments" class="col-md-6 mb-2">
                        <div class="form-group">
                        <label for="bathrooms">{{$t('layout.header.search_form.bathrooms_input')}}</label>
                        <select class="form-control form-control-lg form-control-a" v-model="search.baths" id="bathrooms">
                            <option selected value="">{{$t('layout.header.search_form.option_select')}}</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="form-group">
                        <label for="price">{{$t('layout.header.search_form.min_price_input')}}</label>
                        <select class="form-control form-control-lg form-control-a" v-model="search.minPrice" id="priceMin">
                            <option selected value="">{{$t('layout.header.search_form.limit_price_option')}}</option>
                            <option>$50,000</option>
                            <option>$100,000</option>
                            <option>$150,000</option>
                            <option>$200,000</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="form-group">
                        <label for="price">{{$t('layout.header.search_form.max_price_input')}}</label>
                        <select class="form-control form-control-lg form-control-a" v-model="search.maxPrice" id="priceMax">
                            <option selected value="">{{$t('layout.header.search_form.limit_price_option')}}</option>
                            <option>$50,000</option>
                            <option>$100,000</option>
                            <option>$150,000</option>
                            <option>$200,000</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <button type="submit" class="btn btn-b">{{$t('layout.header.search_form.button')}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!--/ Form Search End /-->
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data(){
        return{
            search:{
                operation:"",
                type:"",
                typeDevs:"",
                city:"",
                keyword:"",
                minPrice:"",
                maxPrice:"",
                urbanization:"",
                beds:"",
                baths:"",
                parking:"",
                searchDevelopments:false
            },
            searching:false,
            moneyMin:{
                thousand:',',
                prefix:'',
                precision:0,
                masked:false
            },
            moneyMax:{
                thousand:',',
                prefix:'',
                precision:0,
                masked:false
            },
            advanced:false
        }
    },
    computed: {
        ...mapGetters({
            input: "_getInputs"
        }),
        
    },
    methods:{
        searchProps(){
            this.searching=true;
            let searchForm = this.search;
            this.$store.dispatch("getResults",searchForm);
            
            if(!this.search.searchDevelopments){
                if(this.$route.name!='Results')
                    this.$router.push({name: 'Results'});
            }else{
                if(this.$route.name!='ResultsDevs')
                    this.$router.push({name: 'ResultsDevs'});
            }
            
            this.searching=false;
        },
        advancedChange(){
            let temp = this.advanced;
            this.advanced=!temp;
        },
        changeForm(){
            this.search.operation="";
            this.search.type="";
            this.search.typeDevs="";
            this.search.city="";
            this.search.keyword="";
            this.search.minPrice="";
            this.search.maxPrice="";
            this.search.urbanization="";
            this.search.beds="";
            this.search.baths="";
            this.search.parking="";
        }
    },
    mounted(){
        /*--/ Navbar Collapse /--*/
        $('.navbar-toggle-box-collapse').on('click', function () {
            $('body').removeClass('box-collapse-closed').addClass('box-collapse-open');
        });
        $('.close-box-collapse, .click-closed').on('click', function () {
            $('body').removeClass('box-collapse-open').addClass('box-collapse-closed');
            $('.menu-list ul').slideUp(700);
        });
        
        this.$store.dispatch("getInputs");
    }
}
</script>
<style scoped>
#switchProp  h5{
    margin-right: calc(100% - 280px);
}
input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

#switchProp label {
	cursor: pointer;
	text-indent: -9999px;
	width: 50px;
	height: 25px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative;
}

#switchProp label:after {
	content: '';
	position: absolute;
	top: 2.8px;
	left: 5px;
	width: 19px;
	height: 19px;
	background: #fff;
	border-radius: 20px;
	transition: 0.70s;
}

#switchProp input:checked + label {
	background: #bada55;
}

#switchProp input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

#switchProp label:active:after {
	width: 40px;
}
</style>