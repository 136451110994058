<template>
    <section class="intro-single">
        <div class="container-fluid">
            <SearchFormResults />
        </div>
        <NotFound v-if="error == 204" code="204"
        :messageError="$t('layout.not_found.204_message')" />
        <div v-else class="container-fluid tab-content " id="myTabContent">
            <!-- map view -->
            <div class="row tab-pane fade show active" id="map" role="tabpanel" aria-labelledby="map-tab">
                <div class="col-sm-12 col-md-12 col-lg-6">
                    <!-- {{ this.markers}} xxx <br> <br>{{this.markers.slice(0, 1)}} -->
                    <GMapMap :center="{lat: centerLat, lng: centerLng}" map-type-id="terrain"  :zoom="13" style="width: 100%; height: 60vh;" >
                        <GMapMarker :key="index" :icon="require('/public/img/pin-house.png')"   
                        :clickable="true"    
                        v-for="(m, index) in markers" :position="m.position" @click="center=m.position,evento_clickeado(m.id)"  
                        >
                            </GMapMarker>
                    </GMapMap>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 props-view">
                    <section class="property-grid grid pt-2">
                        <!-- <NotFound v-if="error == 204" code="204"
                            :messageError="$t('layout.not_found.204_message')" /> -->
                            
                        <div class="container">
                            <!-- <SectionLoader v-if="!results" />  -->
                            <div v-if="selected">
                                <button class="btn btn-closedetail" @click="hidedetail()" >Cerrar</button>
                                <Detailonmap :item="test"/> 
                            </div>
                            <template v-else>
                                <div v-if="!fromDevs" class="row">
                                    <div class="col-md-6" v-for="(item, i) in results" :key="item.folio"
                                        :name="item.folio" :index="i">
                                        <PropertyCard :item="item" />
                                    </div>
                                </div>
                                <div v-else class="row">
                                    <div class="col-md-6" v-for="(item, i) in results" :key="item.folio"
                                        :name="item.folio" :index="i">
                                        <DevelopmentCard :item="item" />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </section>
                </div>
            </div>
            <!-- grid view -->
            <div class="row tab-pane fade " id="grid" role="tabpanel" aria-labelledby="grid-tab">
                <div class="col-sm-12 col-md-12">
                    <section class="property-grid grid pt-2">
                        <NotFound v-if="error == 204" code="204"
                            :messageError="$t('layout.not_found.204_message')" />
                        <div v-else class="container-fluid">
                            <SectionLoader v-if="!results" />
                            <template v-else>
                                <div v-if="!fromDevs" class="row">
                                    <div class="col-md-3" v-for="(item, i) in results" :key="item.folio"
                                        :name="item.folio" :index="i">
                                        <PropertyCard :item="item" />
                                    </div>
                                </div>
                                <div v-else class="row">
                                    <div class="col-md-3" v-for="(item, i) in results" :key="item.folio"
                                        :name="item.folio" :index="i">
                                        <DevelopmentCard :item="item" />
                                    </div>
                                </div>
                            </template>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Detailonmap from '@/components/properties/Detailonmap.vue';
import SearchFormResults from '@/components/layout/SearchFormResults.vue';
import { mapGetters } from "vuex";
import SectionLoader from '@/components/layout/SectionLoader.vue';
import PropertyCard from '@/components/properties/PropertyCard.vue';
import DevelopmentCard from '@/components/developments/DevelopmentCard.vue';
import NotFound from '@/components/layout/NotFound.vue';
import Gmapview from '@/components/layout/Gmapview.vue';
export default {
    components: {
        Detailonmap,
        SearchFormResults,
        PropertyCard,
        SectionLoader,
        NotFound,
        DevelopmentCard,
        Gmapview,
        
    },
    data() {
        return {
            prevRoute: null,  
            developments: false,
            selected: false,
            info: {
                agent: 0,
                folio: 0,
                propertyNameEs: "",
            },
            //llamada al objeto que trae el resultdetial
            test:{

            }
        }
    },
    computed: {
        ...mapGetters({
            results: '_getResults',
            error: "_getError",
            search: "_getSearch",
            markers: "_getMarkers",
            centerLat: "_getCenterLat",
            centerLng: "_getCenterLng"
        }),
        fromDevs() {
            if (this.$route.name == 'ResultsDevs') {
                return true;
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(self => {
            self.prevRoute = from
            if (!self.prevRoute.name) {
                self.$router.push({ name: 'Home' });
            }
        })
    },
    created() {
            // var resu = this.results;
            // console.log('resultado con var resu despues de this', resu)
            this.searching = true;
            let searchForm = this.search; 
            // console.log('1search',this.search)
            this.$store.dispatch("getResults", searchForm)
            .then(value => {
            })
            this.searching = false; 
            //-- muestran despues de un rato

    },
    methods: {
        evento_clickeado(id){
            // console.log('el id es: ')
            // console.log(id)
            this.info.folio = id;
            this.$store.dispatch("getPropertydetail", this.info).then((result) => {
                // console.log('respuesta')
                // this.info = result;
                this.test = result;
                // console.log('respuesta',this.test)
                // console.log('ress',result)
                this.selected = true;
            })
        
        },
        hidedetail(){
            this.selected = false;
        }
    }

}
</script>
<style scoped>
.tab-content>.active {
    display: flex;
}

.props-view {
    overflow-y: scroll;
    max-height: calc(100vh - 180px);
}
.btn-closedetail{
    position: absolute;
    z-index: 999;
    background-color: #bd1734d6;
    border: none !important;
    border-radius: .5rem;
    color: #fff;
    right: 20px;
    margin-top: .5rem;
    padding:.10rem .75rem;
}
</style>