import { createStore } from 'vuex'

/*
 *SINTAX
 ** Constant/Global State : UPPERCASE/SNAKE
 ** Variables State : camelCase
 ** Functions (Actions/Mutations/Getters): CamelCase
 */

export default createStore({
    state: {
        /*****************************************************
         * General Info
         *****************************************************/
        API_URL: "https://api.immocrm360.com.mx",
        CONTACT_URL: "https://api.immocrm360.com.mx",
        //CONTACT_URL: "https://www.immosystem.com.mx/api/mailWeb/mailerContactExample.php",
        COSTA_URL: "https://api.immocrm360.com.mx",
        COSTA_URLTEST: "https://api.immocrm360.com.mx",
        API_PARAMS: {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: {}
        },
        MAIL_PARAMS: {
            method: 'POST',
            body: {}
        },
        NEWS_VISITS: 0,
        NEWS_CONTACTS: 0,
        USER_IP: null,
        CAPTCHA_KEY: "6Lf_1ZMUAAAAAC1iY_FzMJpgod5bIVl_2YYHLT9g",
        ID_COMPANY: "126",
        URL_SITE: "https://www.costarealty.com.mx",
        ACTUAL_PAGE: "",
        INFO_COMPANY: null,
        ERROR_CODE: 0,
        MATADATA: {
            company: 0,
            detail: 0
        },
        PROPS_PER_PAGE: 9,
        AGENTS_PER_PAGE: 8,

        /*****************************************************
         * Properties in Home x places
         *****************************************************/
                homePropertiesPlaces: [{
                    id: 1,
                    name: 'HOLBOX',
                    alias: 'Propiedades Isla Holbox',
                    image: '/img/propertieszonehome/HOLBOX_ISLA.jpg'
                },
                {
                    id: 2,
                    name: 'ISLA MUJERES',
                    alias: 'Propiedades Isla Mujeres',
                    image: '/img/propertieszonehome/ISLA_MUJERES.jpg'
                }
            ],


        /*****************************************************
         * Destinations
         *****************************************************/
        homePlaces: [{
                id: 1,
                name: 'CANCÚN',
                image: '/img/places/cancun.webp'
            },
            {
                id: 2,
                name: 'PLAYA DEL CARMEN',
                image: '/img/places/playa.webp'
            },
            {
                id: 3,
                name: 'ISLA MUJERES',
                image: '/img/places/isla-mujeres.webp'
            }
        ],

        /*****************************************************
         * Master Broker
         *****************************************************/
        masterBroker: [{
                id: 1,
                name: 'JACAMAR RESIDENCIAL',
                image: '/img/master-broker/jacamar.jpg',
                url: 'https://www.costarealty.com.mx/desarrollo/482/jacamar-residencial'
            }
        ],
        /*****************************************************
         * Blog
         *****************************************************/
        homeNews: [{
                id: 1,
                title: 'BENEFICIOS DE UNIRTE AL EQUIPO DE CR CANCÚN & RIVIERA MAYA',
                image: '/img/blog/blog-1.png',
                url: 'http://crrivieramaya.com/blog/index.php/2020/02/20/beneficios-de-unirte-al-equipo-de-cr-cancun-riviera-maya/'
            },
            {
                id: 2,
                title: 'EL RETORNO DE INVERSIÓN (ROI) EN EL CARIBE MEXICANO',
                image: '/img/blog/blog-2.jpg',
                url: 'http://crrivieramaya.com/blog/index.php/2020/02/06/el-retorno-de-inversion-roi-en-el-caribe-mexicano/'
            },
            {
                id: 3,
                title: 'SEGMENTO DE VIVIENDA TRADICIONAL SE COMERCIALIZÓ EN SECTOR MASCULINO',
                image: '/img/blog/blog-3.jpg',
                url: 'http://crrivieramaya.com/blog/index.php/2019/02/14/segmento-de-vivienda-tradicional-se-comercializo-en-sector-masculino/'
            },
            {
                id: 4,
                title: 'LA PSICOLOGÍA EN LAS VENTAS INMOBILIARIAS',
                image: '/img/blog/blog-4.png',
                url: 'http://crrivieramaya.com/blog/index.php/2019/02/14/la-psicologia-en-las-ventas-inmobiliarias/'
            },
            {
                id: 5,
                title: 'EL SECTOR INDUSTRIAL ES EL FUTURO DE LOS BIENES RAÍCES EN MÉXICO Y LA RIVIERA MAYA.',
                image: '/img/blog/blog-5.jpg',
                url: 'http://crrivieramaya.com/blog/index.php/2019/02/05/el-sector-industrial-es-el-futuro-de-los-bienes-raices-en-mexico-y-la-riviera-maya/'
            },
        ],
        /*****************************************************
         * Videos
         *****************************************************/
        homeVideos: [{
                id: 1,
                url: 'https://www.youtube.com/embed/Q-CtbbafuRE'
            },
            {
                id: 2,
                url: 'https://www.youtube.com/embed/JVdFAlgU6Hs'
            },
            {
                id: 3,
                url: 'https://www.youtube.com/embed/x-gm-d-NxF8'
            }
        ],

        /*****************************************************
         * Developments Info
         *****************************************************/
        bestDevelopments: [{
                folio: 360,
                name: 'MORÉ TULUM CONDOS',
                image: '/img/best-developments/360-morefb-S.png',
                city: 'TULUM'
            },
            {   folio: 514,
                name: "SOLEII",
                image: '/img/best-developments/soleii-dev.jpg',
                city: 'PLAYA DEL CARMEN'
                },
                {   folio: 1027,
                    name: "KALISTA HOLBOX CONDOS",
                    image: '/img/best-developments/kalista-dev.jpg',
                    city: 'HOLBOX'
                },

        ],
        developmentsList: null,
        developmentsList2: null,
        developmentsMiamiList: null,
        developmentDetail: 0,
        activeUnit: 0,
        /*****************************************************
         * Properties Info
         *****************************************************/
        homeCarousel: [],
        homeList: null,
        propertiesList: null,
        propertiesByAgent: null,
        propertiesByOpportunities: null,
        propertiesDetail: 0, //Cache properties
        propertyDetail: 0,
        propertiesSearch: null,
        propertyAccess: null,

        /*****************************************************
         * Franchise Info
         *****************************************************/
        teamList: null,
        officesList: null,
        teamByOffices: [{
                office: 60,
                nameOffice: 'Division Listados',
                agents: null
            },
            {
                office: 70,
                nameOffice: 'Master Broker',
                agents: null
            }
        ],
        agentActive: null,
        /*****************************************************
         * Search Info
         *****************************************************/
        inputs: {
            cities: null,
            types: null,
            typesDevs: null,
            citiesDevs: null,
        },
        search:{
            operation:"",
            type:"",
            typeDevs:"",
            city:"",
            keyword:"",
            minPrice:"",
            maxPrice:"",
            urbanization:"",
            beds:"",
            baths:"",
            parking:"",
            searchDevelopments:false
        },
        markers:[],
        centerLat: "",
        centerLng: "",
        /*****************************************************
         * Selects Info
         *****************************************************/
        cityInputs: null,
        stateInputs: null,
        suburbInputs: null,
    },
    mutations: {
        /*****************************************************
         * General Mutations
         *****************************************************/
        setNewVisit(state) {
            state.NEWS_VISITS++;
        },
        setNewContact(state) {
            state.NEWS_CONTACTS++;
        },

        setInfoCompany(state, info) {
            // if (info.logoClean != 0) {
            //     let apiLogo = info.logoClean;
            //     info.logoClean = "https://agent.immosystem.com.mx/" + apiLogo;
            // } else {
            //     let apiLogo = info.logo;
            //     info.logo = "https://agent.immosystem.com.mx/" + apiLogo;
            // }
            state.INFO_COMPANY = info;
        },

        setCompanyMetadata(state, metadata) {
            state.MATADATA.company = metadata;
        },
        setPropertyMetadata(state, metadata) {
            state.MATADATA.company = metadata;
        },
        setDevMetadata(state, metadata) {
            state.MATADATA.company = metadata;
        },
        setUserIp(state, userIp) {
            state.USER_IP = userIp.ip;
        },

        setError(state, code) {
            state.ERROR_CODE = code;
        },
        /*  COMMENTED FOR ERROR WAIT FOR FUTURE IMPLEMENT

        configureImages(state,origin){
          let imageTemp;
          let watermarkImage=state.INFO_COMPANY.logoClean;

          // watermark from remote source
          const options = {
            init(img) {
              img.AccessControlAllowOrigin='https://angelpinton.com';
            }
          };

          switch(origin){
            case'homeSlide':
              for(let x in state.homeCarousel){
                imageTemp=state.homeCarousel[x].largefile;

                watermark([imageTemp, watermarkImage], options)
                  .image(watermark.image.lowerRight(0.5))
                  .then((img)=>state.homeCarousel[x].largefile=img);
              }

            break;
          }
        },
        */

        /*****************************************************
         * Home Mutations
         *****************************************************/
        setHomeSlide(state, slides) {
            state.homeCarousel = slides;
        },
        setHomeProperties(state, properties) {
            state.homeList = properties;
        },

        /*****************************************************
         * Properties Mutations
         *****************************************************/
        setProperties(state, properties) {
            state.propertiesList = properties;
        },
        setPropertiesByAgent(state, properties) {
            state.propertiesByAgent = properties;
        },
        setPropertiesOpportunities(state, properties) {
            state.propertiesByOpportunities = properties;
        },
        setProperty(state, property) {
            state.propertyDetail = property;
        },
        resetProperty(state, property) {
            state.propertyDetail = property;
        },

        /*****************************************************
         * Developments Mutations
         *****************************************************/
        setDevelopments(state, developments) {
            state.developmentsList = developments;
        },
        setDevelopments2(state, developments) {
            state.developmentsList2 = developments;
        },
        setDevelopmentsMiamiList(state, developments) {
            state.developmentsMiamiList = developments;
        },
        setDevelopment(state, development) {
            state.developmentDetail = development;
        },
        resetDevelopment(state, development) {
            state.developmentDetail = development;
        },
        setUnit(state, unit) {
            state.activeUnit = unit;
          },
        /*****************************************************
         * Franchise Mutations
         *****************************************************/
        setTeam(state, team) {
            state.teamList = team;
        },
        setOffices(state, offices) {
            state.officesList = offices;
        },
        setTeamByOffice(state, data) {
            let index = data.index;
            state.teamByOffices[index].agents = data.team;
        },
        setAgent(state, agentInfo) {
            state.agentActive = 0;
            state.agentActive = agentInfo[0];
        },
        /*****************************************************
         * Selects Info
         *****************************************************/
         setCitySelect(state, citys) {
            state.cityInputs = citys;
        },
        setStateSelect(state, states) {
            state.stateInputs = states;
        },
        setSuburbSelect(state, suburbs) {
            state.suburbInputs = suburbs;
        },
        /*****************************************************
         * Search Mutations
         *****************************************************/
        setInputs(state, inputs) {
            state.inputs = inputs;
            //state.inputs.types=inputs.types;
        },
        resetPropertiesSearch(state) {
            state.propertiesSearch = null;
        },
        setPropertiesSearch(state, searchForm) {
            state.propertiesSearch = searchForm;
        },
        setSearch(state, search){
            state.search = search;
        },
        setMarkers(state, markers){
            state.markers = markers;
        },
        setCenterLat(state, centerLat){
            state.centerLat = centerLat;
        },
        setCenterLng(state, centerLng){
            state.centerLng = centerLng;
        }
    },
    actions: {
        /*****************************************************
         * General Actions
         *****************************************************/
        setNewVisit({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=countVisits&folio=${state.ID_COMPANY}&unic=1`;
            config.body = data;
            if (process.env.NODE_ENV === 'production') {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setNewVisit');
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        setConcurrentVisit({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=countVisits&folio=${state.ID_COMPANY}&concurrent=1`;
            config.body = data;
            if (process.env.NODE_ENV === 'production') {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setNewVisit');
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        setPropertyClick({ commit, state }, propertyData) {
            let config = state.API_PARAMS;
            let data = `m=viewsProperty&origincode=7952&folio=${state.ID_COMPANY}`;
            for (let param in propertyData) {
                data += `&${param}=${propertyData[param]}`;
            }

            config.body = data;
            if (process.env.NODE_ENV === 'production') {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setNewVisit');
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        setNewContact({ commit, state }, contactInfo) {
            let config = state.API_PARAMS;
            let data = `m=contact&folio=${state.ID_COMPANY}`;
            for (let param in contactInfo) {
              data += `&${param}=${contactInfo[param]}`;
            }
            config.body = data;
            if (process.env.NODE_ENV === "production") {
              fetch(state.API_URL, config)
                .then((res) => res.json())
                .then((res) => {
                  if (res.status == 200) {
                    commit("setNewContact");
                    commit("setError", res.status);
                  } else {
                    commit("setError", res.status);
                  }
                });
            }
          },
        getInfoCompany({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=footer&folio=${state.ID_COMPANY}`;
            config.body = data;
            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setInfoCompany', res.data[0]);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })
            fetch('https://api.ipify.org?format=json')
                .then(res => res.json())
                .then(res => {
                    commit('setUserIp', res);
                })
        },
        getCompanyMetadata({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=metaC&folio=${state.ID_COMPANY}`;
            config.body = data;
            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setCompanyMetadata', res.data);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })
            fetch('https://api.ipify.org?format=json')
                .then(res => res.json())
                .then(res => {
                    commit('setUserIp', res);
                })
        },
        getDevMetadata({ commit, state },folio) {
            let config = state.API_PARAMS;
            let data = `m=metaD&folio=${folio}`;
            config.body = data;
            fetch(state.API_URL, config)
                .then((res) => res.json())
                .then((res) => {
                    if (res.status == 200) {
                        commit("setDevMetadata", res.data);
                        commit("setError", res.status);
                    } else {
                        commit("setError", res.status);
                    }
                });
            fetch("https://api.ipify.org?format=json")
                .then((res) => res.json())
                .then((res) => {
                    commit("setUserIp", res);
                });
        },

        /*****************************************************
         * Home Actions
         *****************************************************/
        getHomeSlide({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=slider&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (state.homeCarousel.length == 0) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setHomeSlide', res.data);
                                //commit('configureImages','homeSlide');  COMMENTED FOR ERROR WAIT FOR FUTURE IMPLEMENTS
                                commit('setError', res.status);
                            } else {
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

        },
        getHomeProperties({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=properties&limit=6&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.homeList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setHomeProperties', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },

        /*****************************************************
         * Properties Actions
         *****************************************************/
        getProperties({ commit, state }, codeShareProperties) {
            let config = state.API_PARAMS;
            let data
            if (codeShareProperties != 0) {
                data = `m=properties&shareProps=${codeShareProperties}`;
            } else {
                data = `m=properties&folio=${state.ID_COMPANY}`;
            }

            config.body = data;
            if (!state.propertiesList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setProperties', res.data);
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

        },
        getPropertiesByAgent({ commit, state }, agent) {
            let config = state.API_PARAMS;
            let data = `m=findProperty&folio=${state.ID_COMPANY}&user=${agent}`;

            config.body = data;

            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setPropertiesByAgent', res.data);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })


        },
        getPropertiesOpportunities({ commit, state }, agent) {
            let config = state.API_PARAMS;
            let data = `m=findProperty&folio=${state.ID_COMPANY}&featured=${agent}`;

            config.body = data;

            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setPropertiesOpportunities', res.data);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })


        },
        getProperty({ commit, state }, info) {
            commit('resetProperty', 0);
            let config = state.API_PARAMS;
            let data;
            if (info.agent != 0) {
                data = `m=property&folio=${info.folio}&user=${info.agent}`;
            } else {
                data = `m=property&folio=${info.folio}`;
            }
            config.body = data;
            //if(!state.propertyDetail){
            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setProperty', res.data);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })
                //}
        },
        getPropertydetail({ commit, state }, info) {
            return new Promise ((resolve,reject) => {


                commit('resetProperty', 0);
                let config = state.API_PARAMS;
                let data;
                if (info.agent != 0) {
                    data = `m=property&folio=${info.folio}&user=${info.agent}`;
                } else {
                    data = `m=property&folio=${info.folio}`;
                }
                config.body = data;
                //if(!state.propertyDetail){
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            resolve(res.data)
                            // console.log('res from js', res.data.propertyNameEs)
                        } else {
                            resolve(res.data)
                        }
                    })


            })

                //}
        },
        /*****************************************************
         * Developments Actions
         *****************************************************/
        getDevelopments({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=developments&notStates=64&folio=${state.ID_COMPANY}`;

            config.body = data;
            if (!state.developmentsList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setDevelopments', res.data);
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

        },
        getDevelopment({ commit, state }, info) {
            commit('resetDevelopment', 0);
            let config = state.API_PARAMS;
            let data = `m=development&folio=${info.folio}`;
            config.body = data;
            //if(!state.propertyDetail){
            fetch(state.API_URL, config)
                .then(res => res.json())
                .then(res => {
                    if (res.status == 200) {
                        commit('setDevelopment', res.data);
                        commit('setError', res.status);
                    } else {
                        commit('setError', res.status);
                    }
                })
                //}
        },
        getDevelopments2({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=developments&limit=9&rand=true&folio=${state.ID_COMPANY}`;

            config.body = data;
            if (!state.developmentsList2) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            commit('setDevelopments2', res.data);
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

        },
        // getDevelopments({ commit, state }, searchForm) {
        //     return new Promise((resolve, reject) => {
        //         commit('setError', 0);
        //         commit('resetPropertiesSearch');
        //         let config = state.API_PARAMS;
        //         let data = 'm=findDevelopment&notStates=64';
 
    
        //         data += `&folio=${state.ID_COMPANY}`;
    
        //         config.body = data;
        //         if (!state.developmentsMiamiList) {
        //             fetch(state.API_URL, config)
        //                 .then(res => res.json())
        //                 .then(res => {
        //                     if (res.status == 200) {
        //                         if (res.data.length != 0) {
        //                             commit('setDevelopments', res.data);
        //                             commit('setError', res.status);
        //                             resolve(res.data);
        //                         } else {
        //                             commit('setDevelopments', "notFind");
        //                             commit('setError', 204);
        //                             //resolve(204);
        //                         }
        //                     } else {
        //                         commit('setError', res.status);
        //                         //resolve(204);
        //                     }
        //                 })
        //             }
        //         })

        //},
        getDevelopmentsMiamiList({ commit, state }, searchForm) {
            return new Promise((resolve, reject) => {
                commit('setError', 0);
                commit('resetPropertiesSearch');
                let config = state.API_PARAMS;
                let data = 'm=findDevelopment&state=64';
 
    
                data += `&folio=${state.ID_COMPANY}`;
    
                config.body = data;
                if (!state.developmentsMiamiList) {
                    fetch(state.API_URL, config)
                        .then(res => res.json())
                        .then(res => {
                            if (res.status == 200) {
                                if (res.data.length != 0) {
                                    commit('setDevelopmentsMiamiList', res.data);
                                    commit('setError', res.status);
                                    resolve(res.data);
                                } else {
                                    commit('setDevelopmentsMiamiList', "notFind");
                                    commit('setError', 204);
                                    //resolve(204);
                                }
                            } else {
                                commit('setError', res.status);
                                //resolve(204);
                            }
                        })
                    }
                })

        },
    
        setActiveUnit({ commit }, unit) {
            commit("setUnit", unit);
          },
        /*****************************************************
         * Franchise Actions
         *****************************************************/
        getTeam({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=getAgents&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.teamList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setTeam', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        getOffices({ commit, state }) {
            let config = state.API_PARAMS;
            let data = `m=offices&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.officesList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setOffices', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        getCitySelect({ commit, state }) {
            let config = state.API_PARAMS;
            // let data = `m=municipalitiesCompany&folio=${state.ID_COMPANY}`;
            //let data = { cities: [] };
            let citiesUrl = `m=cities&folio=${state.ID_COMPANY}`;
            
            config.body = citiesUrl;
            if (!state.cityInputs) {
                fetch(state.API_URL, config)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit("setCitySelect", res.data);
                                commit("setError", res.status);
                            } else {
                                commit("setError", 204);
                            }
                        } else {
                            commit("setError", res.status);
                        }
                    });
            }
        },
        getCitySelectDepenment({ commit, state },idstate) {
            console.log('se activa');
            console.log(idstate);
            let config = state.API_PARAMS;
            // let data = `m=municipalitiesCompany&folio=${state.ID_COMPANY}`;
            //let data = { cities: [] };
            let citiesUrl = `m=cities&state=${idstate}`;
            
            config.body = citiesUrl;
            //if (!state.cityInputs) {
                fetch(state.API_URL, config)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit("setCitySelect", res.data);
                                commit("setError", res.status);
                            } else {
                                commit("setError", 204);
                            }
                        } else {
                            commit("setError", res.status);
                        }
                    });
            //}
        },
        getStateSelect({ commit, state }) {
            let config = state.API_PARAMS;
            let statesUrl = `m=states&folio=${state.ID_COMPANY}`;
            
            config.body = statesUrl;
            if (!state.stateInputs) {
                fetch(state.API_URL, config)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit("setStateSelect", res.data);
                                commit("setError", res.status);
                            } else {
                                commit("setError", 204);
                            }
                        } else {
                            commit("setError", res.status);
                        }
                    });
            }
        },
        getSuburbSelect({ commit, state }, city) {
            let config = state.API_PARAMS;
            let suburbUrl = `m=suburb&folio=${state.ID_COMPANY}&depenment=${city}`;
            
            config.body = suburbUrl;
            if (!state.suburbInputs) {
                fetch(state.API_URL, config)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit("setSuburbSelect", res.data);
                                commit("setError", res.status);
                            } else {
                                commit("setError", 204);
                            }
                        } else {
                            commit("setError", res.status);
                        }
                    });
            }
        },
        getSuburbSelectDepenment({ commit, state }, city) {
            let config = state.API_PARAMS;
            let suburbUrl = `m=suburb&depenment=${city}`;
            
            config.body = suburbUrl;
            //if (!state.suburbInputs) {
                fetch(state.API_URL, config)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit("setSuburbSelect", res.data);
                                commit("setError", res.status);
                            } else {
                                commit("setError", 204);
                            }
                        } else {
                            commit("setError", res.status);
                        }
                    });
            //}
        },
        //subir file
        onUpload(commit, file, state) {
            let config = state.API_PARAMS;
            let data = `m=contact&folio=${state.ID_COMPANY}`;
            for (let param in contactInfo) {
                data += `&${param}=${contactInfo[param]}`;
            }
            config.body = data;
            if (process.env.NODE_ENV === "production") {
                fetch(state.API_URL, config)
            .then(res => {
              console.log(res.data)
              commit('importFile', res.data)
            })
            .catch(error => {
              console.log(error.res.data)
            })
        }
        },
        getTeamByOffice({ commit, state }) {
            let arrayTeam = state.teamByOffices
            var agentsUpdated = {}
            for (let i in arrayTeam) {
                let config = state.API_PARAMS;
                let data = `m=getAgents&folio=${state.ID_COMPANY}&offices=${arrayTeam[i].office}`;
                config.body = data;
                if (!arrayTeam[i].agents) {
                    fetch(state.API_URL, config)
                        .then(res => res.json())
                        .then(res => {
                            if (res.status == 200) {
                                if (res.data.length != 0) {
                                    for (let item in res.data) {
                                        res.data[item] = Object.defineProperties(res.data[item], { 'office': { value: arrayTeam[i].office }, 'officeName': { value: arrayTeam[i].nameOffice } })
                                    }
                                    let dat = {
                                        team: res.data,
                                        index: i
                                    }
                                    commit('setTeamByOffice', dat);
                                    commit('setError', res.status);
                                } else {
                                    commit('setError', 204);
                                }
                            } else {
                                commit('setError', res.status);
                            }
                        })
                }
            }
        },
        getAgentInfo({ commit, state }, agentId) {
            let config = state.API_PARAMS;
            let data = `m=getAgents&user=${agentId}`;
            config.body = data;
            if (!state.officesList) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setAgent', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        /*****************************************************
         * Search Actions
         *****************************************************/
        getInputs({ commit, state }) {
            let inputs = { cities: [], types: [], typesDevs: [] };
            let config = state.API_PARAMS;
            let citiesUrl = `m=cities&folio=${state.ID_COMPANY}`;
            let typesUrl = `m=typeProperties&folio=${state.ID_COMPANY}`;
            let typesDevsUrl = `m=typeDevelopments&folio=${state.ID_COMPANY}`;
            let citiesDevsUrl = `m=citiesDevelopments&folio=${state.ID_COMPANY}`;

            config.body = citiesUrl;
            if (!state.inputs.cities) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            inputs.cities = res.data;
                            commit('setInputs', inputs);
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }

            config.body = typesUrl;
            if (!state.inputs.types) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            inputs.types = res.data;
                            commit('setInputs', inputs);
                        }
                    })
            }

            config.body = citiesDevsUrl;
            if (!state.inputs.citiesDevs) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            inputs.citiesDevs = res.data;
                            commit('setInputs', inputs);
                            commit('setError', res.status);
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
            config.body = typesDevsUrl;
            if (!state.inputs.typesDevs) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            inputs.typesDevs = res.data;
                            commit('setInputs', inputs);
                        }
                    })
            }
            //commit('setInputs', inputs);
        },
        set_search({commit, state}, search){
            
            commit('setSearch',search)
            // console.log('var',search)

        },

        getResults({ commit, state }, searchForm) {
            return new Promise((resolve, reject) => {



                commit('setError', 0);
                commit('resetPropertiesSearch');
                let config = state.API_PARAMS;
                let data;
                if (searchForm.searchDevelopments) {
                    data = 'm=findDevelopment';
                } else {
                    data = 'm=findProperty';
                }
    
                if (searchForm.keyword != '') {
                    data += `&kwEsp=${searchForm.keyword}`;
                }
                if (searchForm.type && searchForm.type != '') {
                    data += `&type=${searchForm.type}`;
                }
                if (searchForm.operation && searchForm.operation != '') {
                    data += `&operation=${searchForm.operation}`;
                }
                if (searchForm.city && searchForm.city != '') {
                    if (searchForm.searchDevelopments) {
                        data += `&citiesDevelopments=${searchForm.city}`;
                    } else {
                        data += `&city=${searchForm.city}`;
                    }
                }
    
                if (searchForm.minPrice != '') {
                    data += `&priceFrom=${searchForm.minPrice}`;
                }
                if (searchForm.maxPrice != '') {
                    data += `&priceTo=${searchForm.maxPrice}`;
                }
                if (searchForm.baths != '') {
                    data += `&bathrooms=${searchForm.baths}`;
                }
                if (searchForm.beds != '') {
                    data += `&bedrooms=${searchForm.beds}`;
                }
                if (searchForm.parking != '') {
                    data += `&parking=${searchForm.parking}`;
                }
    
                data += `&folio=${state.ID_COMPANY}`;
    
                config.body = data;
                if (!state.propertiesSearch) {
                    fetch(state.API_URL, config)
                        .then(res => res.json())
                        .then(res => {
                            if (res.status == 200) {
                                if (res.data.length != 0) {
                                    // console.log('recibo');
                                    // console.log(res.data);
                                    resolve(res.data);
                                    let markers2 = [];
                                    res.data.forEach(element => {
                                        
                                        // console.log(element.longitude)
                                        // console.log(element.latitude)
                                        markers2.push({ position: {'lat': (element.latitude) * 1,'lng': (element.longitude) * 1}, id: element.folio})
                                    });
                                    let centerLat = res.data[0].latitude* 1;
                                    let centerLng = res.data[0].longitude* 1;
                                    // console.log("center lati",centerLat)
                                    commit('setCenterLat',centerLat)
                                    // console.log("center long",centerLng)
                                    commit('setCenterLng',centerLng)
                                    commit('setMarkers',markers2)
                                    commit('setPropertiesSearch', res.data);
                                    commit('setError', res.status);
                                    
                                } else {
                                    commit('setPropertiesSearch', "notFind");
                                    commit('setError', 204);
                                    //resolve(204);
                                }
                            } else {
                                commit('setError', res.status);
                                //resolve(204);
                            }
                        })
                }




            })

        },
    

        getPropsPlaces({ commit, state }, city) {
            commit('setError', 0);
            commit('resetPropertiesSearch');
            let config = state.API_PARAMS;
            let data = `m=findProperty&city=${city}&folio=${state.ID_COMPANY}`;

            config.body = data;
            if (!state.propertiesSearch) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setPropertiesSearch', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setPropertiesSearch', "notFind");
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        getPropsRivieraMaya({ commit, state }, city) {
            commit('setError', 0);
            commit('resetPropertiesSearch');
            let config = state.API_PARAMS;
            let data = `m=findProperty&RivieraM=${city}&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.propertiesSearch) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setPropertiesSearch', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setPropertiesSearch', "notFind");
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        getPropsZH({ commit, state }, city) {
            commit('setError', 0);
            commit('resetPropertiesSearch');
            let config = state.API_PARAMS;
            let data = `m=findProperty&ZonaH=${city}&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.propertiesSearch) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setPropertiesSearch', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setPropertiesSearch', "notFind");
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        getPropsCC({ commit, state }, city) {
            commit('setError', 0);
            commit('resetPropertiesSearch');
            let config = state.API_PARAMS;
            let data = `m=findProperty&ZonaCC=${city}&folio=${state.ID_COMPANY}`;
            config.body = data;
            if (!state.propertiesSearch) {
                fetch(state.API_URL, config)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status == 200) {
                            if (res.data.length != 0) {
                                commit('setPropertiesSearch', res.data);
                                commit('setError', res.status);
                            } else {
                                commit('setPropertiesSearch', "notFind");
                                commit('setError', 204);
                            }
                        } else {
                            commit('setError', res.status);
                        }
                    })
            }
        },
        // getPropertiesOpportunities({ commit, state }, ) {
        //     commit('setError', 0);
        //     commit('resetPropertiesSearch');
        //     let config = state.API_PARAMS;
        //     let data = `m=findProperty&featured=&folio=${state.ID_COMPANY}`;
        //     config.body = data;
        //     if (!state.propertiesSearch) {
        //         fetch(state.API_URL, config)
        //             .then(res => res.json())
        //             .then(res => {
        //                 if (res.status == 200) {
        //                     if (res.data.length != 0) {
        //                         commit('setPropertiesSearch', res.data);
        //                         commit('setError', res.status);
        //                     } else {
        //                         commit('setPropertiesSearch', "notFind");
        //                         commit('setError', 204);
        //                     }
        //                 } else {
        //                     commit('setError', res.status);
        //                 }
        //             })
        //     }
        // },
    },
    getters: {
        _getInfoCompany(state) {
            return state.INFO_COMPANY;
        },
        _getHomeSlide(state) {
            return state.homeCarousel;
        },
        _getHomeProperties(state) {
            return state.homeList;
        },
        _getProperties(state) {
            return state.propertiesList;
        },
        _getPropertiesByAgent(state) {
            return state.propertiesByAgent;
        },
        _getPropertiesOpportunities(state) {
            return state.propertiesByOpportunities;
        },
        _getSearch(state){
            return state.search;
        },
        _getMarkers(state){
            return state.markers;
        },
        _getCenterLat(state){
            return state.centerLat;
        },
        _getCenterLng(state){
            return state.centerLng;
        },
        _getDevelopments(state) {
            return state.developmentsList;
        },
        _getDevelopments2(state) {
            return state.developmentsList2;
        },
        _getDevelopmentsMiamiList(state){
            return state.developmentsMiamiList;
        },    
        _getTeam(state) {
            return state.teamList;
        },
        _getTeamByOffice(state) {
            return state.teamByOffices;
        },
        _getDataAgent(state) {
            return state.agentActive;
        },
        _getOffices(state) {
            return state.officesList;
        },
        _getCitySelect(state) {
            return state.cityInputs;
        },
        _getStateSelect(state) {
            return state.stateInputs;
        },
        _getSuburbSelect(state) {
            return state.suburbInputs;
        },
        _getProperty(state) {
            return state.propertyDetail
        },
        _getDevelopment(state) {
            return state.developmentDetail
        },
        _getInputs(state) {
            return state.inputs
        },
        _getResults(state) {
            return state.propertiesSearch
        },
        _getError(state) {
            return state.ERROR_CODE
        },
        _getHomeNews(state) {
            return state.homeNews
        },
        _getHomePlaces(state) {
            return state.homePlaces
        },
        _getHomePropertiesPlaces(state) {
            return state.homePropertiesPlaces
        },
        _getBestDevelopments(state) {
            return state.bestDevelopments
        },
        _getHomeVideos(state) {
            return state.homeVideos
        },
        _getMasterBroker(state) {
            return state.masterBroker
        },
        _getActiveUnit(state) {
            return state.activeUnit;
          },
    }
});