<template>
    <!--/ News Star /-->
    <section class="section-news section-t3">
        <div class="container">
        <div class="row">
            <div class="col-md-12">
            <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                <h2 class="title-a">{{$t('components.best_developments.title')}}</h2>
                </div>
            </div>
            </div>
        </div>
        <div id="best-devs-carousel" class="owl-carousel owl-theme">
            <div v-for="(item,i) in bestDevelopments" :key="item.folio" :index="i" class="carousel-item-c">
                <BestDevelopmentCard  :item="item"/>
            </div>
        </div>
        </div>
    </section>
    <!--/ News End /-->
</template>
<script>
import { mapGetters } from "vuex";
import BestDevelopmentCard from '@/components/developments/BestDevelopmentCard.vue'

export default {
    components:{
        BestDevelopmentCard
    },
     computed: {
        ...mapGetters({
            bestDevelopments: "_getBestDevelopments"
        }),
    },
    mounted(){
    	/*--/ News owl /--*/
        $('#best-devs-carousel').owlCarousel({
            loop: false,
            margin: 30,
            responsive: {
                0: {  
                    items: 1,
                },
                769: {
                    items: 2,
                },
                992: {
                    items: 3,
                }
            }
        });
    }
}
</script>