<template>
   <!--/ Carousel Star /-->
    <div class="intro intro-carousel">
        <div id="carousel" class="owl-carousel owl-theme">
            
            <div v-for="(slide,i) in items" :key="slide.folio" :index="i" class="carousel-item-a intro-item bg-image" :style="`background-image: url(${slide.largefile})`">
                <div class="overlay overlay-a"></div>
                <div class="intro-content display-table">
                    <div class="table-cell">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-8">
                                    <div class="intro-body">
                                        <p class="intro-title-top">{{(slide.city)?`${slide.city}, ${slide.state}`:slide.state}}</p>
                                        <h1 v-if="slide.type==1" class="intro-title mb-4 cc_pointer" @click="goToDevelopment(slide.folio,slide.developmentNameEs)">
                                            {{slide.developmentNameEs}}
                                        </h1>
                                        <h1 v-else class="intro-title mb-4" @click="goToProperty(slide.folio,slide.propertyNameEs)">
                                            {{slide.propertyNameEs}}
                                        </h1>                                        
                                        
                                        <p class="intro-subtitle intro-price cc_pointer" @click="(slide.type!=1)?goToProperty(slide.folio,slide.propertyNameEs):goToDevelopment(slide.folio,slide.developmentNameEs)">
                                            <a ><span class="price-a">{{$t('components.carousel_section.see_details')}} </span></a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-mouse">
            <img class="mousedown" src="/img/mousedown.gif" alt="">
        </div>
    </div>
    <!--/ Carousel end /-->
</template>

<script>
import { mapGetters } from 'vuex'
import services from '@/store/_services'
export default {
    computed:{
       ...mapGetters({
            items:'_getHomeSlide',
        }),
    },
   
    mounted(){
        setTimeout(function(){
             /*--/ Property owl /--*/
                $('#carousel').owlCarousel({
                loop: true,
                margin: -1,
                items: 1,
                nav: true,
                navText: ['<i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>'],
                autoplay: true,
                autoplayTimeout: 3000,
                autoplayHoverPause: true
            });
        },2000)
    },
    methods:{
        goToDevelopment(folioDev, nameDev){
            let nameDevFilt=services.normalize(nameDev)
            let folio = folioDev;
            this.$router.push({
                name: 'Development',
                params: {
                    folio,
                    nameDevFilt
                }
            });
        },
        goToProperty(folioProp, nameProp){
            let namePropFilt=services.normalize(nameProp)
            let folio =folioProp;
            this.$router.push({
                name: 'Property',
                params: {
                    folio,
                    namePropFilt
                }
            });
        }
    }
    
}
</script>
<style>
    .container-mouse{
        width: 100%;
        text-align: center;
        margin-top: -140px;
    }
    .mousedown{
        width: 100px;
        margin: 0 -50px;
        /* text-align: center; */
        z-index: 9;
        position: absolute        
    }
</style>
