<template>
    <!--/dup from placesection scm /-->
    <section class="section-news section-t3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                <div class="title-wrap d-flex justify-content-between">
                    <div class="title-box">
                    <h2 class="title-a">{{$t('components.best_properties.properties')}}</h2>
                    </div>
                </div>
                </div>
            </div>
            <div class="row d-flex wow slideInUp">
                <PropertiesCancunCityCard/>
                <PropertiesZHCard/>
                <PropertiesHomeCard 
                    v-for="(item, i) in places"
                    :key="item.id"
                    :index="i"
                    :item="item"
                />
                <PropertiesRivieraM/> 
            </div>
        </div>
    </section>
    <!--/ News End /-->
</template>
<script>
import { mapGetters } from "vuex";
import PropertiesHomeCard from '@/components/generalCards/PropertiesHomeCard.vue'
import PropertiesRivieraM from '@/components/generalCards/PropertiesRivieraM.vue'
import PropertiesZHCard from '@/components/generalCards/PropertiesZHCard.vue'
import PropertiesCancunCityCard from '@/components/generalCards/PropertiesCancunCityCard.vue'

export default {
    components:{
        PropertiesHomeCard,
        PropertiesRivieraM,
        PropertiesZHCard,
        PropertiesCancunCityCard,
    },
    computed: {
        ...mapGetters({
            places: "_getHomePropertiesPlaces"
        }),
    },
}
</script>