<template>
  <div class="container offices">
    <section>
      <div class="row">
        <div class="col-md-7">
          <!-- <img
            src="http://agent.immosystem.com.mx/view/assets/images/oficina-palmeras.jpg"
            width="100%"
          /> -->
          <img src="../../../public/img/oficinas_costarealty.jpg"  
          class="img img-fluid">
        </div>
        <div class="col-md-5">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <h4><strong class="hRed">Oficina de Ventas en Cancún</strong></h4>
              <p>
                <i class="fas fa-map-marker-alt"></i> Residencial Palmeras Local C – 109 Int. A Mza. 03 Lt. 11 
                Ave. Bonampak SM 3a Puerto Cancún. Cancún, Q. Roo C.P. 77500
              </p>
            </li>
            <!-- <li class="list-group-item">
              <h4>
                <strong class="hRed"
                  >Oficina de Ventas en Playa del Carmen
                </strong>
              </h4>
              <p>
                <i class="fas fa-map-marker-alt"></i> Plaza Riviera Center Carr.
                Federal 307 esq. Calle 41 Sur Local 109 Planta Baja Col. Ejidal,
                Playa del Carmen
              </p>
            </li>
            <li class="list-group-item">
              <h4>
                <strong class="hRed"
                  >Oficina de Ventas en Puerto Aventuras</strong
                >
              </h4>
              <p>
                <i class="fas fa-map-marker-alt"></i> Carretera Chetumal–Puerto
                Juárez Km. 269.5 Puerto Aventuras, Q.Roo CP. 77750
              </p>
            </li> -->
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
  .offices .img-fluid{
    width: 100%;
  }
</style>