<template>
 <Preloader v-if="!agent"/>
  <template v-else>
      <!--/ Intro Single star /-->
  <Banner
    :pageTitle="agent.fullname"
    :address="agent.officeName"
    :page="$route.meta.breadcrumb"
  />
  <!--/ Intro Single End /-->
  <!--/ Agent Single Star /-->
  <section class="agent-single">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-md-6">
              <div class="agent-avatar-box text-center mb-5">
                <img
                  :src="agent.image"
                  :alt="agent.fullname"
                  class="agent-avatar img-fluid"
                />
              </div>
              
              <div class="px-5 text-center">
                  <ContactForm from="agent"/>
              </div>
            </div>
            <div class="col-md-5 section-md-t3">
              <div class="agent-info-box">
                <div class="agent-title">
                  <div class="title-box-d">
                    <h3 class="title-d">
                      {{agent.fullname}}
                    </h3>
                  </div>
                </div>
                <div class="agent-content mb-3">
                  <p v-if="agent.biografia" class="content-d color-text-a">
                    {{agent.biografia}}
                  </p>
                  <div class="info-agents color-a">
                    <p v-if="agent.cellphone">
                      <strong>Teléfono: </strong>
                      <span class="color-text-a"> {{agent.cellphone}} </span>
                    </p>
                    <p v-if="agent.email">
                      <strong>Correo Electrónico: </strong>
                      <span class="color-text-a"> {{agent.email}}</span>
                    </p>
                    <p v-if="agent.skypeid">
                      <strong>skype: </strong>
                      <span class="color-text-a"> {{agent.skypeid}}</span>
                    </p>

                  </div>
                </div>
                <div class="socials-footer">
                  <ul class="list-inline">
                      <li v-if="agent.fb"  class="list-inline-item">
                            <a :href="agent.fb" target="_blank" class="link-one">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="agent.twitter" class="list-inline-item">
                            <a :href="agent.twitter" target="_blank" class="link-one">
                            <i class="fab fa-twitter" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="agent.instagram"  class="list-inline-item">
                            <a :href="agent.instagram" target="_blank" class="link-one">
                            <i class="fab fa-instagram" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="agent.youtube"  class="list-inline-item">
                            <a :href="agent.youtube" target="_blank" class="link-one">
                            <i class="fab fa-youtube" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="agent.linkedin"  class="list-inline-item">
                            <a :href="agent.linkedin" target="_blank" class="link-one">
                            <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="agent.pinterest"  class="list-inline-item">
                            <a :href="agent.pinterest" target="_blank" class="link-one">
                            <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                            </a>
                        </li>
                        <li v-if="agent.gmas" class="list-inline-item">
                            <a :href="agent.gmas"  target="_blank" class="link-one">
                            <i class="fab fa-google-plus-g" aria-hidden="true"></i>
                            </a>
                        </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SectionLoader v-if="!properties" /> 
        <template v-else>
            <div class="col-md-12 section-t4">
                <div class="title-box-d">
                    <h3 class="title-d">{{$t('pages.agent_detail.our_properties')}} ({{totalProps}})</h3>
                </div>
            </div>
                
            <div class="container">
              <div class="row property-grid grid">
                <div class="col-md-4" v-for="(item,i) in properties" :key="item.folio" :name="item.folio" :index="i">
                    <PropertyCard :item="item"/>
                </div>
              </div>
            </div>
        </template>
      </div>
    </div>
  </section>
  <!--/ Agent Single End /-->
  </template>
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import SectionLoader from '@/components/layout/SectionLoader.vue';
import PropertyCard from '@/components/properties/PropertyCard.vue';
import ContactForm from '@/components/layout/ContactForm.vue';
export default {
  components: {
    Banner,
    Preloader,
    SectionLoader,
    PropertyCard,
    ContactForm
  },
  data(){
      return {
          agentId:''
      }
  },
  computed:{
        ...mapGetters({
            agent:'_getDataAgent',
            properties: "_getPropertiesByAgent"
        }),
        totalProps(){
            return this.properties.length
        }
    },
/*  computed:{
      agentData(){
          return this.$store.getters._getDataAgent()
      }
  },*/
  created(){
      let agentId=this.$route.params.folio;    
      this.$store.dispatch("getAgentInfo", agentId);
      this.$store.dispatch("getPropertiesByAgent", agentId);
  }
};
</script>
<style scoped>
.agent-avatar-box .img-fluid{
    width: 50%;
}
.img-fluid{
    height: auto;
}
</style>