<template>
  <form class="form-a contactForm" v-on:submit.prevent="sendMail" role="form">
    <!--div id="sendmessage">Your message has been sent. Thank you!</div-->
    <div id="errormessage"></div>
    <div class="row">
		<!-- Name  -->
      <div
        :class="[
          nameRoute == 'Property' || nameRoute == 'Development'
            ? 'col-md-12'
            : 'col-md-4',
          'mb-1',
        ]"
      >
        <div class="form-group">
          <input
            type="text"
            name="name"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="
              $t('components.flyer_form.fullname_input_placeholder')
            "
            data-rule="minlen:4"
            :data-msg="$t('components.contact_form.name_input_message')"
            v-model="contactForm.name.value"
            :disabled="sent || sending"
          />
          <div class="validation"></div>
        </div>
      </div>
				<!-- Email -->
      <div
        :class="[
          nameRoute == 'Property' || nameRoute == 'Development'
            ? 'col-md-12'
            : 'col-md-4',
          'mb-1',
        ]"
      >
        <div class="form-group">
          <input
            name="email"
            type="email"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="$t('components.contact_form.email_input_placeholder')"
            data-rule="email"
            :data-msg="$t('components.contact_form.email_input_message')"
            v-model="contactForm.email.value"
            :disabled="sent || sending"
          />
          <div class="validation"></div>
        </div>
      </div>
			<!-- Phone -->
      <div class="col-md-4 mb-1">
        <div class="form-group">
          <input
            type="phone"
            name="phone"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="$t('components.contact_form.phone_input_placeholder')"
            data-rule="minlen:4"
            :data-msg="$t('components.contact_form.phone_input_message')"
            v-model="contactForm.phone.value"
            :disabled="sent || sending"
          />
          <div class="validation"></div>
        </div>
      </div>
			<div class="col-sm-12 mb-2">
				<h5>Sobre su propiedad</h5>
			</div>
			<!-- Property name  -->
			<div
        :class="[
          nameRoute == 'Property' || nameRoute == 'Development'
            ? 'col-md-12'
            : 'col-md-4',
          'mb-1',
        ]"
      >
        <div class="form-group">
          <input
            type="text"
            name="propertyname"
            id="propertyname"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="
              $t('components.flyer_form.fproperty_name')
            "
            data-rule="minlen:4"
            :data-msg="$t('components.flyer_form.fproperty_name')"
            v-model="contactForm.propertyname.value"
            :disabled="sent || sending"
          />
          <div class="validation"></div>
        </div>
      </div>
				<!-- Property State  -->
			<div class="col-md-4 mb-1">
        <div class="form-group">
					<select 
					class="form-control form-control-lg form-control-a" 
					id="propertystate"
					name="propertystate"
					v-model="contactForm.propertystate.value"
					@change="getStates"
          :disabled="sent || sending">
							<option selected value="">Estado</option>
							<option v-for="(state,k) in states" :key="k" :index="k" :value="state.stateid">{{state.state}}</option>
					</select>
          <div class="validation"></div>
        </div>
      </div>
			<!-- Property City  -->
      <div class="col-md-4 mb-1">
        <div class="form-group">
					<select
					name="propertycity" 
					id="propertycity"
					class="form-control form-control-lg form-control-a" 
					v-model="contactForm.propertycity.value"
					@change="getCities"
          :disabled="sent || sending"
					>
							<option selected value="">Ciudad</option>
							<option v-for="(city,i) in citys" :key="i" :index="i" :value="city.city">{{city.city}}</option>
					</select>
          <div class="validation"></div>
        </div>
      </div>
			<!-- Property Suburb -->
			<div class="col-md-4 mb-1">
        <div class="form-group">
					<select 
					class="form-control form-control-lg form-control-a" 
					id="propertysuburb"
					name="propertysuburb"
					v-model="contactForm.propertysuburb.value"
					@change="getSuburbs"
          :disabled="sent || sending">
							<option selected value="">Colonia</option>
							<option v-for="(suburb,j) in suburbs" :key="j" :index="j" :value="suburb.suburb">{{suburb.suburb}}</option>
					</select>
          <div class="validation"></div>
        </div>
      </div>
			<!-- Property price -->
      <div class="col-md-4 mb-1">
        <div class="form-group">
          <input
            type="text"
            name="propertyprice"
            id="propertyprice"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="$t('components.flyer_form.fprice_input_placeholder')"
            data-rule="minlen:4"
            :data-msg="$t('components.flyer_form.fprice_input_placeholder')"
            v-model="contactForm.propertyprice.value"
            :disabled="sent || sending"
          > 
          <div class="validation"></div>
        </div>
      </div>
      			<!-- Calendario contacto -->
			<div
        class="col-md-4 mb-1"
      >
        <div class="form-group">
          <input
            type="date"
            id="propertydate"
            name="propertydate"
						min="2020-12-01"
            required="required"
            class="form-control form-control-lg form-control-a"
            :placeholder="
              $t('components.flyer_form.fproperty_name')
            "
            data-rule="minlen:4"
            :data-msg="$t('components.flyer_form.fproperty_name')"
            v-model="contactForm.propertydate.value"
            :disabled="sent || sending"
          />
          <div class="validation"></div>
        </div>
      </div>

      <!-- Property Exterior image -->
       <div class="col-md-6 mb-1">
        <div class="form-group exteriorimage">
          <label for="exteriorimage">
            {{ $t("components.flyer_form.fimg_fachada_input_placeholder") }}
          </label>
          <input
        
            type="file"
            ref="exteriorImage"
            name="exteriorImage"
						@change="selectedFile"
            class="form-control-file input-file"
            id="exteriorimage"
            :disabled="sent || sending"
          />
          <div class="validation"></div>
        </div>
      </div> 
			<!-- Property Interior Image -->
      <div class="col-md-6 mb-1">
        <div class="form-group interiorimage">
          <label for="interiorimage">
            {{ $t("components.flyer_form.fimg_interior_input_placeholder") }}
          </label>
          <input
            @change="selectedFile2"
            type="file"
            name="interiorimage"
						ref="interiorimage"
            class="form-control-file input-file"
            id="interiorimage"
            :disabled="sent || sending"
          />
          <div class="validation"></div>
        </div>
      </div>

			<!-- Message -->
      <!-- <div class="col-md-12 mb-1">
        <div class="form-group">
          <textarea
            name="message"
            class="form-control"
            cols="45"
            rows="3"
            required="required"
            data-rule="required"
            :data-msg="$t('components.contact_form.message_input_message')"
            :placeholder="
              $t('components.contact_form.message_input_placeholder')
            "
            v-model="contactForm.message.value"
            :disabled="sent || sending"
          ></textarea>
          <div class="validation"></div>
        </div>
      </div> -->

      <div class="col-md-12">
        <button
          v-if="!sending"
          :disabled="sent"
          type="submit"
          class="btn btn-a"
        >
          {{ $t("components.contact_form.button") }}
        </button>
        <div v-else>
          <!-- Loader -->
          <SectionLoader />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import services from "@/store/_services";
import VueRecaptcha from "vue-recaptcha";
import SectionLoader from "@/components/layout/SectionLoader.vue";

export default {
  components: {
    VueRecaptcha,
    SectionLoader,
  },
  props: {
    from: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      URL: (state) => state.COSTA_URLTEST,
      PARAMS: (state) => state.MAIL_PARAMS,
      SITE: (state) => state.URL_SITE,
    }),
    ...mapGetters({
      companyInfo: "_getInfoCompany",
      propertyInfo: "_getProperty",
      agent: "_getDataAgent",
      dev: "_getDevelopment",
			citys:"_getCitySelect",
			suburbs:"_getSuburbSelect", 
			states:"_getStateSelect", 
    }),
    nameRoute() {
      return this.$route.name;
    },
  },
  data() {
    return {
      url: '',
      imageForm: File,
      imageForm2: File,
      contactForm: {
        name: {
          value: '',
          status: Boolean,
        },
				email: {
          value: '',
          status: Boolean,
        },
				phone: {
          value: '',
          status: Boolean,
        },
				propertyname: {
          value: '',
          status: Boolean,
        },
				propertystate: {
          value: '',
          status: Boolean,
        },
				propertycity: {
          value: '',
          status: Boolean,
        },
				propertysuburb: {
          value: '',
          status: Boolean,
        },
				propertyprice: {
          value: '',
          status: Boolean,
        },
				// interiorimage:{
				// 	value: '',
				// 	status: Boolean,
				// },
				// exteriorimage:{
				// 	value: '',
				// 	status: Boolean,
				// },
        exteriorImage: {
          value: null,
          status: Boolean,
        }
        ,
				propertydate:{
					value: '',
					status: Boolean,
				},
        // message:{
        //   value: '',
        //   status: Boolean,
        // }
      },
      sending: false,
      sent: false,
      bindTelProps: {
        id: "phoneContact",
        mode: "international",
        defaultCountry: "MX",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: "Teléfono",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: ["MX", "BR", "VE"],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 18,
        inputClasses: "form-control py-0",
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  mounted() {
    this.url = this.SITE + this.$route.path;
  },
  methods: {
    selectedFile(event){
      console.log(event);
      this.imageForm = event.target.files[0];
      },
      selectedFile2(event){
        console.log(event);
        this.imageForm2 = event.target.files[0];
      },
    
     onUpload(){
      // const fd = new FormData();
      // fd.append('image',this.exteriorImage, this.exteriorImage.name)
      // console.log('uploadimage')
      let formData = new FormData();
  		formData.append('file', this.imageForm);
  		for(var pair of formData.entries()) {
    		console.log(pair[0]+ ', '+ pair[1]); 
   		}
   		this.$store.dispatch('onUpload', formData);
     },
		getCities(){
      // console.log('evento on change', getCitySelect;
      this.$store.dispatch("getSuburbSelectDepenment",this.contactForm.propertycity.value);
    },
		getSuburbs(){
      // console.log('evento on change', getSuburbSelect;
      //this.$store.dispatch("getSuburbSelectDepenment",this.contactForm.propertystate.value);
    },
		getStates(){
      console.log('se activa el evento')
      console.log(this.contactForm.propertystate.value);
      console.log('respuesta')
      // console.log('evento on change', getStatesSelect);
      //this.$store.dispatch("getStatesSelect");
      this.$store.dispatch("getCitySelectDepenment",this.contactForm.propertystate.value);
    },

    // sendMails(){
    //   let formData = new FormData();
    //     formData.append('exteriorImage', this.contactForm.exteriorImage);
    //     formData.append('name', this.contactForm.name);
    //     formData.append('email', this.contactForm.email);
    //     formData.append('phone', this.contactForm.phone);
    //     formData.append('message', this.contactForm.message);
    //     formData.append('propertyname', this.contactForm.propertyname);
    //     formData.append('propertystate', this.contactForm.propertystate);
    //     formData.append('propertycity', this.contactForm.propertycity);
    //     formData.append('propertysuburb', this.contactForm.propertysuburb);
    //     formData.append('propertyprice', this.contactForm.propertyprice);
    //     formData.append('propertydate', this.contactForm.propertydate);
        
    //   console.log('--newone')
    // },
    sendMail() {
      console.log('se ejecuta')
      //Activate Loader
      this.sending = true;

      //Validate empty form
      for (let x in this.contactForm) {
        if (this.contactForm[x].value == "") {
          this.contactForm[x].status = false;
          this.sending = false;
          return false;
        }
      }
 //  selectedFile();
console.log('se valida el capcha')
      //Validate email
      if (!this.validateEmail(this.contactForm.email.value)) {
        this.$swal.fire({
          icon: "error",
          text: "El formato del correo electrónico ingresado no es válido.",
          showConfirmButton: false,
          showCloseButton: true,
        });
        this.contactForm.email.status = false;
        this.sending = false;
        return false;
      } else {
        this.contactForm.email.status = true;
      }

      //Validate with captcha
      
      this.mailerExecute();
      //this.$invisibleRecaptcha.execute();
    },
    validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    mailerExecute() {
      let self = this;
      let form = new FormData();
      for (let x in this.contactForm) {
        form.append(x,this.contactForm[x].value);
      }

      form.append('m','sharingInfoCosta');
      form.append('imageExt',this.imageForm);
      form.append('imageInt',this.imageForm2);
      let config = self.PARAMS;

      config.body = form;
      //Send Mail
      services.sendMail(self.URL, config).then((res) => {
        console.log(res);
        self.$swal.fire({
          icon: "success",
          title: "¡Envio completo!",
          text: `Gracias por contactarnos :)`,
          showConfirmButton: false,
          showCloseButton: true,
        });
        self.sending = false;
        self.sent = true;

        self
        
      });
    },
    onCaptchaVerified() {
      console.log("en verificado;");
      let self = this;
      let config = self.PARAMS;
      let data = `name=${self.contactForm.name.value}&email=${self.contactForm.email.value}&exteriorImage=${self.contactForm.exteriorImage.value}&propertyname=${self.contactForm.propertyname.value}&propertystate=${self.contactForm.propertystate.value}&propertycity=${self.contactForm.propertycity.value}&propertysuburb=${self.contactForm.propertysuburb.value}&propertyprice=${self.contactForm.propertyprice.value}&propertydate=${self.contactForm.propertydate.value}&phone=${self.contactForm.phone.value}&companyName=crhis.vazquez@gmail.com&companyEmail=${self.companyInfo.reserveemail}&companyAddress=${self.companyInfo.address}&companyPhone=${self.companyInfo.phone}&companyLogo=${self.companyInfo.logoClean}&urlSite=${self.SITE}&origin=contact&special=${self.$store.state.ID_COMPANY}`;
      console.log("cool");
      config.body = data;
      //console.log(self.URL);
      //Send Mail
      /*services.sendMail(self.URL,config)
            .then((res)=>{
                if(res==200){
                    self.$swal.fire({
                        icon: 'success',                    
                        title: '¡Envio completo!',
                        text: `Gracias por contactarnos :)`,
                        showConfirmButton: false,
                        showCloseButton: true,
                    });
                    self.sending=false;
                    self.sent=true;

                    self.$watchLocation()
                    .then(coordinates=>{
                        let contactInfo={
                            fullname:self.contactForm.name.value,
                            email:self.contactForm.email.value,
                            phone:self.contactForm.phone.value,
                            message:self.contactForm.message.value,
                            latitude:coordinates.lat,
                            longitude:coordinates.lng,
                            contacttype:1,
                            soldagentid:2520
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                    .catch(()=>{
                        let contactInfo={
                            fullname:self.contactForm.name.value,
                            email:self.contactForm.email.value,
                            phone:self.contactForm.phone.value,
                            message:self.contactForm.message.value,
                            latitude:0,
                            longitude:0,
                            contacttype:1,
                            soldagentid:2520                            
                        }
                        self.$store.dispatch('setNewContact',contactInfo);
                    })
                }else{
                    self.$swal.fire({
                        icon: "error",
                        text: "No se pudo completar el envio del formulario.",
                        showConfirmButton: false,
                        showCloseButton: true
                    });
                }
            });*/
    },
    onCaptchaExpired() {
      this.$swal.fire({
        icon: "error",
        text: "No se pudo completar el envio del formulario.",
        showConfirmButton: false,
        showCloseButton: true,
      });
      this.sending = false;
      this.$refs.invisibleRecaptcha.reset();
    },
  },
	created(){
    this.$store.dispatch("getCitySelect");
		this.$store.dispatch("getSuburbSelect");
		this.$store.dispatch("getStateSelect");
  },
};
</script>
<style scoped>
.interiorimage, .exteriorimage{
	outline: 2px dashed grey;
	outline-offset: -11px;
	background: #bd17341f;
	color: dimgray;
	padding: 10px 15px;
	min-height: 100px;
	position: relative;
	cursor: pointer;
}
</style>
