<template>
  <section class="pt-0 detailonmap">
  <div class="intro-single p-0">
    <div class="">
          <div v-for="(image,i) in item.images.slice(0, 1)" :key="i" class="carousel-item-b">
            <img :src="image.largefile" :alt="image.alt" class="carousel-image img-detailmap" />
          </div>
    </div>
    <div class="title-single-box">
        <!-- <p>folio{{folio}}</p> -->
        <h2 class="title-single"><a  @click="goToProperty(item.folio)" class="cc_pointer">{{item.propertyNameEs}}</a></h2>
        <div class="row">
            <div class="col-5">
                <span class="color-text-a"><span v-if="item.city != 0">{{item.city}} </span><span  v-if="item.state != 0">, {{item.state}} </span></span>
            </div>
            <div class="col-3">
                <span  v-if="item.toperation[0] != 0" class="color-text-a">{{item.toperation[0]}}</span>
            </div>
            <div class="col-4">
                <h5 class="color-text-a">$ {{format}}</h5> 
            </div>
        </div>
    </div>
    <div class="description-detail row">
        <div v-if="item.bedrooms > 0" class="col-sm-3 text-center font-weight-bold">Recámaras <br> <span class="font-weight-normal"> {{item.bedrooms}} </span></div>
        <div v-if="item.bathrooms > 0" class="col-sm-3 text-center font-weight-bold">Baños <br> <span class="font-weight-normal"> {{item.bathrooms}}</span></div>
        <div  v-if="item.num_parking > 0" class="col-sm-3 text-center font-weight-bold">Estacionamientos <br> <span class="font-weight-normal"> {{item.num_parking}}</span> </div>
        <div  v-if="item.m2c > 0" class="col-sm-3 text-center font-weight-bold">Área de construcción <br> <span class="font-weight-normal">{{item.m2c}}m<sup>2</sup> </span></div>
        <div  v-if="item.mlot > 0" class="col-sm-3 text-center font-weight-bold">Área de terreno <br> <span class="font-weight-normal">{{item.mlot}}m<sup>2</sup> </span></div>
    </div>
    <div class="description">
      <ul
          class="nav nav-pills-a nav-pills mb-3 "
          id="pills-tab"
          role="tablist"
      >
          <li class="nav-item">
            <a
                class="nav-link active"
                id="spanish-tab"
                data-toggle="pill"
                href="#spanish"
                role="tab"
                aria-controls="spanish"
                aria-selected="true"
                >Descripción
            </a>
          </li>
          <li v-if=" item.interiorAmenities||item.exteriorAmenities||item.developmentAmenities||item.otherAmenities" class="nav-item">
              <a
              class="nav-link"
              id="english-tab"
              data-toggle="pill"
              href="#english"
              role="tab"
              aria-controls="english"
              aria-selected="false"
              >Amenidades
              </a>
          </li>
          <li v-if="item.developmentServices || item.services" class="nav-item">
          <a
              class="nav-link"
              id="english-tab"
              data-toggle="pill"
              href="#serv"
              role="tab"
              aria-controls="serv"
              aria-selected="false"
              >Servicios</a>
          </li>
      </ul>
        <div class="tab-content" id="languajes-tabContent">
            <div
                class="tab-pane fade show active"
                id="spanish"
                role="tabpanel"
                aria-labelledby="spanish-tab"
            >
                <div class="property-description">
                    <p class="description color-text-a">
                        <pre>{{item.descriptionEs}}</pre>
                    </p>
                </div>

            </div>
            <div
              v-if="item.interiorAmenities || item.otherAmenities" 
                class="tab-pane fade "
                id="english"
                role="tabpanel"
                aria-labelledby="english-tab"
            >
                <div class="property-description p-3">
                  <div class="amenities-list color-text-a">
                      <h5>{{$t('pages.property_detail.amenities_interior')}}</h5>
                      <ul class="list-a no-margin">
                        <li v-for="(item,i) in item.interiorAmenities" :key="i" :index="i" >{{item}}</li>
                        <li v-for="(item,i) in item.otherAmenities" :key="i" :index="i" >{{item}}</li>
                      </ul>
                  </div>
                  <div v-if="item.exteriorAmenities" class="amenities-list color-text-a mt-3">
                      <h5>{{$t('pages.property_detail.amenities_exterior')}}</h5>
                      <ul class="list-a no-margin">
                      <li v-for="(item,i) in item.exteriorAmenities" :key="i" :index="i" >{{item}}</li>
                      </ul>
                  </div>
                  <div v-if="item.developmentAmenities" class="amenities-list color-text-a mt-3">
                      <h5>{{$t('pages.property_detail.amenities_development')}}</h5>
                      <ul class="list-a no-margin">
                      <li v-for="(item,i) in item.developmentAmenities" :key="i" :index="i" >{{item}}</li>
                      </ul>
                  </div>
                </div>

            </div>
            <div
                v-if="item.developmentServices || item.services"
                class="tab-pane fade "
                id="serv"
                role="tabpanel"
                aria-labelledby="serv-tab"
            >
                <div class="property-description p-3">
                  <div  class="amenities-list color-text-a">
                      <ul class="list-a no-margin">
                      <li v-for="(item,i) in item.developmentServices" :key="i" :index="i" >{{item}}</li>
                      <li v-for="(item,i) in item.services" :key="i" :index="i" >{{item}}</li>
                      </ul>
                  </div>
                </div>

            </div>
        </div>
    </div>
  </div>
  </section>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            required:true
        },
    },
    computed:{
      format(val) {
          let value=val.item.bestprice[0]
          //Formateo de decimales
          let decimalPrice = value.lastIndexOf(".");
          //Extaracción de moneda
          let currentPriceM = value.indexOf("MXN");
          let currentPriceU = value.indexOf("USD");

          // Precio formateado value.substr(currentPrice)
          let formatPrice=value.substr(0,decimalPrice)+" ";
          if(currentPriceM!='-1'){
              formatPrice+= value.substr(currentPriceM)
          }else{
              formatPrice+= value.substr(currentPriceU)
          }
          return formatPrice.slice(1);
      },
      formatr(val) {
        let value=val.item.prices.rent
        //Formateo de decimales
        let decimalPrice = value.lastIndexOf(".");
        //Extaracción de moneda
        let currentPriceM = value.indexOf("MXN");
        let currentPriceU = value.indexOf("USD");

        // Precio formateado value.substr(currentPrice)
        let formatPrice=value.substr(0,decimalPrice)+" ";
        if(currentPriceM!='-1'){
            formatPrice+= value.substr(currentPriceM)
        }else{
            formatPrice+= value.substr(currentPriceU)
        }
        return formatPrice.slice(1);
      }
    },
        mounted(){
        setTimeout(function(){
             /*--/ Property owl /--*/
                $('#carousel-deta').owlCarousel({
                loop: true,
                margin: -1,
                items: 1,
                nav: true,
                navText: ['<i class="ion-ios-arrow-back" aria-hidden="true"></i>', '<i class="ion-ios-arrow-forward" aria-hidden="true"></i>'],
                autoplay: true,
                autoplayTimeout: 3000,
                autoplayHoverPause: true
            });
        },2000)
    },
    methods:{
        goToProperty(){
            let folio = this.item.folio;
            const routeData = this.$router.resolve({name: 'PropertyShort', params: {folio}});
            window.open(routeData.href, '_blank');
        }
    },

}
</script>

<style scoped>
.img-fluid{
    width: 80%;
    height: 100%;
    object-position: center;
    object-fit: contain;
    margin: auto;
  }
.summary-list{
    padding-right: 0rem;
}
.summary-list .list span {
    text-align: end;
}
.summary-list .list span:hover {
    color:#bd1734;
}
.subt{
    margin-left: -17rem;
    font-weight: bold;
    color:#bd1734; 
}
.mb-less{
    margin-bottom:-10px;
}
pre {

    white-space:pre-line;

    height: auto;

    overflow: hidden;

    background: transparent;

    border-color: transparent;

    color: inherit;

    font-family: inherit;

    font-size: 0.9rem;

    text-align: justify;

}
.detailonmap .intro-single .title-single-box .title-single {
  font-size: 1.8rem;
}

  .detailonmap .list-a {
  display: flex;
}

.detailonmap .img-detailmap{
  height: 50vh;
  object-fit: cover;
}
</style>