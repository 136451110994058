import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home/';
import About from '@/views/About/';
import Privacy from '@/views/About/Privacy.vue';
import Consultancy from '@/views/About/Consultancy.vue';
import Offices from '@/views/About/Offices.vue';
import Properties from '@/views/Properties/';
import Property from '@/views/Properties/Detail/';
import Developments from '@/views/Developments/';
import DevelopmentsMiami from '@/views/Developments/DesarrollosMiami.vue';
import Development from '@/views/Developments/Detail/';
import Results from '@/views/Results/';
import ResultsSingle from '@/views/Results/indexoriginal.vue';
import Agents from '@/views/Agents/';
import Agent from '@/views/Agents/Detail/';
import Contact from '@/views/Contact/';
import NotFound from '@/views/Error/';
import Opportunities from '@/views/Opportunities/';
import SellProperty from '@/views/SellProperty/';

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            breadcrumb: 'routes.home.breadcrumb',
            hideBanner: true
        }
    },
    {
        path: '/nosotros',
        name: 'About',
        component: About,
        meta: {
            pageTitle: 'routes.about.page_title',
            breadcrumb: 'routes.about.breadcrumb',
        }
    },
    {
        path: '/aviso-de-privacidad',
        alias: '/privacy',
        name: 'Privacy',
        component: Privacy,
        meta: {
            pageTitle: 'routes.privacy.page_title',
            breadcrumb: 'routes.privacy.breadcrumb',
        }
    },
    {
        path: '/consultoria',
        alias: '/consultancy',
        name: 'Consultancy',
        component: Consultancy,
        meta: {
            pageTitle: 'routes.consultancy.page_title',
            breadcrumb: 'routes.consultancy.breadcrumb',
        }
    },
    {
        path: '/oficinas-de-costa-realty',
        name: 'CrOffices',
        component: Offices,
        meta: {
            pageTitle: 'routes.cr_offices.page_title',
            breadcrumb: 'routes.cr_offices.breadcrumb',
        }
    },
    {
        path: '/propiedades',
        name: 'Properties',
        component: Properties,
        meta: {
            pageTitle: 'routes.properties.page_title',
            breadcrumb: 'routes.properties.breadcrumb',
        },
        children: [{
            //Use when share a list of properties from IMMO CRM 360
            path: '/propiedades/:codeShareProperties',
            name: "ShareProperies",
            component: Properties,
            meta: {
                pageTitle: 'routes.properties.page_title',
                breadcrumb: 'routes.properties.breadcrumb',
            }
        }]
    },
    {
        path: '/propiedad/:folio/:namePropFilt',
        name: 'Property',
        component: Property,
        meta: {
            breadcrumb: 'routes.property.breadcrumb',
            hideBanner: true
        },
        children: [{
                // Use when agentId exist
                path: '/propiedad-:folio-:agentId',
                name: "PropertyAgent",
                component: Property,
                meta: {
                    breadcrumb: 'routes.property.breadcrumb',
                    hideBanner: true
                }
            },
            {
                // Use when namePropFilt not exist
                path: '/propiedad/:folio',
                name: "PropertyShort",
                component: Property,
                meta: {
                    breadcrumb: 'routes.property.breadcrumb',
                    hideBanner: true
                }
            }
        ]
    },
    {
        path: '/desarrollos',
        name: 'Developments',
        component: Developments,
        meta: {
            pageTitle: 'routes.developments.page_title',
            breadcrumb: 'routes.developments.breadcrumb',
        }
    },
        {
        path: '/desarrollos-miami',
        name: 'DevelopmentsMiami',
        component: DevelopmentsMiami,
        meta: {
            pageTitle: 'routes.DevelopmentsMiami.page_title',
            breadcrumb: 'routes.DevelopmentsMiami.breadcrumb',
        }
    },
    {
        path: '/desarrollo/:folio/:nameDevFilt',
        name: 'Development',
        component: Development,
        meta: {
            breadcrumb: 'routes.development.breadcrumb',
            hideBanner: true
        }
    },
    {
        path: '/resultado',
        name: 'ResultsSingle',
        component: ResultsSingle,
        meta: {
            pageTitle: 'routes.search_results.page_title',
            breadcrumb: 'routes.search_results.breadcrumb',
            hideFooter: true
        },
        children: [{
            path: '/resultado-desarrollos',
            name: 'ResultsDevs',
            component: ResultsSingle,
            meta: {
                pageTitle: 'routes.search_results.page_title',
                breadcrumb: 'routes.search_results.breadcrumb'
            }
        }]
    },
    {
        path: '/busqueda',
        name: 'Results',
        component: Results,
        meta: {
            pageTitle: 'routes.search_results.page_title',
            breadcrumb: 'routes.search_results.breadcrumb',
            hideBanner: true,
            hideFooter: true
        },
        // children: [{
        //     path: '/resultado-desarrollos',
        //     name: 'ResultsDevs',
        //     component: ResultsSingle,
        //     meta: {
        //         pageTitle: 'routes.search_results.page_title',
        //         breadcrumb: 'routes.search_results.breadcrumb'
        //     }
        // }]
    },
    {
        path: '/oportunidades',
        name: 'Opportunities',
        component: Opportunities,
        meta: {
            pageTitle: 'routes.opportunities.page_title',
            breadcrumb: 'routes.opportunities.breadcrumb',
        }
    },
    // {
    //     path: '/agentes',
    //     name: 'Agents',
    //     component: Agents,
    //     meta: {
    //         pageTitle: 'routes.agents.page_title',
    //         breadcrumb: 'routes.agents.breadcrumb',
    //     }
    // },
    {
        path: '/agente/:folio/:nameAgentFilt',
        name: 'Agent',
        component: Agent,
        meta: {
            breadcrumb: 'routes.agent.breadcrumb',
            hideBanner: true
        }
    },
    {
        path: '/contacto',
        name: 'Contact',
        component: Contact,
        meta: {
            pageTitle: 'routes.contact.page_title',
            breadcrumb: 'routes.contact.breadcrumb',
            
        }
    },
    {
        path: '/VenderMiInmueble',
        name: 'SellProperty',
        component: SellProperty,
        meta: {
            pageTitle: 'routes.sellproperty.page_title',
            breadcrumb: 'routes.sellproperty.breadcrumb',
            hideBanner: true
        }
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
        name: 'Error',
        meta: {
            breadcrumb: 'routes.error.page_title',
            pageTitle: 'routes.error.breadcrumb',
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.afterEach(() => {
    document.documentElement.scrollTop = 0;
});

export default router