<template>
    <!--/ Agents Star /-->
    <section class="section-agents section-t8">
        <div class="container">
        <div class="row">
            <div class="col-md-12">
            <div class="title-wrap d-flex justify-content-between">
                <div class="title-box">
                <h2 class="title-a">Principales Agentes</h2>
                </div>
                <div class="title-link">
                <a href="agents-grid.html">Todos los Agentes
                    <span class="ion-ios-arrow-forward"></span>
                </a>
                </div>
            </div>
            </div>
        </div>
        <div class="row">
            <AgentCard/>
        </div>
        </div>
    </section>
    <!--/ Agents End /-->
</template>
<script>
import AgentCard from '@/components/agents/AgentCard.vue'
export default {
    components:{
        AgentCard
    }
}
</script>