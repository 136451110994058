
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
// import VueGoogleMaps from "vue2-google-maps";
import VueGoogleMaps from '@fawmi/vue-google-maps'
////import VueGeolocation from 'vue-browser-geolocation';
////import { VueCookieNext } from 'vue-cookie-next'
import VueCookies from 'vue3-cookies'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';


// const googleMapsSetup = {
//     load: {
//         key: "AIzaSyA3mcj07-7u_nSPUosA0IuwIU3WPnP0D6I"
//     }
// }

const app = createApp(App);
// app.component('MyGlobalComponent', VueGoogleMaps);
app.use(store);
app.use(i18n);
app.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyD_hctVx_vmAOtFevBMS0OU1GEKP6BIwcU"
    }
  });
////app.use(VueGeolocation);
app.use(VueCookies);
app.use(VueSweetalert2);
////app.use(VueGoogleMaps, googleMapsSetup);
app.use(router);

app.mount('#app');
