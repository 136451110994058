<template>
    <!--/ Master Broker Star /-->
    <section class="section-agents section-t3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="title-wrap d-flex justify-content-between">
                        <div class="title-box">
                            <h2 class="title-a">MASTER BROKER</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <MBroker v-for="(item,i) in masterBroker" :key="item.id" :index="i" :item="item"/>
            </div>
        </div>
    </section>
    <!--/ Master Broker End /-->
</template>
<script>
import { mapGetters } from "vuex";
import MBroker from '@/components/generalCards/MBroker.vue'

export default {
    components:{
        MBroker
    },
    computed: {
        ...mapGetters({
            masterBroker: "_getMasterBroker"
        }),
    },
}
</script>