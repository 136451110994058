<template>
  <NotFound code="404" :messageError="$t('layout.not_found.404_message')"/>
</template>

<script>
import NotFound from '@/components/layout/NotFound.vue';
export default {
  components:{
    NotFound
  }
}
</script>