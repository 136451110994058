<template>
  <main v-if="loader">
    <Preloader/>
  </main>
  <main v-else>
    <!--/ Form Search Star /-->
    <SearchForm/>
    <!--/ Form Search End /-->

    <Header/>

    <Banner v-if="!$route.meta.hideBanner" :pageTitle="$route.meta.pageTitle" :page="$route.meta.breadcrumb"/>

    <router-view/>

    <Footer v-if="!$route.meta.hideFooter"/>
  </main>
</template>
<script>
import Footer from '@/components/layout/Footer.vue';
import Header from '@/components/layout/Header.vue';
import Preloader from '@/components/layout/Preloader.vue';
import SearchForm from '@/components/layout/SearchForm.vue';
import Banner from '@/components/layout/Banner.vue';
export default {
  name: "App",
  components:{
    Footer,
    Header,
    Preloader,
    SearchForm,
    Banner
  },
  data(){
    return{
      loader:true
    }
  },
  methods:{
    checkCookie(keyName){
      let confirmCookie = this.$cookies.get(keyName);

      if(!confirmCookie){
        this.createCookie(keyName, 1, 365);
        this.$store.dispatch('setNewVisit');
      }else{
        this.$store.dispatch('setConcurrentVisit');
      }
    },

    createCookie(name, value, expirate) {
      var d = new Date();
      d.setTime(d.getTime() + (expirate*24*60*60*1000));
      var expires = "expires="+d.toUTCString();
      //create
      this.$cookies.set(name, value, expires);
    }
  },
  created(){
    var self=this;
    var state=this.$store.state;
  
    this.$store.dispatch('getInfoCompany');
    this.$store.dispatch('getCompanyMetadata');

    setTimeout(()=>{
      this.loader=false;
    },1000);
    
    //Visits Control
    if (process.env.NODE_ENV === 'production') {
      this.checkCookie('countVisit');
      window.addEventListener('unload', function () {
        if (!navigator.sendBeacon) return;

        let url = state.API_URL;
        // Create the data to send
        var data = {
          companyid:  self.$store.state.ID_COMPANY,
          live: '0'
        };
        var blob = new Blob([JSON.stringify(data)], {
          type: 'application/json'
        });
        
        // Send the beacon
        navigator.sendBeacon(url, blob);
        // Log the data and result
        //console.log("sendBeacon: URL = ", url, "; data = ", data, "; status = ", status);
      });
    }
  }
}
</script>