<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center ">
                <h1>{{code}}</h1>
                <h2>{{$t(`layout.error_section.${code}.title`)}}</h2>
                <p>{{$t(`layout.error_section.${code}.description`)}}
                </p>
                <router-link v-if="code != 204" class="btn red" to="/">{{$t(`layout.error_section.${code}.link`)}}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name:'notFound',
  props:{
    code:{
      type:String,
      required:true
    },
    messageError:{
      type:String,
      required:true
    }
  }
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: 7.5em;
  margin: 15px 0px;
  font-weight:bold;
}
h2 {
  font-weight:bold;
}
.btn {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 0px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
  &.red {
    border: 4px solid #bd1734;
    color: #000;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background:#bd1734;
      z-index: -1;
      transition: 0.2s ease;
    }
    &:hover {
      color: var(--white);
      background: var(--gray);
      transition: 0.2s ease;
      &:before {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width:768px) {
  body {
    display:block;
  }
  .container {
    margin-top:70px;
    margin-bottom:70px;
  }
} 
</style>