<template>

    <!--/ Property Grid Star /-->
    <section class="property-grid grid">
        <NotFound v-if="error==204" code="204" :messageError="$t('layout.not_found.204_message')"/>
        <div v-else class="container">
            <SectionLoader v-if="!results"/>
            <template v-else>
                <div v-if="!fromDevs" class="row">
                    <div class="col-md-4" v-for="(item,i) in results" :key="item.folio" :name="item.folio" :index="i">
                        <PropertyCard :item="item"/>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-md-4" v-for="(item,i) in results" :key="item.folio" :name="item.folio" :index="i">
                        <DevelopmentCard :item="item"/>
                    </div>
                </div>
            </template>
        </div>
    </section>
    <!--/ Property Grid End /-->
</template>

<script>
import { mapGetters } from "vuex";
import SectionLoader from '@/components/layout/SectionLoader.vue';
import PropertyCard from '@/components/properties/PropertyCard.vue';
import DevelopmentCard from '@/components/developments/DevelopmentCard.vue';
import NotFound from '@/components/layout/NotFound.vue';
export default {
    components:{
        PropertyCard,
        SectionLoader,
        NotFound,
        DevelopmentCard
    },
     data(){
        return{
            prevRoute:null,
            developments:false
        }
    },
    computed: {
        ...mapGetters({
            results:'_getResults',
            error: "_getError",
        }),
        fromDevs(){
            if(this.$route.name=='ResultsDevs'){
                return true;
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(self => {
            self.prevRoute = from
            if(!self.prevRoute.name){
                self.$router.push({name: 'Home'});
            } 
        })
    },
    created(){
        
    }
}
</script>