<template>
    <!--/ Property Grid Star /-->
    <section class="property-grid grid">
        <div class="container">
            <SectionLoader v-if="!properties" /> 
            <div v-else class="row">
                <div class="col-md-4" v-for="(item,i) in properties" :key="item.folio" :name="item.folio" :index="i">
                    <PropertyCard :item="item"/>
                </div>
            </div>
        </div>
    </section>
    <!--/ Property Grid End /-->
</template>

<script>
import { mapGetters } from "vuex";
import SectionLoader from '@/components/layout/SectionLoader.vue';
import PropertyCard from '@/components/properties/PropertyCard.vue';
export default {
    components:{
        PropertyCard,
        SectionLoader
    },
    data() {
        return {
        codeShareProperties: 0,
        };
    },
    computed: {
        ...mapGetters({
            properties: "_getProperties"
        }),
    },
    created() {
        if (this.$route.params.codeShareProperties) {
        this.codeShareProperties = this.$route.params.codeShareProperties;
        }
        this.$store.dispatch("getProperties", this.codeShareProperties);
    },
}
</script>