<template>
  <div class="container">
    <div class="card-body">
      <p>
        <strong>
           En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de Particulares (LFPDPPP) Inmobiliaria Basac, S.A. de C.V., al cual se le denominara “Costa Realty” pone a su disposición el siguiente Aviso de Privacidad:
        </strong>
      </p>
      <h4>I.	RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES.</h4>
      <p>
        Las personas morales responsables de recabar sus datos personales serán las sociedades controladoras, subsidiarias o afiliadas que se encuentren bajo el común del grupo empresarial de <b>Inmobiliaria Basac, S.A. de C.V.</b>, a quienes en conjunto se les denominará como <b> “Costa Realty” </b>mismas que para efectos del presente Aviso de Privacidad señalan como domicilio el ubicado en avenida Bonampak, esquina Donceles, Condominio Palmeras, Local C-109 Int. A, Cancún, Quintana Roo, CP 77500. Por medio del presente hace de su conocimiento que los datos personales, patrimoniales o financieros que usted ha proporcionado a esta empresa por cualquier medio, de manera enunciativa más no limitativa, a través de correos electrónicos, telefax, llenado de formatos, llamadas telefónicas, contratos, correo, tarjetas de presentación, medios digitales, etc.; serán tratados estrictamente bajo los principios de licitud, consentimiento, información, calidad, finalidad, lealtad y responsabilidad.


      </p>
      <h4>II. DATOS RECABADOS.</h4>
      <p>
        Los datos personales que <b>“Costa Realty”</b> recabe de usted, serán los
        siguientes:
      </p>
      <ol>
        <li> Nombre.</li>
        <li> Domicilio.</li>
        <li>Fecha de nacimiento.</li>
        <li> País de nacimiento.</li>
        <li> Nacionalidad.</li>
        <li> Ocupación.</li>
        <li> Profesión.</li>
        <li> Actividad o giro del negocio al que se dedique.</li>
        <li> Números telefónicos.</li>
        <li> Correos electrónicos.</li>
        <li> CURP.</li>
        <li> RFC.</li>
        <li> Domicilio de propiedades.</li>
        <li> Nombre de inquilinos.</li>
        <li> Montos de renta.</li>
        <li> Fechas de vigencia de arrendamientos.</li>
        <li> Referencias.</li>
      </ol>

      <h4>III. FINALIDADES DEL TRATAMIENTO DE DATOS PERSONALES.</h4>
      <p>
        <b>“Costa Realty”</b>, hace de su conocimiento que los datos personales proporcionados por usted serán utilizados para:
      </p>
      <p><b>Finalidades primarias:</b></p>
      <ol>
        <li> Promesas de compraventa.</li>
        <li> Compraventas.</li>
        <li> Arrendamientos.</li>
        <li> Analizar viabilidad de solvencia.</li>
      </ol>
      <p><b>Finalidades secundarias:</b></p>
      <ol>
        <li>
         	En caso de no contratar nuestros servicios inmobiliarios, le sugerimos mantenerse en nuestra base de datos actualizada para poder contactarnos con usted y darle prioridad con nuestros productos y servicios.


        </li>
        <li>
        	Realizarle ofertas, promociones o alguna actividad sobre el inmueble de su interés.


        </li>
        <li>	Facilitar el uso a nuestro portal de internet: <a href="www.costarealty.com.mx">www.costarealty.com.mx</a></li>
      </ol>
      <p>
       Los datos personales serán guardados bajo la más estricta confidencialidad y no se les podrá dar un uso distinto a los antes mencionados, salvo que medie un cambio en este Aviso de Privacidad. 


      </p>

      <h4>IV. DERECHOS ARCO.</h4>
      <p>
       El artículo 28 de la LFPDPPP, le hace titular de cuatro derechos a ejercer:
      </p>
      <ul>
        <li>
          <b>Acceder</b> a sus datos personales que obren en el poder del responsable, a conocer el Aviso de Privacidad y sus finalidades.
        </li>
        <li>
          <b>	Rectificar </b>sus datos en caso de ser inexactos, incorrectos o estén desactualizados.
        </li>
        <li>
          <b>Cancelar </b>sus datos personales cuando considere que no están siendo utilizados para el cumplimiento de los fines establecidos en el presente Aviso de Privacidad.
        </li>
        <li>
          <b> Oponerse </b>al tratamiento de sus datos personales respecto a alguno de los fines establecidos en el presente Aviso de Privacidad.
        </li>
      </ul>
      <p>
      <b>“Costa Realty”</b>, no estará obligada a cancelar los datos personales cuando se trate de alguno de los supuestos establecidos en la ley, entre otras las siguientes: que se refiera a las partes de un contrato privado, social o administrativo y sean necesarios para su desarrollo y cumplimiento; deban ser tratados por disposición legal; se obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas; sean necesarios para  proteger los intereses jurídicamente tutelados del titular; sean necesarios para cumplir una obligación legalmente adquirida por el titular y sean objeto de tratamiento para la prevención.
      </p>

      <h4>
        V. OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS.
      </h4>
      <p>
       Asimismo, le informamos que los datos personales proporcionados a “Costa Realty”, se encuentran debidamente protegidos a través de medidas de seguridad tecnológicas, físicas y administrativas, previniendo el uso o divulgación indebida de los mismos. El acceso a dicha información, sólo se permitirá a quienes cuentan con una autorización expresa y por escrito del titular que los identifique plenamente como autorizados para hacer uso de los datos personales, exclusivamente para el cumplimiento de las finalidades señaladas en el presente Aviso de Privacidad.
      </p>

      <h4>
        VI.	MECANISMOS PARA EL EJERCICIO DE LOS DERECHOS ARCO Y REVOCACIÓN DEL CONSENTIMIENTO PARA EL TRATAMIENTO DE SUS DATOS.
      </h4>
      <p>
        Para ejercer sus derechos de Acceso, Rectificación, Cancelación, y Oposición o su derecho a revocar el consentimiento para el tratamiento de sus datos personales, deberá ser mediante un escrito libre manifestando lo que a su derecho convenga respecto al mal uso, rectificación de sus datos personales remitido al siguiente correo electrónico: <a href="mailto:mgodoy@costarealty.com.mx"> mgodoy@costarealty.com.mx </a> y cumpliendo con los requisitos señalados en el artículo 29 del reglamento de la LFPDPPP. O bien presentado su escrito de forma física, en idioma español, de conformidad con los requisitos establecidos en el artículo antes mencionado en la dirección establecida en el inciso (I), del presente Aviso de Privacidad. 


      </p>

      <h4>VII. TRANSFERENCIA DE DATOS.</h4>
      <p>
        Los datos personales que nos han sido proporcionados, en ningún momento serán divulgados, ni compartidos con terceros, en el sentido de que, no se considerará para efectos del presente Aviso de Privacidad, como a un tercero a las sociedades controladoras, subsidiarias o afiliadas que se encuentren bajo el común del grupo empresarial de “Costa Realty”, que requieran tener los datos personales para cumplir los fines antes mencionados.
      </p>
      <p>
        Asimismo, únicamente los datos podrán ser compartidos exclusivamente con oficinas inmobiliarias y/o agentes inmobiliarios, o bien a la red AMPI (Asociación Mexicana de Profesionales Inmobiliarios) y únicamente serán con el fin de poder realizar en colaboración, las gestiones necesarias para la actividad de intermediación comercial inmobiliaria para la compra, venta, arrendamiento o la administración de bienes inmuebles. 
      </p>
      <p>
        A los terceros que se les transfieran sus datos personales se obligarán en los mismos términos del presente Aviso de Privacidad, cumplirán con las medidas de seguridad y confidencialidad respectivas.
      </p>
      <p>
       De igual manera, nos obligamos a  transferir sus datos personales a terceros sin su consentimiento, únicamente para los casos establecidos en el artículo 37 de la LFPDPPP, así como realizar dicha transferencia en los términos de la Ley antes mencionada.


      </p>

      <h4>VIII. REVOCACIÓN.</h4>
      <p>
       El titular de los datos personales podrá revocar su consentimiento para el tratamiento de datos personales en cualquier momento, de conformidad con el artículo 8 de la LFPDPPP, debiendo, únicamente para tales efectos, enviar su solicitud a través de un escrito libre al Encargado de los Datos Personales: Sra. Rosa Ana Lavinia Beltrani Parenti ya sea por mensajería al domicilio o al correo electrónico mencionados en el inciso (VI) del presente Aviso de Privacidad. 
      </p>
      <p>
       De igual manera se le informa al titular de los datos personales, que “Costa Realty”, tendrá un plazo de 7 días naturales para atender a su petición, en la contestación se informará sobre la procedencia de la petición.


      </p>

      <h4>IX. COOKIES</h4>
      <p>
       “Costa Realty”, utiliza cookies propias y de terceros para analizar su navegación y ofrecerles un servicio más personalizado acorde a sus intereses. Ciertas áreas de nuestro sitio también usan cookies para entender más sobre usted a fin de ofrecerle una experiencia de navegación personalizada. Si desea conocer nuestra política de Cookies, puede consultar www.costarealty.com.mx en el apartado de aviso de privacidad.


      </p>

      <h4>X. CONSENTIMIENTO DEL TITULAR.</h4>
      <p>
       Consiento que mis datos personales sean tratados conforme a los términos y condiciones del presente Aviso de Privacidad.
      </p>
      <br> <br>
      <div class="row">

        <div class="col-sm-6">
          ________________________ <br>
                Nombre 

        </div>
         <div class="col-sm-6">
          _________________________ <br>
          Firma autógrafa del titular.
        </div>
      </div>
     
      
     



      <h4>XI. CAMBIOS AL AVISO DE PRIVACIDAD.</h4>
      <p>
       Nos reservamos el derecho de cambiar este Aviso de Privacidad en cualquier momento. Estas modificaciones estarán disponibles en cualquiera de los siguientes medios: 
      </p>
      <ol>
        <li>	Anuncios visibles en nuestros establecimientos.</li>
        <li>	En nuestra página de internet.</li>
        <li>
         Se las haremos llegar al último correo electrónico que nos haya proporcionado.
        </li>
      </ol>
      <p>
      No seremos responsables si usted no recibe la notificación de cambio en el Aviso de Privacidad si existiere algún problema con su cuenta de correo electrónico o de transmisión de datos por internet.
      </p>

      <div class="container text-center">
        <p>ATENTAMENTE</p>
        <h3>“COSTA REALTY” <br> INMOBILIARIA BASAC, S.A. DE C.V.</h3>
      </div>
    </div>
  </div>
</template>
