
<template>
    <div class="card-box-b card-shadow news-box">
        <div class="img-box-b">
        <img :src="item.image" :alt="item.name" class="img-b img-fluid">
        </div>
        <div class="card-overlay">
        <div class="card-header-b">
            <div class="card-title-b">
                <h3 class="title-2">
                    <a @click="searchProps()" class="cc_pointer" >{{item.name}}</a>
                </h3>
            </div>
            <div class="card-date">
                <span class="date-b">
                     <a @click="searchProps()" class="cc_pointer" >{{$t('components.place_card.link')}}</a></span>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            required:true
        }
    },
    methods:{
        searchProps(){
            this.searching=true;
            let city = this.item.name;
            this.$store.dispatch("getPropsPlaces",city);
            
            if(this.$route.name!='Results')
                this.$router.push({name: 'ResultsSingle'});
            
            this.searching=false;
        },        
    },
}
</script>
<style scoped>

.date-b a,
.title-2{
    color: #ffffff;
}
.img-box-b{
    height: 300px;
}
.img-fluid {
     max-width: auto;
    height: 100%;
    object-position: center;
    object-fit: cover;
}
</style>