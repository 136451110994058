<template>
  <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 ">
    <div class="card-box-d">
      <div class="card-img-d">
        <img :src="(agent.image)?agent.image:'/img/avatar.png'" :alt="`${agent.fullname}-img`" class="img-d img-fluid" />
      </div>
      <div class="card-overlay card-overlay-hover">
        <div class="card-header-d">
          <div class="card-title-d align-self-center">
            <h3 class="title-d">
                <a @click="goToAgent()" class="link-to cc_pointer">
                    {{agent.fullname}}
                </a>
            </h3>
          </div>
        </div>
        <div class="card-body-d">
          <!--p v-if="agent.biografia" class="content-d color-d">
            {{formatBiography}}
          </p-->
          <div class="info-agents color-a">
            <p v-if="agent.cellphone"><strong>{{$t('layout.footer.company_phone')}}: </strong> {{agent.cellphone}}</p>
            <p v-if="agent.email"><strong>{{$t('layout.footer.company_email')}}: </strong> {{agent.email}}</p>
            <p @click="goToAgent()" class="link-to-button cc_pointer text-center mt-3"><strong>{{$t('components.agent_card.link')}}</strong></p>
          </div>
        </div>
        <div class="card-footer-d">
          <div class="socials-footer d-flex justify-content-center">
            <ul class="list-inline">
              <li v-if="agent.fb"  class="list-inline-item">
                <a :href="agent.fb" target="_blank">
                  <i class="fab fa-facebook-f" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="agent.twitter" class="list-inline-item">
                <a :href="agent.twitter" target="_blank">
                  <i class="fab fa-twitter" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="agent.instagram"  class="list-inline-item">
                <a :href="agent.instagram" target="_blank">
                  <i class="fab fa-instagram" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="agent.youtube"  class="list-inline-item">
                <a :href="agent.youtube" target="_blank">
                  <i class="fab fa-youtube" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="agent.linkedin"  class="list-inline-item">
                <a :href="agent.linkedin" target="_blank">
                  <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="agent.pinterest"  class="list-inline-item">
                <a :href="agent.pinterest" target="_blank">
                  <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                </a>
              </li>
              <li v-if="agent.gmas" class="list-inline-item">
                <a :href="agent.gmas"  target="_blank">
                  <i class="fab fa-google-plus-g" aria-hidden="true"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import services from '@/store/_services'
export default {
    props:{
        agent:{
            type:Object,
            required:false
        }
    },
    computed:{
        formatBiography(){
            return this.agent.biografia.substring(0, 125) + '...'
        }
    },
    methods:{
      goToAgent(){
        //this.$store.dispatch('setAgentActive',this.agent);

        let nameAgentFilt=services.normalize(this.agent.fullname)
        let folio = this.agent.folio;
        this.$router.push({
            name: 'Agent',
            params: {
                folio,
                nameAgentFilt
            }
        });
      }
    }
}
</script>
<style scoped>
.img-fluid{
  width:100%;
}
.link-to:hover{
  color: #fff!important;
}
.link-to-button:hover{
  color:#000
}
.link-to-button{
  color: #fff;
}
</style>